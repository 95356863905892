import Button from 'components/atoms/Button';
import SiteContext from 'context/SiteContext';
import PropTypes from 'prop-types';
import { useContext, useMemo } from 'react';

const AccessibilityButton = ({ blok }) => {
    const { showAccessibility } = useContext(SiteContext);

    const data = useMemo(() => {
        const { buttonText = 'Launch Accessibility Widget' } = { ...blok };
        return { buttonText };
    }, [blok]);

    return (
        <div className="container grid grid-cols-6 lg:grid-cols-12 my-16">
            <div className="col-span-6 lg:col-start-4">
                <Button label={data.buttonText} onClick={showAccessibility} />
            </div>
        </div>
    );
};

AccessibilityButton.propTypes = {
    blok: PropTypes.object,
};

export default AccessibilityButton;
