import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--block"
                width="200"
                viewBox="0 0 200 139"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="16.5,38.3 16.5,92.5 137.9,115 137.9,54.7 " />
                <polygon points="3.5,22.6 3.5,105.2 155.8,135.1 155.8,41.5 " />
                <polyline points="51.3,43 51.4,82 137.9,96.3 " />
                <line x1="16.5" y1="92.5" x2="51.4" y2="82" />
                <polygon points="155.8,135.1 155.8,41.5 183,32.9 183,119.7 " />
                <polygon points="3.5,22.6 41.7,15.1 183,32.9 155.8,41.5 " />
                <polyline points="42.8,0.9 42.3,5.9 41.8,10.7 " />
                <polyline points="185,17.4 184.4,22.3 183.9,27.2 " />
                <line x1="42.3" y1="5.8" x2="184.4" y2="22.3" />
                <polyline points="189.7,119.6 194.7,119.6 199.5,119.6 " />
                <polyline points="189.5,32.3 194.3,32.3 199.3,32.3 " />
                <line x1="194.6" y1="119.6" x2="194.3" y2="32.3" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
