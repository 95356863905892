import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--rectangle"
                width="120"
                viewBox="0 0 342.4 189.3"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="3.8" y="37.2" width="300" height="150" />
                <line x1="4.1" y1="9.7" x2="304.1" y2="9.7" />
                <line x1="304.1" y1="2.1" x2="304.1" y2="17.2" />
                <line x1="4.1" y1="2.1" x2="4.1" y2="17.2" />
                <line x1="331.1" y1="37.3" x2="331.1" y2="187.2" />
                <line x1="338.6" y1="187.2" x2="323.5" y2="187.2" />
                <line x1="338.6" y1="37.3" x2="323.5" y2="37.3" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
