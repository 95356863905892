import PropTypes from 'prop-types';

import TransitMap from './TransitMap';

const Components = {
    TransitMap,
};

const Component = ({ selectComponent, _uid }) => {
    // check if component is defined above
    if (typeof Components[selectComponent] !== 'undefined') {
        const Component = Components[selectComponent];
        // wrap with SbEditable for visual editing
        return <Component key={_uid} />;
    }

    return (
        <p>
            The component <strong>{selectComponent}</strong> has not been created yet.
        </p>
    );
};

Component.propTypes = {
    selectComponent: PropTypes.string,
    _uid: PropTypes.string,
};

export default Component;
