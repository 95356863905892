import PropTypes from 'prop-types';
import { storyblokEditable } from '@storyblok/react';
import { Section } from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import LoadingPlaceholder from 'components/atoms/LoadingPlaceholder';
import StoryblokSection from 'components/atoms/StoryblokSection';
import { BodyText, BodyTextAlt, H2, H3 } from 'components/atoms/Typography';
import { APPS } from 'helpers/constants/general';
import { logError } from 'helpers/logging';
import { getShopifyProductByHandle } from 'helpers/requests/shopify-data-requests';
import { useQuery } from 'react-query';
import { gShopifyProduct } from 'helpers/graphql';
import { useContext, useMemo } from 'react';
import SiteContext from 'context/SiteContext';
import CurrencyFormat from 'react-currency-format';
import { getMetafieldValue, roundToTwo } from 'helpers';
import ProductProvider, { useProductContext } from 'hooks/useProductStore';
import Input from 'components/atoms/Input';
import Select from 'components/atoms/Select';
import Tooltip from 'components/molecules/Tooltip';
import { numberOfBoxesInfo, overageInfo, overageOptions } from 'helpers/constants/product';
import { AddToCartIcon, InfoIcon } from 'components/atoms/Icons';
import useProductForm from 'components/atoms/hooks/useProductForm';
import Button from 'components/atoms/Button';

const ShopTheLook = ({ blok }) => {
    const {
        store: { settings },
    } = useContext(SiteContext);

    const { data, isFetching, isError } = useQuery({
        queryKey: ['ShopTheLook', blok?.productHandle],
        queryFn: async () => await getShopifyProductByHandle(blok.productHandle),
        enabled: !!blok?.productHandle,
    });

    if (isFetching) {
        return (
            <StoryblokSection _uid={blok._uid} component={blok.component}>
                <Section className={`container py-10 lg:py-14`} {...storyblokEditable(blok)}>
                    <div className="grid grid-rows-2 gap-7 md:grid-rows-1 md:grid-cols-2 md:gap-16">
                        <div className="aspect-w-1 aspect-h-1 md:aspect-w-5 md:aspect-h-6">
                            <LoadingPlaceholder />
                        </div>
                        <div className="aspect-w-1 aspect-h-1 md:aspect-w-5 md:aspect-h-6">
                            <LoadingPlaceholder />
                        </div>
                    </div>
                </Section>
            </StoryblokSection>
        );
    }

    if (isError) {
        logError(`Error get product ${blok.productHandle}`, APPS.FRONTEND);
    }

    const productRespData = data?.data;

    if (!productRespData) {
        return null;
    }

    const product = gShopifyProduct(productRespData);
    if (!product) {
        return null;
    }

    return (
        <StoryblokSection _uid={blok._uid} component={blok.component}>
            <ProductProvider settings={settings} product={product}>
                <SectionContent product={product} blok={blok} />
            </ProductProvider>
        </StoryblokSection>
    );
};

const SectionContent = ({ product, blok }) => {
    const {
        store: { checkoutCreated, tradeDiscountValue },
    } = useContext(SiteContext);
    const { quantity, setQuantity, selectedVariant, shippingInterval } = useProductContext();

    const {
        area,
        setArea,
        minimumCoverage,
        overage,
        setOverage,
        pricePerSquareFoot,
        pricePerTile,
        tilesPerBox,
        sqFtPerBox,
        totalSquareFeet,
        isAdding,
        addToCart,
    } = useProductForm({
        selectedVariant,
        quantity,
        setQuantity,
        product,
        shippingInterval,
    });

    const layout =
        !blok?.secondaryImage?.filename && !product.images?.[1] ? 'non-double' : 'double';
    console.log(layout);

    const data = useMemo(() => {
        const subtitle = getMetafieldValue(product.metafields, 'accentuate', 'subtitle');
        const color = getMetafieldValue(product.metafields, 'accentuate', 'color');

        return {
            subtitle,
            color,
        };
    }, [product]);

    const Overage = () => {
        return (
            <label htmlFor="overage" style={{ height: '14px', overflow: 'visible' }}>
                OVERAGE
                <Tooltip title={overageInfo}>
                    <InfoIcon className="ml-1.5" />
                </Tooltip>
            </label>
        );
    };

    return (
        <Section className={`container py-10 lg:py-16`} {...storyblokEditable(blok)}>
            <div className={`grid gap-7 md:grid-cols-2 md:gap-x-16`}>
                {blok?.title && <H2>{blok.title}</H2>}
                {product.images?.[0] && (
                    <div className={`${layout === 'double' ? 'md:col-start-1' : 'md:row-span-2'}`}>
                        <Image
                            alt={
                                product.images[0].altText ||
                                `${product.title} - Shop the look image 1`
                            }
                            src={product.images[0].src}
                            width={600}
                            height={Math.ceil(
                                (product.images[0].height / product.images[0].width) * 600
                            )}
                        />
                    </div>
                )}
                {blok?.secondaryImage?.filename && (
                    <div className="relative row-start-2 md:row-start-1 md:col-start-2 md:row-span-3">
                        <div className="aspect-1 md:aspect-none">
                            <Image
                                className="w-full h-full"
                                fill={true}
                                alt={
                                    blok.secondaryImage.alt ||
                                    `${product.title} - Shop the look image 2`
                                }
                                src={blok?.secondaryImage?.filename}
                            />
                        </div>
                    </div>
                )}
                {!blok?.secondaryImage?.filename && product.images?.[1] && (
                    <div className="relative row-start-2 md:row-start-1 md:col-start-2 md:row-span-3">
                        <div className="aspect-1 md:aspect-none">
                            <Image
                                className="w-full h-full"
                                fill={true}
                                alt={
                                    product.images[1].altText ||
                                    `${product.title} - Shop the look image 2`
                                }
                                src={product.images[1].src}
                            />
                        </div>
                    </div>
                )}
                <div className="grid gap-7 items-end self-end">
                    <h3 className="flex flex-wrap gap-3 items-center">
                        {product?.titleLine1 && product?.titleLine2 ? (
                            <>
                                <H3 className="uppercase" as="span">
                                    {product.titleLine1}
                                </H3>
                                <BodyText as="span" color="#7F7F7F">
                                    |
                                </BodyText>
                                <H3 className="uppercase" as="span" color="#7F7F7F">
                                    {product.titleLine2}
                                </H3>
                            </>
                        ) : (
                            <>
                                <H3 className="uppercase" as="span">
                                    {product.title}
                                </H3>
                                <BodyText as="span" color="#7F7F7F">
                                    |
                                </BodyText>
                                <span>
                                    {data.subtitle && (
                                        <H3 className="uppercase" as="span" color="#7F7F7F">
                                            {data.subtitle}
                                        </H3>
                                    )}
                                    {data.color && (
                                        <H3 as="span" className="uppercase" color="#7F7F7F">
                                            {data.color}
                                        </H3>
                                    )}
                                </span>
                            </>
                        )}
                    </h3>
                    <div className="grid grid-cols-2 xl:grid-cols-4 gap-7">
                        {blok.showQuantitySelector && (
                            <>
                                <Input
                                    className="xl:order-5"
                                    error={Number(area) < minimumCoverage}
                                    size="medium"
                                    label="Ft² needed"
                                    name="sqft"
                                >
                                    <input
                                        name="sqft"
                                        type="number"
                                        placeholder="200"
                                        value={area.toString()}
                                        onChange={e => setArea(Number(e.target.value))}
                                        min={1}
                                    />
                                    {Number(area) < minimumCoverage && (
                                        <div className="error">
                                            Minimum {minimumCoverage} sq ft.
                                        </div>
                                    )}
                                </Input>
                                <Select
                                    className="xl:order-6"
                                    name="overage"
                                    customLabel={Overage}
                                    placeholder="Select overage"
                                    options={overageOptions}
                                    value={`${overage}`}
                                    onChange={e => setOverage(Number(e.target.value))}
                                />
                                <div className="xl:order-7 col-span-2 md:col-span-1">
                                    <div className="flex overflow-visible">
                                        <BodyTextAlt
                                            className="whitespace-nowrap"
                                            color="#7f7f7f"
                                            margin="0 5px 5px 0"
                                        >
                                            Number of boxes
                                        </BodyTextAlt>
                                        <Tooltip
                                            title={numberOfBoxesInfo({
                                                tilesPerBox,
                                                sqFtPerBox,
                                            })}
                                        >
                                            <InfoIcon fill="#7f7f7f" />
                                        </Tooltip>
                                    </div>
                                    <BodyText lineHeight="1" fontSize="24px" padding="10px 0">
                                        <CurrencyFormat
                                            value={quantity}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </BodyText>
                                </div>
                                <div className="xl:order-3">
                                    <div className="flex">
                                        <BodyTextAlt color="#7f7f7f" margin="0 5px 3px 0">
                                            Total ft<sup>2</sup>
                                        </BodyTextAlt>
                                    </div>
                                    <BodyText padding="10px 0">
                                        <CurrencyFormat
                                            value={totalSquareFeet}
                                            displayType={'text'}
                                            thousandSeparator={true}
                                        />
                                    </BodyText>
                                </div>
                                <div className="xl:order-4">
                                    <BodyTextAlt color="#7f7f7f">Tiles/Box</BodyTextAlt>
                                    <BodyText>{tilesPerBox}</BodyText>
                                </div>
                            </>
                        )}
                        <div className="xl:order-1">
                            <div className="flex">
                                <BodyTextAlt color="#7f7f7f" margin="0 5px 7px 0">
                                    Price per ft<sup>2</sup>
                                </BodyTextAlt>
                            </div>
                            <div className="flex flex-wrap -m-1">
                                {tradeDiscountValue && (
                                    <BodyText
                                        className="m-1"
                                        lineHeight="1"
                                        padding="10px 0"
                                        color="#7f7f7f"
                                    >
                                        <span className="align-top text-xs mr-0.5">$</span>
                                        <s>
                                            <CurrencyFormat
                                                value={roundToTwo(pricePerSquareFoot)}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                            />
                                        </s>
                                    </BodyText>
                                )}
                                <BodyText className="m-1" lineHeight="1" padding="10px 0">
                                    <span className="align-top text-xs mr-0.5">$</span>
                                    <CurrencyFormat
                                        value={roundToTwo(
                                            (pricePerSquareFoot * (100 - tradeDiscountValue)) / 100
                                        )}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                    />
                                </BodyText>
                            </div>
                        </div>
                        <div className="xl:order-2">
                            <div className="flex">
                                <BodyTextAlt color="#7f7f7f" margin="0 5px 7px 0">
                                    Price per tile
                                </BodyTextAlt>
                            </div>
                            <BodyText lineHeight="1" padding="10px 0">
                                <span className="align-top text-xs mr-0.5">$</span>
                                <CurrencyFormat
                                    value={pricePerTile}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </BodyText>
                        </div>
                    </div>
                    {(blok.showBuyButton || blok.showViewButton) && (
                        <div className="grid gap-5 items-end">
                            {blok.showBuyButton && (
                                <Button
                                    className="w-full"
                                    type="primary"
                                    size="large"
                                    icon={<AddToCartIcon />}
                                    label="Add to Cart"
                                    labelClassName="ml-auto"
                                    iconClassName="mr-auto"
                                    disabled={
                                        isAdding ||
                                        !checkoutCreated ||
                                        Number(area) < minimumCoverage
                                    }
                                    onClick={addToCart}
                                />
                            )}
                            {blok.showViewButton && (
                                <Button
                                    as="a"
                                    href={`/products/${product.handle}`}
                                    className="w-full"
                                    labelClassName="mx-auto"
                                    type={blok.showBuyButton ? 'secondary' : 'primary'}
                                    size="large"
                                    label="View Product"
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        </Section>
    );
};

ShopTheLook.propTypes = {
    blok: PropTypes.object,
};

SectionContent.propTypes = {
    product: PropTypes.object,
    blok: PropTypes.object,
};

export default ShopTheLook;
