import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from 'styles/breakpoints';

export const Section = styled.section`
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: ${props => props.maxWidth};
    padding: ${props => props.padding};
    @media screen and ${props =>
            props.breakpoint ? `(max-width: ${props.breakpoint})` : breakpoints.maxMd} {
        padding: ${props => props.paddingMobile};
    }
`;

export const BlogPageSection = styled.div`
    margin: ${props => (props.marginTop ? props.marginTop : '0px')} auto
        ${props => (props.marginBottom ? props.marginBottom : '0px')};
    max-width: ${props => props.maxWidth};
    padding: ${props => props.padding};
    @media screen and ${breakpoints.maxMd} {
        padding: ${props => props.paddingMobile};
        margin: ${props => (props.marginTopMobile ? props.marginTopMobile : '0px')} auto
            ${props => (props.marginBottomMobile ? props.marginBottomMobile : '0px')};
    }
`;

export const Sticky = styled.div`
    top: var(--header-height, 0px);
    transition: all 0.25s ease-in-out;
    body.hideHeader & {
        top: 0;
    }
`;

export const DesktopContainer = styled.div`
    display: none;
    @media screen and ${breakpoints.lg} {
        display: block;
    }
`;

export const MobileContainer = styled.div`
    @media screen and ${breakpoints.lg} {
        display: none;
    }
`;

const Container = ({ type, ...props }) => {
    if (type === 'homePageSection') {
        return <Section {...props} />;
    }
    return <Section {...props} />;
};

export default Container;

Container.propTypes = {
    type: PropTypes.oneOf(['homePageSection']),
};

Container.defaultProps = {
    type: 'homePageSection',
};
