import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--shipHands" width="47" height="46" viewBox="0 0 47 46" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M27.117 38.214L38.609 38.214 38.609 43.96 27.117 43.96zM36.694 38.214v-2.932s4.99-4.94 7.099-7.04c.36-.36.563-.848.563-1.358V12.231s-4.789-1.724-4.789 11.617l-5.687 5.687 1.318-5.746c.657-2.752-1.86-3.929-3.233-1.795-1.205 2.435-2.187 4.975-2.933 7.587v8.633M6.047 38.214L17.54 38.214 17.54 43.96 6.047 43.96z"
                        transform="translate(-378 -1354) translate(379 1355)"
                    />
                    <path
                        d="M7.963 38.214v-2.932S3.24 30.317.93 28.227c-.401-.363-.63-.88-.63-1.421V12.23S5.09 10.507 5.09 23.848l5.686 5.687L9.46 23.79c-.66-2.747 1.86-3.929 3.233-1.789 1.205 2.433 2.186 4.97 2.932 7.581v8.633M32.863 6.22c0-.35-.192-.673-.5-.84L22.786.164c-.285-.155-.63-.155-.915 0l-9.578 5.224c-.308.167-.5.49-.5.84V16.61c0 .337.177.649.466.822l8.662 5.196c.28.168.627.18.919.035l10.495-5.26c.324-.162.528-.494.528-.856V6.22zM21.371 11.398L32.723 5.723M11.928 5.732L21.371 11.398M21.371 11.398L21.371 22.757"
                        transform="translate(-378 -1354) translate(379 1355)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
