import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--nouveau"
                width="120"
                viewBox="0 0 154.1 335.2"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <path d="M0.5,334.6v-299h27.6v39.1c0,7.5,0.5,15.3,1.4,23.2c4,31.8,12.6,54.9,48.3,93.2c39,41.8,40.3,94.9,40.3,95.5 l-0.2,48.1H0.5z" />
                <path d="M27.6,36.1v38.6c0,7.5,0.5,15.3,1.4,23.2c4,31.9,12.6,55.1,48.5,93.5c20.3,21.7,30.1,46.4,34.8,63.3 c5.1,18.3,5.3,31.7,5.3,31.8l-0.2,47.6H1v-298H27.6 M28.6,35.1H0v300h118.4l0.2-48.6c0,0-0.8-53.4-40.4-95.8 C43.3,153.3,34.1,130.2,30,97.8c-0.9-7.6-1.4-15.4-1.4-23.1V35.1L28.6,35.1z" />
                <line x1="0.4" y1="7.6" x2="118.4" y2="7.6" />
                <line x1="118.4" y1="0" x2="118.4" y2="15.2" />
                <line x1="0.4" y1="0" x2="0.4" y2="15.2" />
                <line x1="146.5" y1="35.1" x2="146.5" y2="335.2" />
                <line x1="154.1" y1="335.2" x2="138.9" y2="335.2" />
                <line x1="154.1" y1="35.1" x2="138.9" y2="35.1" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
