import { storyblokEditable } from '@storyblok/react';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Link from 'components/atoms/Link';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import styled from 'styled-components';

const Organism = styled.section`
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.6) 0%,
        rgba(0, 0, 0, 0.0001) 20%,
        rgba(0, 0, 0, 0.0001) 60%,
        rgba(0, 0, 0, 0.6) 91%
    );
    transition: all 0.2s ease-in-out;
    img {
        max-height: max(calc(100vh - var(--header-height, 79px)), 900px);
        margin-inline: auto;
    }
    @media screen and (max-aspect-ratio: 1/1) {
        img {
            max-height: min(calc(100vh - var(--header-height, 65px)), 950px);
        }
    }
    @supports (height: 100svh) {
        img {
            max-height: max(calc(100svh - var(--header-height, 79px)), 900px);
            margin-inline: auto;
        }
        @media screen and (max-aspect-ratio: 1/1) {
            img {
                max-height: min(calc(100svh - var(--header-height, 65px)), 950px);
            }
        }
    }
`;

const HeroV2 = ({ blok }) => {
    const data = useMemo(() => {
        const {
            image,
            mobileImage,
            heading,
            buttonText,
            buttonLink,
            allowTransparentHeader = true,
        } = { ...blok };
        return {
            image: image?.filename && image?.filename !== '' ? image : null,
            mobileImage: mobileImage?.filename && mobileImage?.filename !== '' ? mobileImage : null,
            heading,
            buttonText,
            buttonLink,
            allowTransparentHeader,
        };
    }, [blok]);

    return (
        <Organism
            className={`relative`}
            {...storyblokEditable(blok)}
            {...(data.allowTransparentHeader && { 'data-allow-transparent-header': 'true' })}
        >
            {data.image ? (
                <>
                    <Image
                        className={`mix-blend-multiply  ${
                            data.mobileImage ? 'lg:block hidden' : ''
                        }`}
                        // width={2000}
                        // height={1300}
                        src={data.image.filename}
                        priority={true}
                        sizes="(max-width: 480px) 90vw, 100vw"
                        alt={data.image?.alt || data.heading}
                    />
                    {data.mobileImage && (
                        <Image
                            className={`mix-blend-multiply lg:hidden`}
                            // width={641}
                            // height={1282}
                            src={data.mobileImage.filename}
                            priority={true}
                            sizes="100vw"
                            alt={data.mobileImage?.alt || data.heading}
                        />
                    )}
                    <Content
                        heading={data.heading}
                        buttonText={data.buttonText}
                        buttonLink={data.buttonLink}
                    />
                </>
            ) : (
                <>
                    <div className={`bg-gray-100 aspect-w-16 aspect-h-10`} />
                    <Content
                        heading={data.heading}
                        buttonText={data.buttonText}
                        buttonLink={data.buttonLink}
                    />
                </>
            )}
        </Organism>
    );
};

const Content = ({ heading, buttonText, buttonLink }) => {
    return (
        <div className="container absolute w-full left-1/2 bottom-8 transform -translate-x-1/2 grid grid-cols-6 lg:grid-cols-12">
            {heading && <h1 className="sr-only">{heading}</h1>}
            {buttonText && (
                <div className="col-span-full lg:col-span-3 lg:col-start-10">
                    <Link href={buttonLink || '#'} passHref>
                        <Button
                            className="ml-auto w-full lg:w-52"
                            as="span"
                            inverted={true}
                            label={buttonText}
                        />
                    </Link>
                </div>
            )}
        </div>
    );
};

HeroV2.defaultProps = {
    blok: {},
};

HeroV2.propTypes = {
    blok: PropTypes.object,
};

Content.propTypes = {
    heading: PropTypes.string,
    buttonText: PropTypes.string,
    buttonLink: PropTypes.string,
};

export default HeroV2;
