import PropTypes from 'prop-types';

const Logo = props => {
    const { fill, ...attrs } = props;
    return (
        <svg className="icon icon--logo" width="90" height="16" viewBox="0 0 90 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g fill={fill} fillRule="nonzero">
                    <path
                        d="M28.953.074c.706 0 1.355.372 1.714.97l.078.142L38.088 16h-3.995l-1.046-2.228c-.306-.651-.937-1.084-1.646-1.143l-.165-.006h-5.975l-1.542 3.366h-1.387L28.19 3.288 26.648.074h2.305zm-8.471 0l-.006 15.915H17.09V.074h3.392zm-6.167 0L3.985 14.765h10.33v1.224H1.199c-.215 0-.425-.07-.598-.198-.408-.304-.518-.862-.273-1.294l.069-.105 9.755-13.094H.286V.074h14.029zm43.937 0v1.224h-4.976c-.552 0-.999.446-1 .998l-.036 13.693h-3.392l.036-13.688c0-.555-.448-1.003-1-1.003h-5.048V.074h15.416zm5.088 0l-.006 15.915h-3.386V.074h3.392zM69.879 0l-.06 13.76c0 .557.448 1.005 1 1.005h5.153l.053 1.224h-9.602V0h3.456zM88.93.074v1.224H81.84V7.42h5.55v1.224h-5.55v5.121c0 .552.447 1 1 1h6.4v1.224H78.448V.074h10.483zM28.807 4.971l-3.084 6.427h4.578c.15 0 .298-.033.433-.098.462-.222.676-.753.513-1.226l-.044-.108-2.396-4.995z"
                        transform="translate(-39 -40) translate(39.878 40)"
                    />
                </g>
            </g>
        </svg>
    );
};

Logo.defaultProps = {
    fill: 'currentColor',
};

Logo.propTypes = {
    fill: PropTypes.string,
};

export default Logo;
