import PropTypes from 'prop-types';

const MinusIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--minus" width="16" height="17" viewBox="0 0 16 17" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1336 -1957) translate(40 1900) translate(1289 50) translate(0 .366)">
                    <g fill={fill}>
                        <path
                            d="M0 0H12V1H0z"
                            transform="translate(7 7) translate(2 7.5) matrix(1 0 0 -1 0 1)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

MinusIcon.defaultProps = {
    fill: 'currentColor',
};

MinusIcon.propTypes = {
    fill: PropTypes.string,
};

export default MinusIcon;
