import axios from 'axios';
import { SHOP_NAME, STOREFRONT_ACCESS_TOKEN } from 'helpers/constants/urls';

export const createShopifyCustomer = async ({
    firstName,
    lastName,
    email,
    password,
    phone,
    accepts_marketing = true,
}) => {
    const query = `
    mutation {
      customerCreate(input: {
        acceptsMarketing: ${accepts_marketing},
        firstName: "${firstName}",
        lastName: "${lastName}"
        email: "${email}",
        password: "${password}",
        ${phone ? `phone: "${phone}",` : ''}
      }) {
        userErrors {
          field
          message
        }
        customer {
          id
        }
      }
    }`;
    return axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
};

export const authenticateShopifyCustomer = async (email, password) => {
    const query = `
    mutation {
      customerAccessTokenCreate(input: {
        email: "${email}",
        password: "${password}"
      }) {
        userErrors {
          field
          message
        }
        customerAccessToken {
          accessToken
          expiresAt
        }
      }
    }`;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
    return {
        ...data,
    };
};

export const customerAccessTokenDelete = async customerAccessToken => {
    const query = `mutation customerAccessTokenDelete($customerAccessToken: String!) {
  customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
    deletedAccessToken
    deletedCustomerAccessTokenId
    userErrors {
      field
      message
    }
  }
}`;
    const variables = {
        customerAccessToken,
    };
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },

        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
    return {
        ...data,
    };
};

export const initiateCustomerRecover = async email => {
    const query = `
    mutation customerRecover {
      customerRecover(email: "${email}") {
        customerUserErrors {
          code
          field
          message
        }
      }
    }`;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
    return { ...data };
};
export const customerReset = (id, resetToken, password) => {
    const query = `
      mutation customerReset($id: ID!, $input: CustomerResetInput!) {
        customerReset(id: $id, input: $input) {
          customer {
            id
            email
          }
          customerAccessToken {
            accessToken
            expiresAt
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }
      `;
    const variables = {
        id,
        input: {
            resetToken,
            password,
        },
    };
    return axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
};
export const getShopifyCustomer = async accessToken => {
    const query = `
    query {
      customer(customerAccessToken: "${accessToken}") {
        id
        email
        acceptsMarketing
        firstName
        lastName
        numberOfOrders
        tags
        defaultAddress {
          address1
          address2
          city
          country
          countryCodeV2
          province
          provinceCode
          firstName
          lastName
          phone
          zip
          id
          company
        }
        addresses (first: 20) {
          edges {
            node {
              address1
              address2
              city
              country
              countryCodeV2
              province
              provinceCode
              firstName
              lastName
              phone
              zip
              id
              company
            }
          }
        }
      }
    }`;
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const createCustomerAddress = async (customerAccessToken, address) => {
    const query = `
      mutation customerAddressCreate($customerAccessToken: String!, $address: MailingAddressInput!) {
        customerAddressCreate(
          customerAccessToken: $customerAccessToken
          address: $address
        ) {
          customerAddress {
              address1
              address2
              city
              country
              countryCodeV2
              province
              provinceCode
              firstName
              lastName
              phone
              zip
              id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }`;
    const variables = {
        customerAccessToken,
        address,
    };
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
    return { ...data };
};

export const updateCustomerAddress = async (customerAccessToken, id, address) => {
    const query = `
      mutation customerAddressUpdate($customerAccessToken: String!, $id: ID!, $address: MailingAddressInput!) {
        customerAddressUpdate(
          customerAccessToken: $customerAccessToken
          id: $id
          address: $address
        ) {
          customerAddress {
              address1
              address2
              city
              country
              countryCodeV2
              province
              provinceCode
              firstName
              lastName
              phone
              zip
              id
          }
          customerUserErrors {
            code
            field
            message
          }
        }
      }`;
    const variables = {
        customerAccessToken,
        id,
        address,
    };
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );
    return { ...data };
};

export const deleteCustomerAddress = async (customerAccessToken, id) => {
    const query = `
    mutation customerAddressDelete($customerAccessToken: String!, $id: ID!){
        customerAddressDelete(
            customerAccessToken: $customerAccessToken
            id: $id
        ) {
            customerUserErrors {
                code
                field
                message
            }
        }
    }`;

    const variables = {
        customerAccessToken,
        id,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const updateCustomerDefaultAddress = async (customerAccessToken, addressId) => {
    const query = `
    mutation customerDefaultAddressUpdate($customerAccessToken: String!, $addressId: ID!){
        customerDefaultAddressUpdate(
            customerAccessToken: $customerAccessToken
            addressId: $addressId
        ) {
            customerUserErrors {
                code
                field
                message
            }
        }
    }`;

    const variables = {
        customerAccessToken,
        addressId,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const activateAccount = async (activationUrl, password) => {
    const query = `
        mutation customerActivateByUrl($activationUrl: URL!, $password: String!) {
          customerActivateByUrl(activationUrl: $activationUrl, password: $password) {
            customer {
              id
              acceptsMarketing
              email
              firstName
              lastName
              phone
              tags
            }
            customerAccessToken {
              accessToken
              expiresAt
            }
            customerUserErrors {
              code
              field
              message
            }
          }
        }
    `;
    const variables = {
        activationUrl,
        password,
    };
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const updateCustomer = async ({ customerAccessToken, customer }) => {
    const query = `
        mutation customerUpdate($customer: CustomerUpdateInput!, $customerAccessToken: String!) {
            customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
                customer {
                    id
                    acceptsMarketing
                    email
                    firstName
                    lastName
                    phone
                    tags
                }
                customerUserErrors {
                    code
                    field
                    message
                }
            }
        }
    `;
    const variables = {
        customer,
        customerAccessToken,
    };

    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};

export const getCustomerOrders = async ({ accessToken, after = null, first = 16 }) => {
    const query = `
      query ($accessToken: String!, $after: String, $first: Int){
        customer(customerAccessToken: $accessToken) {
          orders(first: $first, reverse: true, after: $after) {
            edges {
              cursor
              node {
                processedAt
                financialStatus
                fulfillmentStatus
                id
                name
                originalTotalPrice {
                    amount
                }
                subtotalPrice {
                  amount
                }
                totalShippingPrice {
                  amount
                }
                totalTax {
                    amount
                }
                originalTotalDuties {
                    amount
                }
                totalPrice {
                  amount
                }
                shippingAddress {
                  address1
                  address2
                  city
                  country
                  countryCodeV2
                  provinceCode
                  firstName
                  lastName
                  phone
                  zip
                }
                lineItems (first: 250) {
                  edges {
                    node {
                      title
                      quantity
                      discountedTotalPrice {
                        amount
                      }
                      originalTotalPrice {
                        amount
                      }
                      variant {
                        title
                        id
                        image {
                          originalSrc
                          altText
                        }
                        product {
                          handle
                          title
                          metafields(identifiers: [{namespace: "accentuate", key: "square_feet_box_ratio"}, {namespace: "accentuate", key: "template"}, {namespace: "accentuate", key: "linear_feet_box_ratio"}]) {
                            namespace
                            key
                            value
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    const variables = {
        accessToken,
        after,
        first,
    };
    const { data } = await axios.post(
        `https://${SHOP_NAME}.myshopify.com/api/2024-04/graphql.json`,
        { query, variables },
        {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Shopify-Storefront-Access-Token': STOREFRONT_ACCESS_TOKEN,
            },
        }
    );

    return { ...data };
};
