import PropTypes from 'prop-types';

const CartIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--cart" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1344 -40) translate(39.878 40)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M5.07 14.386l.109.007c.393.054.696.39.696.799 0 .445-.36.806-.806.806-.445 0-.806-.361-.806-.806 0-.408.304-.745.697-.799l.11-.007zm5.328 0l.11.007c.393.054.696.39.696.799 0 .445-.36.806-.806.806-.445 0-.806-.361-.806-.806 0-.408.303-.745.697-.799l.11-.007zM15.461 0c.297 0 .539.242.539.54 0 .264-.19.484-.442.53l-.097.009h-.7c-.224 0-.42.141-.495.346l-.024.092-1.873 11.012c-.123.72-.713 1.26-1.428 1.33l-.155.007H3.737c-.298 0-.54-.241-.54-.54 0-.264.191-.484.443-.53l.097-.009h7.05c.224 0 .42-.142.494-.347l.024-.091.286-1.681H3.653c-1.16 0-2.18-.748-2.537-1.838l-.054-.184-1.03-4.121c-.08-.32-.008-.66.195-.92.175-.224.429-.367.706-.404l.14-.009h11.789l.317-1.856c.123-.72.713-1.26 1.428-1.329L14.76 0h.7zm-2.782 4.271H1.081l1.028 4.113c.165.661.731 1.139 1.4 1.2l.144.006 8.121-.001.905-5.318z"
                        transform="translate(1304.122) matrix(-1 0 0 1 16 0)"
                    />
                </g>
            </g>
        </svg>
    );
};

CartIcon.defaultProps = {
    fill: 'currentColor',
};

CartIcon.propTypes = {
    fill: PropTypes.string,
};

export default CartIcon;
