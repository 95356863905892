import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--hex"
                width="120"
                viewBox="0 0 339.8 299.5"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="227.5,37.4 77.5,37.4 2.5,167.3 77.5,297.2 227.5,297.2 302.5,167.3  " />
                <line x1="2.5" y1="9.8" x2="302.6" y2="9.8" />
                <line x1="302.6" y1="2.2" x2="302.6" y2="17.3" />
                <line x1="2.5" y1="2.2" x2="2.5" y2="17.3" />
                <line x1="329.8" y1="37.3" x2="329.8" y2="297.3" />
                <line x1="337.3" y1="297.3" x2="322.2" y2="297.3" />
                <line x1="337.3" y1="37.3" x2="322.2" y2="37.3" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
