import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--mailContained"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...attrs}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.3333 2.66797H2.66659C2.29244 2.66797 1.95301 2.82415 1.71025 3.07453C1.77817 3.10224 1.84408 3.1379 1.90671 3.18174L7.99992 7.44699L14.0931 3.18174C14.1558 3.1379 14.2217 3.10224 14.2896 3.07453C14.0468 2.82415 13.7074 2.66797 13.3333 2.66797ZM14.6666 5.22163L8.57338 9.48688C8.22906 9.7279 7.77078 9.7279 7.42646 9.48688L1.33325 5.22163V12.0013C1.33325 12.7346 1.93325 13.3346 2.66659 13.3346H13.3333C14.0666 13.3346 14.6666 12.7346 14.6666 12.0013V5.22163Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
