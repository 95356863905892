import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--triad"
                width="200"
                viewBox="0 0 200 130"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="1.3,22.3 1.3,106.6 170.2,128.5 172,39.6 " />
                <polygon points="13.5,37.6 13.5,95.5 49.3,99.8 49.2,41.4 " />
                <polyline points="31.5,39.5 31.5,85.8 49.3,87.9 " />
                <line x1="13.5" y1="95.5" x2="31.5" y2="85.8" />
                <polygon points="63.7,43.4 63.7,101.2 103.6,105.6 103.6,47 " />
                <polyline points="79.5,44.9 79.5,91.5 103.6,94.5 " />
                <line x1="63.7" y1="101.2" x2="79.5" y2="91.5" />
                <polygon points="118.2,48.7 118.3,108.6 158.1,113.2 158.1,52.7 " />
                <polyline points="131.9,50.1 131.9,99 158.1,102.3 " />
                <line x1="118.3" y1="108.6" x2="131.9" y2="99" />
                <polygon points="170.2,128.5 172,39.6 184.8,31.2 183,115.9 " />
                <polygon points="1.3,22.3 21.5,16.2 184.8,31.2 172,39.6 " />
                <polyline points="25.6,0.9 25,5.8 24.4,10.9 " />
                <polyline points="189.8,16.8 189.2,21.7 188.6,26.8 " />
                <line x1="25" y1="5.9" x2="189.2" y2="21.7" />
                <polyline points="187.9,116.2 192.9,116.2 197.9,116.2 " />
                <polyline points="189.3,30.7 194.4,30.7 199.3,30.7 " />
                <line x1="192.9" y1="116.2" x2="194.3" y2="30.7" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
