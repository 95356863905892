import PropTypes from 'prop-types';

const ChatIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--chat" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <defs>
                <filter id="6mnlshezla">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-983 -5852) translate(40 5697) translate(793 133)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M14.498 4.998c.83 0 1.502.673 1.502 1.503v4.998c0 .83-.673 1.502-1.502 1.502H14v2.496c0 .418-.471.642-.791.412l-.066-.056L10.29 13h-2.79c-.782 0-1.423-.596-1.496-1.358l-.007-.144V6.5c0-.83.672-1.503 1.502-1.503zm0 1.006H7.5c-.274 0-.497.222-.497.497v4.998c0 .274.223.497.497.497h3c.132 0 .26.053.355.147l2.14 2.14v-1.785c0-.246.178-.452.413-.494l.09-.008h1c.274 0 .497-.223.497-.497V6.5c0-.275-.223-.497-.497-.497zM8.5 0c.78 0 1.422.596 1.495 1.358l.007.144v2c0 .277-.225.502-.503.502-.246 0-.452-.178-.494-.412l-.008-.09v-2c0-.244-.176-.447-.408-.489l-.09-.008H1.503c-.244 0-.447.176-.489.408l-.008.09V6.5c0 .244.176.446.408.488l.09.009h.999c.247 0 .452.177.495.412l.008.09v1.785l1.14-1.14c.175-.175.446-.195.642-.059l.07.058c.174.175.194.446.058.642l-.058.07-2 1.999c-.295.295-.787.12-.85-.27l-.008-.086V8.002h-.497c-.78 0-1.422-.595-1.495-1.357L0 6.501V1.502C0 .722.596.08 1.358.007L1.502 0H8.5z"
                        transform="translate(150 22)"
                    />
                </g>
            </g>
        </svg>
    );
};

ChatIcon.defaultProps = {
    fill: 'currentColor',
};

ChatIcon.propTypes = {
    fill: PropTypes.string,
};

export default ChatIcon;
