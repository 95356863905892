import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--arrowUpRight"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd">
                <g fill={fill} fillRule="nonzero">
                    <path
                        d="M14.89 7.704l.02.013.036.025.003.003c.023.02.045.041.065.065l.023.029.011.016.012.019.004.005c.018.033.032.063.042.095l-.018-.048.01.025.003.006.002.009.01.03c.005.016.008.031.01.047.004.017.006.033.007.05l.002.026v.03l-.001.016-.006.051-.001.006-.003.013-.006.027-.009.028-.003.01-.004.011-.015.035.022-.056c-.01.032-.024.062-.04.09l-.002.004-.006.009-.02.03-.02.026c-.01.013-.023.027-.036.04l-.023.021-.005.004-.042.032-.022.014-.006.004c-.032.018-.062.032-.094.042l.045-.017-.022.009-.007.003H14.8c-.657.221-1.318.64-1.99 1.268l-.224.216c-.748.749-1.239 1.485-1.479 2.205-.087.262-.37.403-.632.316-.262-.087-.404-.37-.317-.632.294-.88.87-1.744 1.721-2.596.28-.279.56-.528.841-.747H1.632c-.276 0-.5-.223-.5-.5 0-.245.177-.449.41-.491l.09-.008 11.088.002c-.282-.22-.563-.47-.842-.749-.851-.851-1.427-1.715-1.72-2.595-.088-.262.054-.545.316-.633.262-.087.545.055.632.317.24.72.73 1.456 1.48 2.204.748.749 1.484 1.24 2.204 1.48l.01.003.011.004.032.014-.053-.022c.032.011.062.025.09.04z"
                        transform="translate(-663 -5704) translate(380 5240) translate(282.868 463.368) rotate(-45 8.132 8.132)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
