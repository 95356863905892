import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--installation"
            width="64"
            height="64"
            viewBox="0 0 50 55"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M17.703 12.158c-.549.968-1.46 1.677-2.534 1.972-1.073.294-2.219.15-3.185-.401L2.259 8.198C.245 7.053-.46 4.492.686 2.478S4.39-.24 6.406.905l9.727 5.536c2.011 1.145 2.714 3.704 1.57 5.717h0z"
                        transform="translate(-845 -5564) translate(720 5498) translate(126 67)"
                    />
                    <path
                        d="M17.701 12.16l4.87 2.764c1.342.765 1.81 2.472 1.047 3.815L16.69 30.892c-.763 1.342-.295 3.05 1.046 3.815l9.725 5.537"
                        transform="translate(-845 -5564) translate(720 5498) translate(126 67)"
                    />
                    <path
                        d="M22.939 50.188c-1.473-.073-2.794-.932-3.456-2.25-.662-1.318-.564-2.89.257-4.116l6.847-12.032c.613-1.359 1.921-2.272 3.408-2.38 1.486-.107 2.912.608 3.715 1.864l14.208 19.13c.331.445.368 1.044.093 1.526-.274.482-.808.757-1.36.7L22.94 50.187z"
                        transform="translate(-845 -5564) translate(720 5498) translate(126 67)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
