import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--facebook" width="18" height="18" viewBox="0 0 16 16" {...attrs}>
            <path
                d="M6.50248 13.418V8.02184H4.94019V6.07897H6.50248V4.41951C6.50248 3.11549 7.42533 1.91797 9.55178 1.91797C10.4127 1.91797 11.0494 1.99335 11.0494 1.99335L10.9992 3.80766C10.9992 3.80766 10.35 3.80189 9.64144 3.80189C8.87461 3.80189 8.75175 4.12463 8.75175 4.66032V6.07897H11.0602L10.9597 8.02184H8.75175V13.418H6.50248Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
