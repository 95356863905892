import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
    100% {
    transform: translateX(100%);
    }
`;

const Wrapper = styled.div`
    z-index: -1;
    .skeleton-box {
        background-color: #e2e8f0;
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0.2) 20%,
                rgba(255, 255, 255, 0.5) 60%,
                rgba(255, 255, 255, 0)
            );
            -webkit-animation: ${shimmer} 3s infinite;
            animation: ${shimmer} 3s infinite;
            content: '';
        }
    }
`;
const LoadingPlaceholder = () => {
    return (
        <Wrapper className="absolute top-0 left-0 h-full w-full">
            <span className="skeleton-box transition-transform transform-center block h-full"></span>
        </Wrapper>
    );
};

export default LoadingPlaceholder;
