import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--pantheon"
                width="200"
                viewBox="0 0 156 200"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="1.5,23.2 21.7,15.9 138.2,23.2 123.7,31.5 " />
                <polyline points="23,1.3 22.5,6.3 21.9,11.3 " />
                <polyline points="140.2,8.5 139.6,13.5 139.1,18.5 " />
                <line x1="22.5" y1="6.3" x2="139.6" y2="13.5" />
                <polyline points="144.6,181.3 149.6,181.3 154.6,181.3 " />
                <polyline points="144.3,23.4 149.3,23.4 154.3,23.4 " />
                <line x1="149.6" y1="181.3" x2="149.3" y2="23.4" />
                <polygon points="123.7,31.5 123.7,43 138.2,33.1 138.2,23.2 " />
                <polyline
                    points="1.5,23.2 1.5,33.7 8.9,34.2 8.9,171.8 1.5,171.2 1.4,181.3 123.8,198.7 123.8,188.1 116.5,187.2 
                116.8,42.4 123.7,43 "
                />
                <line x1="1.5" y1="171.2" x2="8.9" y2="165.9" />
                <polygon points="116.5,187.2 130.7,171.4 138.1,172.4 123.8,188.1 " />
                <polygon points="123.8,198.7 123.8,188.1 138.1,172.4 138.1,181.4 " />
                <polygon points="18.9,35.5 18.9,173 41.3,176 41.3,37.2 " />
                <polyline points="38.1,37 38.1,160.2 41.3,160.5 " />
                <line x1="18.9" y1="173" x2="38.1" y2="160.2" />
                <polygon points="51.4,38 74.2,39.8 74.2,181 51.4,177.4 " />
                <polyline points="67.8,39.3 67.8,163.8 74.2,164.6 " />
                <line x1="67.8" y1="163.8" x2="51.4" y2="177.4" />
                <polygon points="85.7,182.6 108.1,186.2 108.1,42.5 85.7,40.6 " />
                <polyline points="108.1,168.7 99.9,167.7 101.2,41.9 " />
                <line x1="85.7" y1="182.6" x2="99.9" y2="167.7" />
                <line x1="130.7" y1="38.3" x2="130.7" y2="171.4" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
