import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--square"
                width="120"
                height="120"
                viewBox="0 0 355 350.53"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <line x1="288.88" y1="6.93" x2=".97" y2="8.29" />
                <line x1="288.8" y1=".28" x2="288.8" y2="15.48" />
                <line x1=".5" x2=".5" y2="15.2" />
                <line x1="344.82" y1="66" x2="344.82" y2="350.24" />
                <line x1="335.24" y1="65.47" x2="355" y2="65.47" />
                <line x1="334.87" y1="349.72" x2="354.63" y2="349.72" />
                <rect x="1.27" y="62.63" width="287.51" height="287.51" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
