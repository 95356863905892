import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

const imageWithTextV2Store = set => ({
    activeTab: null,
    setActiveTab: _uid =>
        set(
            produce(draft => {
                draft.activeTab = _uid;
            })
        ),
    popupOpen: false,
    setPopupOpen: popupOpen =>
        set(
            produce(draft => {
                draft.popupOpen = popupOpen;
            })
        ),
    popup: null,

    setPopup: popup =>
        set(
            produce(draft => {
                draft.popup = popup;
            })
        ),
});

const useImageWithTextV2Store = create(devtools(imageWithTextV2Store));

export default useImageWithTextV2Store;
