import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--cube"
                width="150"
                viewBox="0 0 187 200"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="18.4,52.2 18.4,154.8 118.9,174.4 118.9,63.8 " />
                <polygon points="1.4,29.7 1.4,171.5 138.9,198.5 138.9,45.6 " />
                <polyline points="51.7,56.1 51.7,137.3 118.9,150 " />
                <line x1="18.4" y1="154.8" x2="51.7" y2="137.3" />
                <polygon points="138.9,198.5 138.9,45.6 167,32.2 167,174.4 " />
                <polygon points="1.4,29.7 38.1,17.4 167,32.2 138.9,45.6 " />
                <polyline points="39.5,1.5 39,6.5 38.4,11.5 " />
                <polyline points="169.8,15.8 169.2,20.8 168.7,25.8 " />
                <line x1="39" y1="6.5" x2="169.2" y2="20.8" />
                <polyline points="175.4,32 180.4,32 185.4,32 " />
                <polyline points="175.6,173.9 180.6,173.9 185.6,173.9 " />
                <line x1="180.6" y1="173.9" x2="180.4" y2="32" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
