import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--trash" width="16" height="17" viewBox="0 0 16 17" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g transform="translate(-1344.000000, -514.000000) translate(1344.000000, 514.307692)">
                    <path
                        d="M11.389 15H3.61c-.614 0-1.111-.56-1.111-1.25V2.5h10v11.25c0 .69-.497 1.25-1.111 1.25zM5.658 11.123L5.658 6.211M9.342 11.123L9.342 6.211M0 2.5L15 2.5M9.342 0H5.658C4.98 0 4.43.55 4.43 1.228v1.228h6.14V1.228C10.57.55 10.02 0 9.342 0z"
                        transform="translate(0.500000, 0.500000)"
                        fill="none"
                        stroke={fill}
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
