import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--twiter" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <path
                d="M14.6667 3.98407C14.1923 4.19856 13.6892 4.33934 13.1741 4.40175C13.7107 4.07347 14.1227 3.55362 14.3167 2.93422C13.8066 3.24321 13.2485 3.4609 12.6665 3.57791C12.1925 3.06241 11.5171 2.74023 10.7696 2.74023C9.33445 2.74023 8.17084 3.92791 8.17084 5.39276C8.17084 5.60069 8.19385 5.80311 8.23814 5.99731C6.07837 5.88665 4.16353 4.83064 2.88177 3.22579C2.65812 3.61756 2.52997 4.07327 2.52997 4.55933C2.52997 5.47963 2.98879 6.2915 3.68605 6.7672C3.27337 6.75401 2.86977 6.64024 2.50894 6.43539C2.50879 6.4465 2.50879 6.45761 2.50879 6.46877C2.50879 7.75397 3.40457 8.82609 4.59335 9.06978C4.21067 9.17601 3.80927 9.19156 3.4198 9.11524C3.75047 10.1691 4.71022 10.9359 5.84735 10.9574C4.95796 11.6688 3.83741 12.0929 2.61992 12.0929C2.41013 12.0929 2.20331 12.0803 2 12.0558C3.15004 12.8085 4.51602 13.2476 5.98357 13.2476C10.7636 13.2476 13.3774 9.20564 13.3774 5.70039C13.3774 5.58534 13.375 5.47094 13.3699 5.3572C13.8787 4.98179 14.3178 4.51681 14.6667 3.98407"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
