import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--closeCircle"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd">
                <g fill={fill}>
                    <path
                        d="M8 0C3.582 0 0 3.582 0 8s3.582 8 8 8 8-3.582 8-8c-.005-4.416-3.584-7.995-8-8zm0 14.91c-3.816 0-6.91-3.094-6.91-6.91 0-3.816 3.094-6.91 6.91-6.91 3.816 0 6.91 3.094 6.91 6.91 0 3.816-3.094 6.91-6.91 6.91zm2.923-9.848c-.217-.209-.562-.202-.77.014L8.083 7.23 5.833 5.062c-.14-.142-.345-.197-.536-.144-.192.053-.339.206-.385.4-.046.193.017.396.164.53L7.312 8l-2.236 2.153c-.217.209-.223.554-.015.77.103.108.245.169.394.168.14 0 .276-.055.378-.153l2.251-2.167 2.068 2.153c.21.216.554.223.771.014.217-.209.224-.554.016-.77L8.855 8l2.084-2.167c.208-.217.201-.562-.016-.771z"
                        transform="translate(-1344 -298) translate(1283 295) translate(61 3.308)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
