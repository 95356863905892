import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--linkArrow" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <path
                d="M7.52774 3.25624C7.25161 3.25747 7.02875 3.48232 7.02998 3.75846C7.03121 4.0346 7.25607 4.25746 7.53221 4.25623L9.18697 4.24884C9.85854 4.24585 10.32 4.24455 10.6715 4.28293C10.7857 4.29541 10.8812 4.31148 10.9622 4.33091L3.87659 11.4166C3.68133 11.6118 3.68133 11.9284 3.87659 12.1237C4.07185 12.3189 4.38844 12.3189 4.5837 12.1237L11.6693 5.03802C11.6888 5.11909 11.7048 5.21456 11.7173 5.32879C11.7557 5.68028 11.7544 6.14171 11.7514 6.81329L11.744 8.46805C11.7428 8.74419 11.9657 8.96904 12.2418 8.97027C12.5179 8.9715 12.7428 8.74865 12.744 8.47251L12.7515 6.78793C12.7544 6.15346 12.7567 5.63491 12.7114 5.22022C12.6643 4.78901 12.5606 4.40723 12.3064 4.07871C12.2506 4.00668 12.1904 3.93835 12.1262 3.87408C12.0619 3.80982 11.9936 3.74963 11.9215 3.69389C11.593 3.43965 11.2112 3.33593 10.78 3.28884C10.3653 3.24356 9.84679 3.24588 9.21231 3.24872L7.52774 3.25624Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
