import { Section } from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import { BodyText, H2 } from 'components/atoms/Typography';
import moment from 'moment';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';
import PropTypes from 'prop-types';
import {
    ChevronLeftIcon,
    ChevronRightIcon,
    CloseIcon,
    FacebookIcon,
    InstagramColorIcon,
    LinkIcon,
    MailContainedIcon,
    PinterestIcon,
    TwitterIcon,
} from 'components/atoms/Icons';
import { Backdrop, Fade, Modal } from '@material-ui/core';
import useInstagramModalStore from './components/useInstagramModalStore';
import { storyblokEditable } from '@storyblok/react';
import ScrollingCarousel from 'components/molecules/ScrollingCarousel';
import Link from 'next/link';
import { useQuery } from 'react-query';
import LoadingPlaceholder from 'components/atoms/LoadingPlaceholder';
import { getShopifyProductByHandle } from 'helpers/requests/shopify-data-requests';
import { gShopifyProduct, transformProductCard } from 'helpers/graphql';
import SiteContext from 'context/SiteContext';
import { ProductCard } from 'components/molecules/Card';
import Slider from 'components/molecules/Slider';
import { getInstagramTimeline } from 'helpers/requests/foursixty-request';

const Hotspot = styled.span`
    --background: 255, 255, 255;
    --foreground: 0, 0, 0;
    --dimension: 20px;
    position: absolute;
    left: ${props => props.left || '50'}%;
    top: ${props => props.top || '50'}%;
    display: block;
    width: max-content;
    max-width: 150px;
    border-radius: 50%;
    height: var(--dimension);
    width: var(--dimension);
    line-height: var(--dimension);
    font-size: 12px;
    text-align: center;
    display: inline-block;
    background: rgba(var(--background), 0.85);
    transition: box-shadow 0.25s, transform 0.25s, opacity 0.25s;
    @media (hover: hover) {
        opacity: 0;
    }
    .fs-hotspot__overlink-text {
        --foreground: 255, 255, 255;
        --background: 0, 0, 0;
        padding: 5px 10px;
        color: white;
        z-index: 10;
        margin-left: 10px;
        margin-top: 10px;
        font-size: 12px;
        background-color: rgba(var(--background), 0.75);
        min-width: 250px;
        opacity: 0;
        pointer-events: none;
        font-weight: bold;
        text-align: left;
        display: none;
        transition: transform 0.25s, opacity 0.25s;
    }
    .fs-hotspot__overlink-text:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 0px;
        height: 0px;
        border-style: solid;
        border-width: 0 10px 10px 0;
        border-color: transparent transparent rgba(var(--background), 0.75) transparent;
        transform: translateY(-100%);
    }
    .fs-hotspot__overlink-text--right:before {
        left: auto;
        right: 0;
        border-width: 0 0 10px 10px;
    }
    .icon--chevronRight path {
        stroke-width: 2px;
    }
    &:hover {
        box-shadow: 0 5px 0px #222;
        transform: translateY(-5px);
        .fs-hotspot__overlink-text {
            display: block;
            transform: translateY(5px);
            opacity: 1;
        }
        .fs-hotspot__overlink-text--right {
            transform: translateX(-100%);
        }
    }
`;

const Organism = styled(Section)`
    .instagramItemList {
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        /* Hide scrollbar for IE, Edge add Firefox */
        --ms-overflow-style: none;
        scrollbar-width: none; /* Firefox */
    }
    .instagramItem {
        flex: 0 0 auto;

        @media screen and ${breakpoints.maxMd} {
            &:first-child {
                margin-left: 2rem;
                padding-left: 0;
            }
            &:last-child {
                margin-right: 2rem;
                padding-right: 0;
            }
        }
    }
`;

const SliderContainer = styled.div`
    .slick-slider:has(.slick-arrow) {
        padding-bottom: 64px;
    }
    .slick-slider .slick-track {
        display: flex;
        margin-inline-start: 0;
    }
    .slick-slider .slick-slide {
        display: flex;
        height: inherit;
        > * {
            height: inherit;
            flex: 1;
            display: flex;
        }
    }
    @media screen and ${breakpoints.maxMd} {
        .slick-list {
            overflow: visible;
        }
    }
    .slick-arrow {
        position: absolute;
        top: auto;
        bottom: 0;
        z-index: 10;
        left: 50%;
        right: auto;
    }
    .slick-prev {
        transform: translateX(calc(-50% + 0.5px));
    }
    .slick-next {
        transform: translateX(calc(50% - 0.5px));
    }
    @media screen and ${breakpoints.maxMd} {
        .slick-arrow {
            display: none;
        }
    }
`;

const InstagramCarousel = ({ blok }) => {
    const timelineQuery = useQuery({
        queryKey: blok.component,
        queryFn: getInstagramTimeline,
    });
    const settings = {
        arrows: true,
        adaptiveHeight: false,
        draggable: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    const data = useMemo(() => {
        const { heading, profilePicture, name } = { ...blok };
        return {
            heading,
            profilePicture,
            ...(timelineQuery?.data?.timeline && {
                timeline: {
                    ...timelineQuery.data.timeline,
                    results: timelineQuery.data.timeline.results?.slice(0, 12),
                },
            }),
            name,
        };
    }, [blok, timelineQuery.data]);

    const { modalOpen, handleCloseModal, selectedPost, handleChangeSelectedPost } =
        useInstagramModalStore(
            ({ modalOpen, handleCloseModal, selectedPost, handleChangeSelectedPost }) => ({
                modalOpen,
                handleCloseModal,
                selectedPost,
                handleChangeSelectedPost,
            })
        );

    const handleOnPostClick = postId => {
        const postIndex = data.timeline.results.findIndex(({ id }) => id === postId);
        if (postIndex !== -1) {
            const post = data.timeline.results[postIndex];
            handleChangeSelectedPost({
                ...post,
                isLast: postIndex === data.timeline.results.length - 1,
                isFirst: postIndex === 0,
            });
        }
    };

    const handleNextPost = () => {
        const postIndex = data.timeline.results.findIndex(({ id }) => id === selectedPost.id);
        if (postIndex === -1) {
            return;
        }
        if (postIndex !== data.timeline.results.length - 1) {
            handleChangeSelectedPost({
                ...data.timeline.results[postIndex + 1],
                isLast: postIndex + 1 === data.timeline.results.length - 1,
                isFirst: false,
            });
        }
    };

    const handlePreviousPost = () => {
        const postIndex = data.timeline.results.findIndex(({ id }) => id === selectedPost.id);
        if (postIndex === -1) {
            return;
        }
        if (postIndex !== 0) {
            handleChangeSelectedPost({
                ...data.timeline.results[postIndex - 1],
                isLast: false,
                isFirst: postIndex - 1 === 0,
            });
        }
    };

    const handleOnKeyDown = e => {
        if (e.keyCode === 37 || e.keyCode === 38) {
            // previous
            handlePreviousPost();
        } else if (e.keyCode === 39 || e.keyCode === 40) {
            // next
            handleNextPost();
        }
    };

    return (
        <>
            <Organism
                className="my-16 lg:my-24 container overflow-hidden"
                paddingMobile="0"
                {...storyblokEditable(blok)}
            >
                {data?.heading && <H2 className="px-8 lg:px-0">{data.heading}</H2>}
                {data?.timeline?.results?.length > 0 && (
                    <div className="lg:-mx-5">
                        <ScrollingCarousel
                            ariaLabel="post"
                            autoPlay={false}
                            prevNextButtons={true}
                            PreviousButton={PreviousButton}
                            NextButton={NextButton}
                            alignItems="start"
                        >
                            {data.timeline.results.map(
                                ({
                                    author_username,
                                    id,
                                    main_image_url,
                                    resource_url,
                                    service,
                                    time_posted,
                                    title,
                                    visible,
                                }) => (
                                    <div
                                        className={`instagramItem w-4/6 sm:w-5/12 md:w-4/12 lg:w-3/12 px-2.5 lg:px-5 py-8 lg:py-12 ${
                                            visible ? '' : 'hidden'
                                        }`}
                                        key={id}
                                    >
                                        <div className="p-3 bg-instagram block transform transition-transform hover:scale-105 lg:hover:scale-110">
                                            <div className="w-full aspect-w-4 aspect-h-3 relative">
                                                <button
                                                    onClick={() => {
                                                        handleOnPostClick(id);
                                                    }}
                                                >
                                                    <img
                                                        className="w-full h-full object-cover object-center"
                                                        src={main_image_url}
                                                        width="272"
                                                        height="268"
                                                        alt={title}
                                                        loading="lazy"
                                                    />
                                                </button>
                                                <span className="w-12 h-12 border-4 border-white rounded-full overflow-hidden absolute top-full left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                                                    {data?.profilePicture?.filename &&
                                                    data?.profilePicture?.filename !== '' ? (
                                                        <Image
                                                            className="w-full h-full object-cover object-center"
                                                            src={data.profilePicture.filename}
                                                            alt={`${data.name} logo`}
                                                            width={96}
                                                            height={96}
                                                        />
                                                    ) : (
                                                        <span className="w-full h-full flex items-center justify-center bg-gray-400">
                                                            Z
                                                        </span>
                                                    )}
                                                </span>
                                            </div>
                                            <div className="pt-8 pb-3 px-4 text-center">
                                                {data?.name && (
                                                    <BodyText
                                                        className="uppercase mb-1"
                                                        fontWeight="600"
                                                    >
                                                        {data.name}
                                                    </BodyText>
                                                )}
                                                <InstagramColorIcon
                                                    className="w-5 h-5 mx-auto mb-3"
                                                    id={id}
                                                />
                                                <div className="p-4 bg-light">
                                                    {title && (
                                                        <BodyText
                                                            className="line-clamp-3 mb-4"
                                                            fontSize="0.75rem"
                                                        >
                                                            {title}
                                                        </BodyText>
                                                    )}
                                                    <BodyText
                                                        className="uppercase tracking-tight block mb-4"
                                                        as="a"
                                                        href={resource_url}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                        fontSize="0.625rem"
                                                        color="rgb(var(--instagram-link-color))"
                                                    >
                                                        {author_username}
                                                        {' // '}
                                                        {service}
                                                        {' // '}
                                                        {moment(time_posted).format('DD MMMM YYYY')}
                                                    </BodyText>
                                                    <ul className="grid grid-flow-col-dense gap-3 place-items-center place-content-center">
                                                        <li>
                                                            <a
                                                                aria-label="share on facebook"
                                                                href={`https://www.facebook.com/sharer/sharer.php?u=${resource_url}?utm_source=ig_web_button_share_sheet`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <FacebookIcon className="w-4 h-4" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                aria-label="share on twitter"
                                                                href={`https://twitter.com/share?text=See this Instagram post by @${author_username}&url=${resource_url}?utm_source=ig_web_button_share_sheet`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <TwitterIcon className="w-4 h-4" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                aria-label="share by email"
                                                                href={`mailto:?subject=See this Instagram post by @${author_username}&body=See this Instagram post by @${author_username}: ${resource_url}?utm_source=ig_web_button_share_sheet`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <MailContainedIcon className="w-4 h-4" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                aria-label="share on pinterest"
                                                                href={`http://pinterest.com/pin/create/button/?url=${resource_url}&description=Shop this Instagram from @${author_username}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                data-pin-do="button"
                                                                data-pin-config="above"
                                                            >
                                                                <PinterestIcon className="w-4 h-4" />
                                                            </a>
                                                        </li>
                                                        <li>
                                                            <a
                                                                aria-label="permalink"
                                                                href={`https://foursixty.com/zia-tile_1/${id}`}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <LinkIcon className="w-4 h-4" />
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            )}
                        </ScrollingCarousel>
                    </div>
                )}
            </Organism>
            {selectedPost && (
                <Modal
                    open={modalOpen}
                    onClose={handleCloseModal}
                    aria-labelledby={'postInfo'}
                    aria-describedby={'postTitle'}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={modalOpen}>
                        <div
                            className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full max-w-screen-xxl h-full lg:h-auto lg:max-h-full overflow-y-auto bg-light cursor-default focus:outline-none"
                            role="button"
                            tabIndex="0"
                            onKeyDown={handleOnKeyDown}
                        >
                            <div className="grid grid-cols-6 lg:grid-cols-12 gap-8 lg:p-5">
                                <div className="col-span-full lg:col-span-7 relative group">
                                    <img
                                        className="max-w-full lg:w-full"
                                        src={selectedPost.main_image_url}
                                        alt=""
                                    />
                                    {selectedPost.links?.length > 0 &&
                                        selectedPost.links?.map((link, index) => (
                                            <Hotspot
                                                key={link.id}
                                                as={Link}
                                                className="group-hover:opacity-100"
                                                href={link.link_url}
                                                left={link.relative_x * 100}
                                                top={link.relative_y * 100}
                                            >
                                                <span>{index + 1}</span>
                                                <span
                                                    className={`fs-hotspot__overlink-text ${
                                                        link.relative_x * 100 > 50
                                                            ? 'fs-hotspot__overlink-text--right'
                                                            : ''
                                                    }`}
                                                >
                                                    {link.link_text}
                                                </span>
                                            </Hotspot>
                                        ))}
                                </div>
                                <div className="col-span-full lg:col-span-5 px-5 lg:p-0 overflow-hidden">
                                    <div className="flex flex-wrap gap-x-8">
                                        <button
                                            disabled={selectedPost.isFirst}
                                            onClick={handlePreviousPost}
                                        >
                                            <ChevronLeftIcon
                                                className="w-8 h-8"
                                                fill={
                                                    selectedPost.isFirst
                                                        ? 'rgb(var(--subdued-text-color))'
                                                        : 'currentColor'
                                                }
                                            />
                                        </button>
                                        <button
                                            disabled={selectedPost.isLast}
                                            onClick={handleNextPost}
                                        >
                                            <ChevronRightIcon
                                                className="w-8 h-8"
                                                fill={
                                                    selectedPost.isLast
                                                        ? 'rgb(var(--subdued-text-color))'
                                                        : 'currentColor'
                                                }
                                            />
                                        </button>
                                        <button className="ml-auto" onClick={handleCloseModal}>
                                            <CloseIcon className="w-8 h-8" />
                                        </button>
                                    </div>
                                    <div className="pt-8 pb-11 lg:pt-11 lg:pr-8 text-center">
                                        {selectedPost.title && (
                                            <div className="mb-8">
                                                {selectedPost.title.split('\n').map((line, i) => (
                                                    <BodyText
                                                        as="div"
                                                        className="mb-4"
                                                        fontSize="0.875rem"
                                                        key={i}
                                                    >
                                                        {line}
                                                    </BodyText>
                                                ))}
                                            </div>
                                        )}
                                        <div className=" mb-8">
                                            <BodyText
                                                className="uppercase tracking-tight"
                                                as="a"
                                                href={selectedPost.resource_url}
                                                target="_blank"
                                                rel="noreferrer"
                                                fontSize="0.625rem"
                                                color="rgb(var(--instagram-link-color))"
                                            >
                                                {selectedPost.author_username}
                                                {' // '}
                                                {selectedPost.service}
                                                {' // '}
                                                {moment(selectedPost.time_posted).format(
                                                    'DD MMMM YYYY'
                                                )}
                                            </BodyText>
                                        </div>
                                        <ul className="grid grid-flow-col-dense gap-3 place-items-center place-content-center">
                                            <li>
                                                <a
                                                    aria-label="share on facebook"
                                                    href={`https://www.facebook.com/sharer/sharer.php?u=${selectedPost.resource_url}?utm_source=ig_web_button_share_sheet`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <FacebookIcon
                                                        className="w-4 h-4"
                                                        fill="rgb(var(--light-text-color))"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    aria-label="share on twitter"
                                                    href={`https://twitter.com/share?text=See this Instagram post by @${selectedPost.author_username}&url=${selectedPost.resource_url}?utm_source=ig_web_button_share_sheet`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <TwitterIcon
                                                        className="w-4 h-4"
                                                        fill="rgb(var(--light-text-color))"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    aria-label="share by email"
                                                    href={`mailto:?subject=See this Instagram post by @${selectedPost.author_username}&body=See this Instagram post by @${selectedPost.author_username}: ${selectedPost.resource_url}?utm_source=ig_web_button_share_sheet`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <MailContainedIcon
                                                        className="w-4 h-4"
                                                        fill="rgb(var(--light-text-color))"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    aria-label="share on pinterest"
                                                    href={`http://pinterest.com/pin/create/button/?url=${selectedPost.resource_url}&description=Shop this Instagram from @${selectedPost.author_username}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                    data-pin-do="button"
                                                    data-pin-config="above"
                                                >
                                                    <PinterestIcon
                                                        className="w-4 h-4"
                                                        fill="rgb(var(--light-text-color))"
                                                    />
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    aria-label="permalink"
                                                    href={`https://foursixty.com/zia-tile_1/${selectedPost.id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                                                    <LinkIcon
                                                        className="w-4 h-4"
                                                        fill="rgb(var(--light-text-color))"
                                                    />
                                                </a>
                                            </li>
                                        </ul>
                                        {selectedPost.links?.length > 0 && (
                                            <div className="mt-10 lg:mt-16 max-w-xs mx-auto">
                                                <H2
                                                    className="text-center mb-8"
                                                    textTransform="none"
                                                >
                                                    Shop the Tile
                                                </H2>
                                                <SliderContainer className="-mx-2.5 xxl:-mx-5">
                                                    <Slider settings={settings}>
                                                        {selectedPost.links?.map(link => (
                                                            <div
                                                                key={link.id}
                                                                className="px-2.5 xxl:px-5"
                                                            >
                                                                <ResultLinkProduct
                                                                    resultLink={link}
                                                                />
                                                            </div>
                                                        ))}
                                                    </Slider>
                                                </SliderContainer>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fade>
                </Modal>
            )}
        </>
    );
};

InstagramCarousel.propTypes = {
    blok: PropTypes.object,
};
const ResultLinkProduct = ({ resultLink }) => {
    // Create a URL object
    const urlObj = new URL(resultLink?.link_url);

    if (!urlObj) return null;
    // Get the pathname from the URL object
    const pathname = urlObj.pathname;
    const handle = pathname?.split('/products/')?.[1];

    if (!handle || handle.length < 1) return null;

    const {
        store: { settings },
    } = useContext(SiteContext);

    const inventoryQuantityThreshold = useMemo(() => {
        return Number(settings?.product?.inventoryQuantityThreshold) || 0;
    }, [settings]);

    // Queries
    const { data, isLoading, error } = useQuery(`result-product-${handle}`, async () => {
        const { data: productRespData } = await getShopifyProductByHandle(handle);
        if (!productRespData?.product) return;
        const product = gShopifyProduct(productRespData);
        const productCardProps = transformProductCard(product, inventoryQuantityThreshold);
        return productCardProps;
    });

    if (isLoading)
        return (
            <div className="relative h-56 mb-4">
                <LoadingPlaceholder />
            </div>
        );
    if (error) return null;
    return (
        <ProductCard className="w-full" {...data} pageName="Homepage" sectionName="Product list" />
    );
};
ResultLinkProduct.propTypes = {
    resultLink: PropTypes.object,
};

const PreviousButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 left-0 -translate-y-1/2 -translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronLeftIcon />
    </button>
);

PreviousButton.propTypes = {
    disabled: PropTypes.bool,
};

const NextButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 right-0 -translate-y-1/2  translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronRightIcon />
    </button>
);

NextButton.propTypes = {
    disabled: PropTypes.bool,
};
export default InstagramCarousel;
