import PropTypes from 'prop-types';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <svg className="icon icon--close-v2" width="37" height="36" viewBox="0 0 37 36" {...attrs}>
            <path
                d="M27.5 9.00024L9.5 27.0002"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M9.5 9.00024L27.5 27.0002"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
