import PropTypes from 'prop-types';

const InfoIcon = ({ fill, className, ...attrs }) => {
    return (
        <svg
            className={`icon icon--info ${className}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd">
                =
                <g transform="translate(-232 -1576) translate(40 1211) translate(0 365) translate(106)">
                    <g fill={fill} fillRule="nonzero">
                        <path
                            d="M8 0c4.418 0 8 3.582 8 8s-3.582 8-8 8-8-3.582-8-8 3.582-8 8-8zm0 1.006C4.137 1.006 1.006 4.137 1.006 8S4.137 14.994 8 14.994 14.994 11.863 14.994 8 11.863 1.006 8 1.006zM7.467 6.43c.534 0 .974.405 1.03.924l.006.113v2.666c0 .28.204.513.472.557l.092.007H9.6c.278 0 .503.225.503.503 0 .247-.178.452-.413.495l-.09.008h-.533c-.819 0-1.491-.627-1.563-1.427l-.007-.143V7.467c0-.012-.006-.021-.015-.027l-.015-.004h-.534c-.277 0-.503-.225-.503-.503 0-.247.178-.452.413-.494l.09-.009h.534zm.266-2.666l.105.007c.375.05.665.373.665.762 0 .425-.345.77-.77.77-.425 0-.77-.345-.77-.77 0-.39.29-.711.666-.762l.104-.007z"
                            transform="translate(86)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

InfoIcon.defaultProps = {
    fill: 'currentColor',
    className: '',
};

InfoIcon.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default InfoIcon;
