import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--eclipse"
                width="200"
                viewBox="0 0 220.2 172.2"
                {...attrs}
                fill={fill}
                stroke="none"
            >
                <path
                    d="M197.7,156.6c-3.7,1.2-7.4,2.4-11.1,3.5c-12.3,3.9-24.7,7.8-37,11.7c-0.7,0.2-1.4,0.3-2.1,0.2
                    c-5.7-0.6-11.4-1.3-17.2-1.9c-5.6-0.6-11.1-1.3-16.7-1.9c-6.2-0.7-12.4-1.4-18.6-2.1c-5.7-0.6-11.4-1.3-17.2-2
                    c-6.2-0.7-12.3-1.4-18.5-2.1c-6.1-0.7-12.2-1.4-18.3-2.1c-6.3-0.7-12.5-1.4-18.8-2.2c-6.1-0.7-12.3-1.4-18.4-2.1
                    c-1.2-0.1-2.4-0.3-3.7-0.4C0,113.2,0,71.1,0,29c2.2-0.4,4.5-0.7,6.7-1c8-1.3,16-2.5,23.9-3.8c7.2-1.1,14.4-2.3,21.7-3.4
                    c3.3-0.5,6.5-1.1,9.8-1.5c0.9-0.1,1.8,0,2.7,0c7.1,0.5,14.2,1,21.2,1.5c7.1,0.5,14.2,1.1,21.2,1.6c6.3,0.5,12.5,0.9,18.8,1.4
                    c7.1,0.5,14.2,1.1,21.3,1.6c6.2,0.5,12.5,0.9,18.7,1.4c7.1,0.5,14.3,1.1,21.4,1.6c2.9,0.2,5.9,0.4,8.8,0.6c0.4,0,0.8,0.1,1.3,0.1
                    C197.7,71.6,197.7,114.1,197.7,156.6z M147.9,171.4c0.6-43.9,1.2-87.7,1.8-131.5C100,36.6,50.4,33.2,0.8,29.8
                    c0,41.6,0,83.2,0,124.8C49.8,160.2,98.8,165.8,147.9,171.4z M196.8,29.9c-15.5,3.4-30.9,6.7-46.3,10c-0.1,9.3-0.3,18.4-0.4,27.5
                    c-0.1,4.5-0.1,9.1-0.2,13.6c-0.1,9.2-0.2,18.5-0.4,27.7c-0.1,4.5-0.1,9-0.2,13.5c-0.1,9.8-0.2,19.6-0.4,29.4
                    c-0.1,5.1-0.1,10.1-0.2,15.2c0,1.5,0,2.9,0,4.5c16.1-5.1,32.1-10.2,48-15.2C196.8,114,196.8,72,196.8,29.9z M4.4,29.1
                    C4.4,29.2,4.4,29.2,4.4,29.1c0.8,0.1,1.6,0.2,2.4,0.3c5.8,0.4,11.7,0.8,17.5,1.2c6.7,0.5,13.5,0.9,20.2,1.4
                    c6.7,0.5,13.4,0.9,20.2,1.4c6.8,0.5,13.6,0.9,20.3,1.4c5.8,0.4,11.6,0.8,17.4,1.2c7.7,0.5,15.4,1.1,23.1,1.6c7,0.5,13.9,1,20.9,1.4
                    c1.3,0.1,2.7,0.4,3.9,0.1c13.6-2.9,27.3-5.9,40.9-8.8c0.8-0.2,1.6-0.4,2.4-0.5c-0.4-0.2-0.7-0.3-1.1-0.3
                    c-5.4-0.4-10.8-0.8-16.2-1.2c-6.3-0.5-12.6-0.9-19-1.4c-5.4-0.4-10.7-0.8-16.1-1.2c-7.1-0.5-14.3-1-21.4-1.6
                    c-5.5-0.4-10.9-0.8-16.4-1.2c-6.2-0.5-12.4-0.9-18.6-1.4c-5.5-0.4-11-0.8-16.6-1.2c-1.9-0.1-3.8-0.5-5.6-0.3
                    c-6.7,0.9-13.3,2-20,3.1c-7.2,1.1-14.4,2.3-21.6,3.4C15.6,27.4,10,28.2,4.4,29.1z"
                />
                <path
                    d="M19.3,95.2c0.1-9.7,2.6-18.6,8.6-26.4c5.1-6.6,11.9-11,19.7-13.8c8.3-2.9,16.8-3.8,25.5-3.2
                    c14.3,1,27,6.3,38,15.4c8,6.6,14,14.7,17.1,24.6c3.4,10.8,2.9,21.4-1.9,31.7c-3.6,7.6-9.5,13.1-17,17c-7,3.6-14.6,5.4-22.4,6.1
                    c-4.6,0.4-9.2,0.5-13.8,0c-7.6-0.8-14.7-2.9-21.6-6.3c-6.4-3.1-12.2-7.2-17.3-12.2c-6.8-6.8-11.9-14.6-14-24.1
                    c-0.6-2.9-0.8-5.9-1.2-8.8C19.1,95.2,19.2,95.2,19.3,95.2z M86.2,55c-0.2-0.1-0.3-0.1-0.4-0.1c-6.5-1.8-13.2-2.6-20-2.3
                    c-5.9,0.3-11.7,1.1-17.3,3c-8.6,2.9-15.9,7.8-21.2,15.3c-4.3,6.2-6.4,13.1-7.1,20.5c-0.7,8,0.8,15.5,4.5,22.6
                    c3.8,7.2,9,13.3,15.4,18.3c11.3,8.8,24,13.6,38.4,13.9c5.9,0.1,11.7-0.5,17.5-1.8c8.2-1.9,15.8-5.2,22-11.1c0.3-0.3,0.6-0.7,1-1
                    c-0.2-0.1-0.3-0.2-0.4-0.2c-7.2-2-13.8-5-20-9.2c-8.8-6.1-16-13.6-20.3-23.5c-5.2-11.9-4.9-23.7,1-35.3
                    C81.2,60.7,83.5,57.7,86.2,55z M119.7,131.7c0-0.1,0-0.1,0.1-0.2c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.3,0.4-0.4
                    c5.9-6.7,8.7-14.6,9.2-23.5c0.6-10.3-2.4-19.6-8.1-28c-6.7-9.7-15.7-16.7-26.4-21.5c-2.5-1.1-5.1-2-7.7-3c-1,1.1-1.9,2.1-2.7,3.1
                    c-7.2,8.9-9.9,19-8.3,30.3c1,7,3.9,13.3,8,19c7.1,9.9,16.6,16.9,27.8,21.4C114.5,130,117.1,130.8,119.7,131.7z"
                />

                <line
                    x1="216.8"
                    y1="28.4"
                    x2="216.8"
                    y2="156.5"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
                <line
                    x1="213.5"
                    y1="28.2"
                    x2="220.2"
                    y2="28.2"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
                <line
                    x1="213.4"
                    y1="156.3"
                    x2="220.1"
                    y2="156.3"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
                <line
                    x1="62.9"
                    y1="3.4"
                    x2="197.6"
                    y2="13.2"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
                <line
                    x1="62.5"
                    y1="6.7"
                    x2="63"
                    y2="0"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
                <line
                    x1="197.3"
                    y1="16.6"
                    x2="197.8"
                    y2="9.9"
                    fill="none"
                    stroke={fill}
                    strokeWidth={0.5}
                />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
