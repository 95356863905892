import PropTypes from 'prop-types';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <svg className="icon icon--calendar" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 0C4.74546 0 4.94961 0.1974 4.99194 0.457716L5 0.558022V2H11V0.558022C11 0.249835 11.2239 0 11.5 0C11.7455 0 11.9496 0.1974 11.9919 0.457716L12 0.558022V2H15.4973C15.7749 2 16 2.22504 16 2.50265V15.4973C16 15.775 15.7749 16 15.4973 16H0.502678C0.225056 16 0 15.775 0 15.4973V2.50265C0 2.22504 0.225056 2 0.502678 2H4V0.558022C4 0.249835 4.22386 0 4.5 0ZM11 3.00505V4.44198L11.0081 4.54228C11.0504 4.8026 11.2545 5 11.5 5C11.7761 5 12 4.75017 12 4.44198V3.00505H14.9943V14.9943H1.00474V3.00505H4V4.44198L4.00806 4.54228C4.05039 4.8026 4.25454 5 4.5 5C4.77614 5 5 4.75017 5 4.44198V3.00505H11ZM6 6.50245V9H3.50214C3.22482 9 3 9.22482 3 9.50214V12.4979C3 12.7752 3.22482 13 3.50214 13H6.49786L6.50015 13L6.50245 13H9.5H12.4976C12.775 13 13 12.775 13 12.4976V9.5V6.50245C13 6.22495 12.775 6 12.4976 6H9.5H6.50245C6.22495 6 6 6.22495 6 6.50245ZM11.9947 9V7.00437H10V9H11.9947ZM10 10H11.9947V11.9947H10V10ZM9 9V7.00437H7.00437V9H9ZM7.00437 10H9V11.9947H7.00437V10ZM5.9952 11.9953V10.0038H4.00371V11.9953H5.9952Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
