import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--mail" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g fill={fill} fillRule="nonzero">
                    <path
                        d="M2 13.5c-.78 0-1.42-.595-1.493-1.356L.5 12V4c0-.78.595-1.42 1.356-1.493L2 2.5h12l.145.007C14.905 2.58 15.5 3.22 15.5 4v8l-.006.144C15.42 12.905 14.78 13.5 14 13.5H2zm12-1l.09-.008c.234-.042.41-.247.41-.492V4.217L9.64 7.956l-.165.117c-.395.26-.842.404-1.295.435L8 8.514l-.18-.006c-.453-.03-.9-.176-1.294-.435l-.165-.117L1.5 4.217V12c0 .245.177.45.41.492L2 12.5h12zM2.21 3.5l4.762 3.663c.303.234.666.35 1.03.351l.154-.007c.31-.03.615-.144.875-.344L13.79 3.5H2.21z"
                        transform="translate(-40 -185) translate(40 143) translate(0 35) translate(0 7)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
