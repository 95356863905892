import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--save" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-756 -135) translate(493 112)">
                    <path
                        fill={fill}
                        d="M10.797 4.805H9.203c-.114 0-.21-.04-.289-.117-.078-.079-.117-.172-.117-.282V2c0-.11.039-.203.117-.281.078-.078.175-.117.29-.117h1.593c.114 0 .21.039.289.117.078.078.117.172.117.281v2.406c0 .11-.039.203-.117.282-.078.078-.175.117-.29.117zM9.602 2.406V4h.796V2.406h-.796zm6.28-.289c.037.042.066.086.087.133.02.047.031.099.031.156v13.196c0 .109-.04.203-.117.28-.078.079-.172.118-.281.118H.398c-.109 0-.203-.04-.28-.117C.038 15.805 0 15.71 0 15.602V.406C0 .292.04.196.117.117.195.04.29 0 .398 0h13.204c.052 0 .102.01.152.031.05.021.092.05.129.086l2 2zM4.798.805v4.797H12V.805H4.797zm8 14.398V8.805H3.203v6.398h9.594zm2.406 0V2.57L13.438.805h-.641V6c0 .115-.04.21-.117.29-.078.077-.172.116-.282.116h-8c-.109 0-.203-.039-.28-.117C4.038 6.211 4 6.115 4 6V.805H.797v14.398h1.601V8.406c0-.114.04-.21.118-.289.078-.078.171-.117.28-.117h10.407c.11 0 .203.04.281.117.079.078.118.175.118.29v6.796h1.601z"
                        transform="translate(263 23)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
