import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--addToCart" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-642 -641) translate(379 619)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M11.957 12.437c.966 0 1.743.8 1.743 1.781 0 .98-.777 1.782-1.743 1.782-.966 0-1.743-.801-1.743-1.782 0-.98.777-1.781 1.743-1.781zm-5 0c.966 0 1.743.8 1.743 1.781 0 .98-.777 1.782-1.743 1.782-.966 0-1.743-.801-1.743-1.782 0-.98.777-1.781 1.743-1.781zm5 1c-.406 0-.743.346-.743.781 0 .435.337.782.743.782.407 0 .743-.347.743-.782 0-.435-.336-.781-.743-.781zm-5 0c-.406 0-.743.346-.743.781 0 .435.337.782.743.782.407 0 .743-.347.743-.782 0-.435-.336-.781-.743-.781zM1.192.087l.16.006c.84.068 1.555.67 1.773 1.51l2.159 8.308.029.083c.081.185.258.304.454.304h7.214l.082-.007c.189-.033.348-.178.4-.38l1.557-5.404.032-.084c.105-.212.35-.326.586-.258.266.077.419.354.342.619l-1.552 5.391-.04.129c-.21.587-.77.994-1.408.994H5.77l-.136-.006c-.624-.056-1.154-.505-1.317-1.129L2.157 1.854l-.035-.11c-.15-.394-.517-.657-.93-.657H.5L.41 1.08C.177 1.037 0 .833 0 .587c0-.276.224-.5.5-.5h.692zM9.457 3.5c.277 0 .5.224.5.5v1.5h1.5l.09.008c.234.042.41.247.41.492 0 .276-.223.5-.5.5h-1.5V8l-.008.09c-.042.233-.246.41-.492.41-.276 0-.5-.224-.5-.5V6.5H7.46l-.09-.008c-.233-.042-.41-.247-.41-.492 0-.276.224-.5.5-.5h1.497V4l.008-.09c.043-.233.247-.41.492-.41z"
                        transform="translate(263 22)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
