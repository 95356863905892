import PropTypes from 'prop-types';

import Account from './Account';
import AddToCart from './AddToCart';
import AddToProject from './AddToProject';
import ArrowDown from './ArrowDown';
import ArrowLeft from './ArrowLeft';
import ArrowRight from './ArrowRight';
import ArrowUpRight from './ArrowUpRight';
import Burger from './Burger';
import CaretDown from './CaretDown';
import Cart from './Cart';
import Chat from './Chat';
import Check from './Check';
import ChevronLeft from './ChevronLeft';
import ChevronRight from './ChevronRight';
import CheckFilled from './CheckFilled';
import Close from './Close';
import CloseCircle from './CloseCircle';
import EditPen from './EditPen';
import Facebook from './Facebook';
import Faq from './Faq';
import Fireplace from './Fireplace';
import Info from './Info';
import Instagram from './Instagram';
import InstagramColor from './InstagramColor';
import Installation from './Installation';
import InstallationGuide from './InstallationGuide';
import Link from './Link';
import Lock from './Lock';
import Logo from './Logo';
import LogoMobile from './LogoMobile';
import Logout from './Logout';
import Mail from './Mail';
import MailContained from './MailContained';
import Minus from './Minus';
import Pinterest from './Pinterest';
import Plus from './Plus';
import Print from './Print';
import Quotes from './Quotes';
import Save from './Save';
import Search from './Search';
import ShipHands from './ShipHands';
import Shipping from './Shipping';
import Shipment from './Shipment';
import SizeAero from './SizeAero';
import SizeAlcazar from './SizeAlcazar';
import SizeDiamond from './SizeDiamond';
import SizeGambit from './SizeGambit';
import SizeHex from './SizeHex';
import SizeLattice from './SizeLattice';
import SizeNouveau from './SizeNouveau';
import SizePerpetualCheck from './SizePerpetualCheck';
import SizePrismatic from './SizePrismatic';
import SizeRadianOffsetInline from './SizeRadianOffsetInline';
import SizeRectangle from './SizeRectangle';
import SizeRubric from './SizeRubric';
import SizeSquare from './SizeSquare';
import SizeStar from './SizeStar';
import SizeSubway from './SizeSubway';
import SizeToltec from './SizeToltec';
import SizeTrapezoid from './SizeTrapezoid';
import SizeZocalo from './SizeZocalo';
import Thickness from './Thickness';
import Trade from './Trade';
import Notes from './Notes';
import Trash from './Trash';
import Twitter from './Twitter';
import Upload from './Upload';

export const Icons = {
    Account,
    AddToCart,
    AddToProject,
    ArrowDown,
    ArrowLeft,
    ArrowRight,
    ArrowUpRight,
    Burger,
    CaretDown,
    Cart,
    Chat,
    Check,
    ChevronLeft,
    ChevronRight,
    CheckFilled,
    Close,
    CloseCircle,
    EditPen,
    Facebook,
    Faq,
    Fireplace,
    Info,
    Instagram,
    InstagramColor,
    Installation,
    InstallationGuide,
    Link,
    Lock,
    Logo,
    LogoMobile,
    Logout,
    Mail,
    MailContained,
    Minus,
    Pinterest,
    Plus,
    Print,
    Quotes,
    Save,
    Search,
    ShipHands,
    Shipping,
    Shipment,
    SizeAero,
    SizeAlcazar,
    SizeDiamond,
    SizeGambit,
    SizeHex,
    SizeLattice,
    SizeNouveau,
    SizePerpetualCheck,
    SizePrismatic,
    SizeRadianOffsetInline,
    SizeRectangle,
    SizeRubric,
    SizeSquare,
    SizeStar,
    SizeSubway,
    SizeToltec,
    SizeTrapezoid,
    SizeZocalo,
    Thickness,
    Trade,
    Notes,
    Trash,
    Twitter,
    Upload,
};
const DynamicIcon = ({ icon }) => {
    // check if component is defined above
    if (typeof Icons[icon] !== 'undefined') {
        const Icon = Icons[icon];
        return <Icon />;
    }

    return null;
};

DynamicIcon.propTypes = {
    icon: PropTypes.string,
};

export default DynamicIcon;
