import PropTypes from 'prop-types';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <svg className="icon icon--eye" width="16" height="16" viewBox="0 0 16 11" {...attrs}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99927 1.0711C5.32721 1.02648 2.56366 2.87514 0.826258 4.76842C0.448539 5.18356 0.448539 5.81408 0.826258 6.22923C2.52588 8.08248 5.28147 9.97379 7.99927 9.92851C10.7171 9.97379 13.4733 8.08248 15.1743 6.22923C15.552 5.81408 15.552 5.18356 15.1743 4.76842C13.4349 2.87514 10.6713 1.02648 7.99927 1.0711Z"
                fill="none"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.4853 5.50017C10.4849 6.85922 9.37181 7.96069 7.99903 7.96045C6.62624 7.96021 5.51355 6.85835 5.51367 5.49929C5.51379 4.14024 6.62669 3.03857 7.99947 3.03857C8.65891 3.0384 9.29138 3.29771 9.75762 3.75941C10.2239 4.2211 10.4856 4.84732 10.4853 5.50017V5.50017Z"
                fill="none"
                stroke={fill}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
