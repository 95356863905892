import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from 'styles/breakpoints';

const InputWrapper = styled.div`
    width: 100%;
    position: relative;

    input::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: ${props => props.placeholderColor};
        opacity: 1; /* Firefox */
    }

    input:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: ${props => props.placeholderColor};
    }

    input::-ms-input-placeholder {
        /* Microsoft Edge */
        color: ${props => props.placeholderColor};
    }
    label {
        display: flex;
        flex-wrap: wrap;
        color: ${props => (props.error ? '#ff3636' : props.inverted ? '#fff' : '#7f7f7f')};
        line-height: normal;
        opacity: 1;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    input {
        width: 100%;
        background: transparent;
        color: ${props => (props.inverted ? '#fff' : '#000')};
        border-radius: 0;
        border-bottom: 1px solid
            ${props =>
                props.error ? '#ff3636' : props.inverted ? '#f2f2f2' : props.borderColor || '#ccc'};
        outline: none;
        letter-spacing: inherit;
        ${props =>
            props.inverted
                ? `
                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 36px #1d1d1d inset;
                    -webkit-text-fill-color: #fff;
                    -webkit-background-clip: text;
                }
            `
                : `
                &:-webkit-autofill,
                &:-webkit-autofill:hover, 
                &:-webkit-autofill:focus, 
                &:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 36px #fff inset;
                    -webkit-text-fill-color: #000;
                    -webkit-background-clip: text;
                }
            `};
    }
    .error {
        display: flex;
        color: #ff3636;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        margin-top: 6px;
        position: absolute;
    }
    .success {
        display: flex;
        color: #52cc61;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        margin-top: 6px;
    }
    .helper-icon {
        position: absolute;
        left: 0;
        top: calc(50% + 7px);
        transform: translateY(-50%);
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.maxWidth};
    }
`;

const InputMedium = styled(InputWrapper)`
    input {
        padding: ${props => (props.icon ? '20px 40px 20px 25px' : props.inputPadding || '10px 0')};
    }
`;
const InputLarge = styled(InputWrapper)`
    label {
        position: absolute;
    }
    input {
        font-size: 24px;
        padding: 20px 40px 20px ${props => (props.icon ? '25px' : '0px')};
    }
`;
const SearchInput = styled.div`
    width: 100%;
    position: relative;

    label {
        color: #000;
        line-height: normal;
        opacity: 1;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
        position: absolute;
    }
    input {
        width: 100%;
        background: transparent;
        color: #000;
        font-size: 24px;
        padding: 15px 40px 15px ${props => (props.icon ? '25px' : '0px')};
        outline: none;
        text-align: center;
        &::placeholder {
            color: #000;
        }
    }
    .helper-icon {
        position: absolute;
        right: 0;
        top: 27px;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.maxWidth};
    }
`;

const Input = ({ size, name, label, srOnlyLabel, icon, children, ...props }) => {
    if (size === 'large') {
        return (
            <InputLarge icon={!!icon} {...props}>
                <label htmlFor={name} className={`${srOnlyLabel ? 'sr-only' : ''}`}>
                    {label}
                </label>
                <span className="helper-icon">{icon}</span>
                {children}
            </InputLarge>
        );
    }
    if (size === 'medium') {
        return (
            <InputMedium icon={!!icon} {...props}>
                <label htmlFor={name} className={`${srOnlyLabel ? 'sr-only' : ''}`}>
                    {label}
                </label>
                <span className="helper-icon">{icon}</span>
                {children}
            </InputMedium>
        );
    }
    if (size === 'search') {
        return (
            <SearchInput icon={!!icon} {...props}>
                {label ? (
                    <label htmlFor={name} className={`${srOnlyLabel ? 'sr-only' : ''}`}>
                        {label}
                    </label>
                ) : null}
                <span className="helper-icon">{icon}</span>
                {children}
            </SearchInput>
        );
    }
};

export default Input;

Input.propTypes = {
    /**
     * How large should the Input be?
     */
    size: PropTypes.oneOf(['medium', 'large', 'search']),
    /**
     * Input contents
     */
    name: PropTypes.string,
    /**
     * Input contents
     */
    label: PropTypes.string,
    /**
     * Input contents
     */
    placeholder: PropTypes.string,
    srOnlyLabel: PropTypes.bool,
};

Input.defaultProps = {
    size: 'medium',
    name: 'test',
    label: 'Label',
    placeholder: 'Placeholder',
};
