import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--addToProject"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g>
                    <g transform="translate(-913 -139) translate(770 119)">
                        <g transform="translate(40 17)">
                            <g>
                                <g stroke={fill}>
                                    <path
                                        fill="#fff"
                                        d="M6.966 10.937c0 2.197 1.78 3.977 3.977 3.977 2.196 0 3.977-1.78 3.977-3.977 0-2.196-1.78-3.977-3.977-3.977s-3.977 1.78-3.977 3.977zM10.943 8.949L10.943 12.926M8.954 10.937L12.932 10.937"
                                        transform="translate(103 3.353) translate(.54 .543)"
                                    />
                                    <path
                                        fill="#fff"
                                        d="M4.954 10.44L1.102 6.42C-.043 5.275-.327 3.526.397 2.078h0C.937.998 1.965.242 3.158.048c1.193-.193 2.407.2 3.261 1.054L7.46 2.139l1.038-1.037C9.353.247 10.566-.145 11.76.049c1.193.193 2.22.948 2.761 2.03h0c.534 1.068.528 2.326-.015 3.39"
                                        transform="translate(103 3.353) translate(.54 .543)"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
