import PropTypes from 'prop-types';

const ArrowRightIcon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--arrowRight"
            width="16"
            height="16"
            viewBox="0 0 16 10"
            {...attrs}
        >
            <path
                d="M11.7739 0.389099C11.5288 0.146112 11.1331 0.147878 10.8901 0.393042C10.6471 0.638207 10.6489 1.03393 10.894 1.27692L12.3632 2.73301C12.9594 3.32395 13.3684 3.73066 13.6452 4.07526C13.7351 4.18725 13.8053 4.28584 13.8598 4.37467L1.33398 4.37468C0.988806 4.37468 0.708984 4.6545 0.708984 4.99968C0.708984 5.34485 0.988806 5.62468 1.33398 5.62468L13.8598 5.62467C13.8053 5.71351 13.7351 5.8121 13.6452 5.92409C13.3684 6.26869 12.9594 6.67539 12.3632 7.26634L10.894 8.72243C10.6489 8.96542 10.6471 9.36114 10.8901 9.60631C11.1331 9.85147 11.5288 9.85324 11.7739 9.61025L13.2696 8.12792C13.8329 7.56964 14.2933 7.11335 14.6198 6.70679C14.9593 6.28402 15.2051 5.8549 15.2707 5.33981C15.2851 5.22688 15.2923 5.11328 15.2923 4.99967C15.2923 4.88607 15.2851 4.77247 15.2707 4.65954C15.2051 4.14444 14.9593 3.71533 14.6198 3.29256C14.2933 2.88599 13.8329 2.4297 13.2696 1.87141L11.7739 0.389099Z"
                fill={fill}
            />
        </svg>
    );
};

ArrowRightIcon.defaultProps = {
    fill: 'currentColor',
};

ArrowRightIcon.propTypes = {
    fill: PropTypes.string,
};

export default ArrowRightIcon;
