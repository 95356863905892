import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--upload" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1110 -786) translate(493 779) translate(610)">
                    <g fill={fill} fillRule="nonzero">
                        <path
                            d="M4 2c.276 0 .5.232.5.519 0 .286-.224.518-.5.518H2.5c-.827 0-1.5.698-1.5 1.556v8.814c0 .858.673 1.556 1.5 1.556h8c.827 0 1.5-.698 1.5-1.556V4.593c0-.858-.673-1.556-1.5-1.556H9.001c-.276 0-.5-.232-.5-.518 0-.287.224-.519.5-.519H10.5C11.879 2 13 3.163 13 4.593v8.814C13 14.837 11.879 16 10.5 16h-8C1.121 16 0 14.837 0 13.407V4.593C0 3.163 1.121 2 2.5 2H4zm2.5-2c.276 0 .5.224.5.5v8.793l2.147-2.146C9.24 7.052 9.367 7 9.5 7c.133 0 .26.052.353.147.095.094.147.22.147.353 0 .133-.052.26-.147.353l-3 3c-.023.024-.048.044-.076.063-.008.006-.024.015-.034.02-.007.005-.028.016-.05.025-.01.005-.031.012-.042.014-.013.005-.032.011-.052.015-.032.007-.065.01-.098.01-.034 0-.067-.003-.099-.01-.02-.004-.04-.01-.059-.017l-.033-.011c-.025-.01-.046-.022-.066-.034-.011-.005-.015-.008-.026-.015-.023-.016-.049-.036-.071-.06l-3-3C3.052 7.76 3 7.633 3 7.5c0-.133.052-.26.147-.353C3.24 7.052 3.367 7 3.5 7c.133 0 .26.052.353.147L6 9.293V.5c0-.276.224-.5.5-.5z"
                            transform="translate(7 7) matrix(1 0 0 -1 1.5 16)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
