import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--alcazar"
                width="120"
                viewBox="0 0 324.55 294.09"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="9.81 169.47 9.81 231.12 128.61 293.53 252.67 234.88 252.67 172.48 193.27 170.22 133.87 51.42 69.96 169.47 9.81 169.47" />
                <line x1="258.68" y1="9.73" x2=".84" y2="10.78" />
                <line x1="258.62" y1="1.08" x2="258.59" y2="20.85" />
                <line x1=".44" y1="0" x2=".41" y2="19.77" />
                <line x1="314.36" y1="61.63" x2="313.87" y2="290.54" />
                <line x1="304.78" y1="61.18" x2="324.55" y2="61.23" />
                <line x1="303.92" y1="290.09" x2="323.68" y2="290.14" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
