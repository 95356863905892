import CurrencyFormat from 'react-currency-format';

export const sampleLimitNotification = 'A sample of this item is already in your cart';

export const sampleNotAvailable = 'This item does not currently have a sample available';

export const overageInfo =
    'Industry standard suggests adding at least 15% overage due to tile cuts, potential breakage, or future repairs.';

export const overageOptions = {
    1.15: '15%',
    1.2: '20%',
};
export const numberOfBoxesInfo = ({ tilesPerBox, sqFtPerBox, linearFeetPerBox }) => {
    let info = tilesPerBox ? `${tilesPerBox} pieces per box.\n` : '';
    info += sqFtPerBox ? `${sqFtPerBox} sq. ft. per box.\n` : '';
    info += linearFeetPerBox ? `${linearFeetPerBox} linear ft. per box.\n` : '';
    info += 'All tiles sold by the full box.';
    return info;
};

export const priceInfo = ({ pricePerBox, info = ' per box.' }) => {
    return (
        <>
            $<CurrencyFormat value={pricePerBox} displayType={'text'} thousandSeparator={true} />
            {info}
        </>
    );
};

export const sampleShipInfo =
    'All samples ship via FedEx/UPS/USPS ground within 2 business days. Sample orders are unavailable for pick-up at our warehouse.';

export const initialArea = 1;

export const initialOverage = 1.15;

export const tagValueMapping = {
    sealer: '511-porous-plus',
};
export const getUsagesColumns = (productTemplate, tileUsages) => {
    const productSpecificData =
        tileUsages.find(tileUsage => tileUsage.productTemplate === productTemplate)
            ?.tileUsageAreas || [];

    const perChunk = 2;
    const chunks = productSpecificData.reduce((usagesArray, item, index) => {
        const chunkIndex = Math.floor(index / perChunk);

        if (!usagesArray[chunkIndex]) {
            usagesArray[chunkIndex] = []; // start a new chunk
        }

        usagesArray[chunkIndex].push(item);

        return usagesArray;
    }, []);

    return chunks;
};
