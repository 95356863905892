import React from 'react';
import PropTypes from 'prop-types';
import Link from 'components/atoms/Link';
import styled from 'styled-components';

import breakpoints from 'styles/breakpoints';

import { BodyText, H2 } from 'components/atoms/Typography';
import { BlogPageSection } from 'components/atoms/Container';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import { DesktopContainer, MobileContainer } from 'components/atoms/Container';
import LoadingPlaceholder from 'components/atoms/LoadingPlaceholder';
import { PlusIcon } from 'components/atoms/Icons';
import { storyblokEditable } from '@storyblok/react';

const ImageContainer = styled.div`
    width: 100%;
    min-height: 500px;
    position: relative;
    @media screen and ${breakpoints.lg} {
        max-height: 830px;
        // display: none;
    }
`;

const ButtonContainer = styled.div`
    position: absolute;
    right: 40px;
    bottom: 40px;
`;

const ButtonContainerMobile = styled.div`
    position: absolute;
    left: 30px;
    bottom: 30px;
`;

const CollectionText = styled.div`
    position: absolute;
    top: 25px;
    left: 30px;
`;

const FromTheBlogText = styled.div`
    position: absolute;
    left: 40px;
    bottom: 122px;
`;

const MainTitle = styled.div`
    position: absolute;
    left: 266px;
    bottom: 40px;
    max-width: 640px;
`;

const MainTitleMobile = styled.div`
    position: absolute;
    left: 30px;
    bottom: 100px;
    max-width: 640px;
`;

const BannerImage = ({ blok }) => {
    const { title, slug, images } = blok;
    return (
        <>
            <MobileContainer {...storyblokEditable(blok)}>
                <ImageContainer>
                    {images[0]?.filename && (
                        <Image
                            className="object-cover object-center h-full"
                            src={images[0].filename}
                            alt={images[0]?.alt || title}
                        />
                    )}
                    <LoadingPlaceholder />
                    <CollectionText>
                        <BodyText color="#ffffff">From the blog</BodyText>
                    </CollectionText>
                    <MainTitleMobile>
                        <H2 color="#ffffff">{title}</H2>
                    </MainTitleMobile>
                    <ButtonContainerMobile>
                        <Link href={`blog/${slug}`}>
                            <Button
                                as="span"
                                width="200px"
                                type="transparent"
                                size="medium"
                                label="Read more"
                                icon={<PlusIcon fill="currentColor" />}
                            />
                        </Link>
                    </ButtonContainerMobile>
                </ImageContainer>
            </MobileContainer>
            <DesktopContainer {...storyblokEditable(blok)}>
                <BlogPageSection marginTop="40px" padding="0 40px 0px" maxWidth="1400px">
                    <div className="grid gap-x-40px grid-cols-12">
                        <div className="col-span-12">
                            <ImageContainer>
                                <ButtonContainer>
                                    <Link href={`blog/${slug}`}>
                                        <Button
                                            as="span"
                                            width="200px"
                                            type="transparent"
                                            size="medium"
                                            label="Read more"
                                            icon={<PlusIcon fill="currentColor" />}
                                        />
                                    </Link>
                                </ButtonContainer>
                                <FromTheBlogText>
                                    <BodyText color="#ffffff">From the blog</BodyText>
                                </FromTheBlogText>
                                <MainTitle>
                                    <H2 color="#ffffff">{title}</H2>
                                </MainTitle>
                                {images[0]?.filename && (
                                    <Image src={images[0].filename} alt={images[0]?.alt || title} />
                                )}
                                <LoadingPlaceholder />
                            </ImageContainer>
                        </div>
                    </div>
                </BlogPageSection>
            </DesktopContainer>
        </>
    );
};

BannerImage.defaultProps = {
    blok: {},
};

BannerImage.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

export default BannerImage;
