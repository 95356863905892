import { storyblokEditable } from '@storyblok/react';
import Button from 'components/atoms/Button';
import { CaretDownIcon } from 'components/atoms/Icons';
import LoadingPlaceholder from 'components/atoms/LoadingPlaceholder';
import RevealElement from 'components/atoms/RevealElement';
import { BodyText, H2 } from 'components/atoms/Typography';
import { ProductCard } from 'components/molecules/Card';
import Slider from 'components/molecules/Slider';
import SiteContext from 'context/SiteContext';
import { APPS } from 'helpers/constants/general';
import {
    transformProductsToElevarImpressions,
    transformToElevarUserProperties,
} from 'helpers/elevar';
import { gShopifyCollection, transformProductCard } from 'helpers/graphql';
import { logInfo } from 'helpers/logging';
import { getShopifyCollectionByHandle } from 'helpers/requests/shopify-data-requests';
import PropTypes from 'prop-types';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';

const defaultProductsPerPage = 9;

const FeaturedCollectionV2 = ({ blok, pageTitle }) => {
    const first =
        blok?.layout === 'carousel'
            ? defaultProductsPerPage
            : Number(blok.productsPerPage) || defaultProductsPerPage;
    const { data: collectionData } = useQuery({
        queryKey: ['collection', blok.collection, first, blok.layout],
        queryFn: async () =>
            await getShopifyCollectionByHandle({
                handle: blok.collection,
                first,
            }),
        enabled: !!blok.collection,
    });

    const collection = useMemo(() => {
        if (!collectionData?.data?.collection) {
            return null;
        }
        // Clean collection
        return gShopifyCollection(collectionData.data);
    }, [collectionData]);

    const data = useMemo(() => {
        const {
            heading,
            collection,
            productsPerPage = defaultProductsPerPage,
            layout = 'load-more',
            loadMoreButtonText = 'Load more items',
        } = { ...blok };
        return {
            heading,
            collection,
            productsPerPage: Number(productsPerPage),
            layout,
            loadMoreButtonText,
        };
    }, [blok]);

    return (
        <div className="relative my-14 lg:my-24 overflow-hidden" {...storyblokEditable(blok)}>
            {data.layout === 'carousel' && (
                <OrganismCarousel
                    data={data}
                    pageName={pageTitle}
                    sectionName={data?.heading || collection.title}
                    products={collection?.products || []}
                />
            )}
            {data.layout === 'load-more' && (
                <OrganismLoadMore
                    data={data}
                    pageName={pageTitle}
                    sectionName={data?.heading || collection.title}
                    collectionHandle={data.collection}
                    products={collection?.products || []}
                    pageInfo={collection?.productsPageInfo || {}}
                    productsPerPage={data?.productsPerPage || defaultProductsPerPage}
                    loadMoreButtonText={data.loadMoreButtonText}
                />
            )}
        </div>
    );
};

const OrganismLoadMore = ({
    data,
    pageName,
    sectionName,
    collectionHandle,
    products = [],
    pageInfo,
    productsPerPage,
    loadMoreButtonText = 'Load more items',
}) => {
    const [productGridStore, setProductGridStore] = useState({
        products,
        pageInfo,
        state: 'idle',
    });

    useEffect(() => {
        setProductGridStore({
            products,
            pageInfo,
            state: 'idle',
        });
    }, [products]);

    const { data: collectionData, ...collectionMutation } = useMutation({
        mutationKey: ['collection', collectionHandle, pageInfo],
        mutationFn: getShopifyCollectionByHandle,
    });

    useEffect(() => {
        if (!collectionData?.data?.collection) {
            return;
        }
        const collection = gShopifyCollection(collectionData.data);

        if (!collection?.products?.length > 0) {
            return;
        }

        /** Collection page product impressions **/
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
        const viewItemListData = {
            event: 'dl_view_item_list',
            user_properties: {
                ...transformToElevarUserProperties({ customer, customerLoggedIn }),
            },
            ecommerce: {
                currencyCode: 'USD',
                impressions: transformProductsToElevarImpressions({
                    products: (collection?.products ?? []).slice(0, 10),
                    list: `/collections/${collectionHandle}`,
                    startIndex: productGridStore.products.length,
                    tradeDiscountValue,
                }),
            },
        };
        window.ElevarDataLayer.push(viewItemListData);
        logInfo('Fire Elevar dl_view_item_list', APPS.FRONTEND, viewItemListData);

        setProductGridStore(draft => ({
            ...draft,
            products: [...productGridStore.products, ...(collection?.products || [])],
            pageInfo: collection.productsPageInfo,
        }));
    }, [collectionData]);

    const {
        store: { settings, customer, customerLoggedIn, tradeDiscountValue },
    } = useContext(SiteContext);

    const inventoryQuantityThreshold = useMemo(() => {
        return Number(settings?.product?.inventoryQuantityThreshold) || 0;
    }, [settings]);

    const handleLoadMore = async () => {
        collectionMutation.mutate({
            handle: collectionHandle,
            after: productGridStore.pageInfo.endCursor,
            first: productsPerPage,
        });
    };

    const hasNextPage =
        productGridStore?.pageInfo?.hasNextPage !== undefined
            ? productGridStore.pageInfo.hasNextPage
            : pageInfo?.hasNextPage;

    return (
        <div className="container">
            <div className="grid grid-cols-1 gap-6">
                {data?.heading && (
                    <RevealElement>
                        <H2>{data.heading}</H2>
                    </RevealElement>
                )}
                <div className="grid grid-cols-1 gap-8 xl:gap-12">
                    <div className="grid grid-cols-1 auto-rows-fr justify-around md:grid-cols-2 xl:grid-cols-3 gap-10 xl:gap-20">
                        {productGridStore.products?.map((product, i) => {
                            const props = {
                                ...transformProductCard(product, inventoryQuantityThreshold),
                                position: i + 1,
                                list: `${pageName} - ${sectionName}`,
                            };
                            return (
                                <RevealElement
                                    key={product.id}
                                    delay={0.3 * (i % 3)}
                                    block={false}
                                    className={`${
                                        0.3 * (i % 3)
                                    } flex flex-col px-xxs md:px-0 items-center ${getColumnClass(
                                        i + 1
                                    )}`}
                                >
                                    <ProductCard
                                        className="w-full max-w-395px"
                                        {...props}
                                        pageName={pageName}
                                        sectionName={sectionName}
                                    />
                                </RevealElement>
                            );
                        })}
                        {collectionMutation?.isLoading && <Loader />}
                    </div>
                    {hasNextPage && (
                        <RevealElement>
                            <div className="flex justify-center">
                                <Button
                                    label={loadMoreButtonText}
                                    icon={<CaretDownIcon />}
                                    onClick={handleLoadMore}
                                    disabled={collectionMutation?.isLoading}
                                />
                            </div>
                        </RevealElement>
                    )}
                </div>
            </div>
        </div>
    );
};
const getColumnClass = i => {
    return i % 3 === 2 ? 'xl:items-center' : i % 3 === 0 ? 'xl:items-end' : 'xl:items-start';
};

const Loader = () => (
    <>
        {Array(3)
            .fill()
            .map((_item, i) => (
                <div
                    key={i}
                    className={`flex flex-col px-xxs md:px-0 items-center ${getColumnClass(i + 1)}`}
                >
                    <div className="w-full max-w-395px">
                        <div className="relative h-56 mb-4">
                            <LoadingPlaceholder />
                        </div>
                        <div className="relative h-7 mb-3">
                            <LoadingPlaceholder />
                        </div>
                        <div className="relative h-6 mb-4">
                            <LoadingPlaceholder />
                        </div>
                    </div>
                </div>
            ))}
    </>
);

const SliderContainer = styled.div`
    &.slideNav-hidden {
        .slick-arrow {
            display: none;
        }
    }
    .slick-track {
        margin-left: 0;
    }
    .slick-arrow {
        position: absolute;
        top: -66px;
    }
    .slick-prev {
        right: 59px;
        left: auto;
    }
    .slick-next {
        right: 20px;
        left: auto;
    }
    @media screen and ${breakpoints.maxMd} {
        .slick-arrow {
            display: none;
        }
    }
    @media screen and ${breakpoints.maxXs} {
        .slick-list {
            overflow: visible;
        }
    }
`;
const OrganismCarousel = ({
    data,
    products,
    pageName = 'Page',
    sectionName = 'Products carousel',
}) => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(1);
    const [maxSlideIndex, setMaxSlideIndex] = useState(1);

    const $ref = useRef(null);

    const {
        store: { settings },
    } = useContext(SiteContext);

    const inventoryQuantityThreshold = useMemo(() => {
        return Number(settings?.product?.inventoryQuantityThreshold) || 0;
    }, [settings]);

    const handleCustomPaging = () => {
        if (!$ref.current) {
            return;
        }
        const dots = [...$ref.current.querySelectorAll(`.customDots > li`)];
        const dotCount = dots.length;
        const dotIndex =
            dots.findIndex(dot => dot.className.includes('slick-active')) === -1
                ? dotCount
                : dots.findIndex(dot => dot.className.includes('slick-active')) + 1;
        const slideIndex = dotIndex;
        setCurrentSlideIndex(slideIndex);
        setMaxSlideIndex(dotCount);
    };

    const sliderSettings = {
        dots: true,
        dotsClass: 'customDots hidden',
        appendDots: dots => {
            return <ul>{dots}</ul>;
        },
        onReInit: handleCustomPaging,
        draggable: false,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    draggable: true,
                },
            },
            {
                breakpoint: 925,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                },
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1.225,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div className="container">
            <div className="grid grid-cols-1 gap-6 lg:grid-cols-4 items-center">
                <div className="lg:col-span-3">
                    {data?.heading && (
                        <RevealElement>
                            <H2>{data.heading}</H2>
                        </RevealElement>
                    )}
                </div>
                {maxSlideIndex > 1 && (
                    <RevealElement
                        delay={0.2}
                        block={false}
                        className="order-last lg:order-none flex justify-end items-end gap-2 lg:pr-28"
                    >
                        <BodyText>
                            {currentSlideIndex < 10 ? `0${currentSlideIndex}` : currentSlideIndex}
                        </BodyText>
                        <BodyText color="#7f7f7f">
                            {maxSlideIndex < 10 ? `/ 0${maxSlideIndex}` : `/ ${maxSlideIndex}`}
                        </BodyText>
                    </RevealElement>
                )}
                {products?.length > 0 && (
                    <SliderContainer
                        ref={$ref}
                        className={`col-span-full -mx-m-md px-xxs sm:px-0 sm:-mx-xs ${
                            maxSlideIndex < 2 && 'slideNav-hidden'
                        }`}
                    >
                        <RevealElement delay={0.3}>
                            <Slider settings={sliderSettings}>
                                {products?.map((product, i) => {
                                    const props = {
                                        ...transformProductCard(
                                            product,
                                            inventoryQuantityThreshold
                                        ),
                                        position: i + 1,
                                        list: `${pageName} - ${sectionName}`,
                                    };
                                    return (
                                        <RevealElement
                                            key={i}
                                            delay={0.3 + 0.1 * i}
                                            className="col-span-3 px-xs"
                                        >
                                            <ProductCard
                                                {...props}
                                                pageName={pageName}
                                                sectionName={sectionName}
                                            />
                                        </RevealElement>
                                    );
                                })}
                            </Slider>
                        </RevealElement>
                    </SliderContainer>
                )}
            </div>
        </div>
    );
};

FeaturedCollectionV2.propTypes = {
    blok: PropTypes.object,
    pageTitle: PropTypes.string,
};

OrganismLoadMore.propTypes = {
    data: PropTypes.object,
    pageName: PropTypes.string,
    sectionName: PropTypes.string,
    collectionHandle: PropTypes.string,
    products: PropTypes.array,
    pageInfo: PropTypes.object,
    productsPerPage: PropTypes.number,
    loadMoreButtonText: PropTypes.string,
};

OrganismCarousel.propTypes = {
    data: PropTypes.object,
    products: PropTypes.array,
    pageName: PropTypes.string,
    sectionName: PropTypes.sectionName,
};

export default FeaturedCollectionV2;
