import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--lattice"
                width="350"
                viewBox="0 0 633 300"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <path d="M562.5 105.5H473.5V194.5H562.5V105.5Z" stroke={fill} />
                <path
                    d="M133.409 204.338L88.4545 249.293L43.5 204.338V95.6617L88.4545 50.7071L133.409 95.6617V204.338ZM342.5 95.6616V204.338L297.545 249.293L252.591 204.338V95.6616L297.545 50.7071L342.5 95.6616ZM247.338 0.5L292.293 45.4545L247.338 90.4091H138.662L93.7071 45.4545L138.662 0.5H247.338ZM247.338 299.5H138.662L93.7071 254.545L138.662 209.591H247.338L292.293 254.545L247.338 299.5Z"
                    stroke={fill}
                />
                <path
                    d="M558 93.5L563 95.8868V90.1132L558 92.5V93.5ZM478 92.5L473 90.1132V95.8868L478 93.5V92.5ZM558.5 92.5H477.5V93.5H558.5V92.5Z"
                    fill={fill}
                    fillOpacity="60%"
                    strokeOpacity="60%"
                />
                <path
                    d="M574.5 190L572.113 195H577.887L575.5 190H574.5ZM575.5 110L577.887 105H572.113L574.5 110H575.5ZM575.5 190.5V109.5H574.5V190.5H575.5Z"
                    fill={fill}
                    fillOpacity="60%"
                    strokeOpacity="60%"
                />
                <path
                    d="M354.5 245L352.113 250H357.887L355.5 245H354.5ZM355.5 55L357.887 50H352.113L354.5 55H355.5ZM355.5 245.5V54.5H354.5V245.5H355.5Z"
                    fill={fill}
                    fillOpacity="60%"
                    strokeOpacity="60%"
                />
                <path
                    d="M31.5001 295L33.8869 300H28.1133L30.5001 295H31.5001ZM30.5001 5L28.1133 0H33.8869L31.5001 5H30.5001ZM30.5001 295.5V4.5H31.5001V295.5H30.5001Z"
                    fill={fill}
                    fillOpacity="60%"
                    strokeOpacity="60%"
                />
                <path
                    d="M187.5 294L185.113 299H190.887L188.5 294H187.5ZM188.5 215L190.887 210H185.113L187.5 215H188.5ZM188.5 294.5V214.5H187.5V294.5H188.5Z"
                    fill={fill}
                    fillOpacity="60%"
                    strokeOpacity="60%"
                />
                <path
                    d="M590.319 143.272C590.415 143.224 590.519 143.2 590.631 143.2H591.927C592.087 143.2 592.167 143.28 592.167 143.44V159.76C592.167 159.92 592.087 160 591.927 160H590.703C590.543 160 590.463 159.92 590.463 159.76V145.144C590.463 145.112 590.447 145.088 590.415 145.072C590.399 145.056 590.375 145.056 590.343 145.072L587.799 146.104L587.703 146.128C587.607 146.128 587.543 146.064 587.511 145.936L587.391 145.024V144.976C587.391 144.896 587.447 144.816 587.559 144.736L590.319 143.272Z"
                    fill={fill}
                />
                <path
                    d="M597.199 159.952C596.799 159.952 596.471 159.824 596.215 159.568C595.975 159.312 595.855 158.992 595.855 158.608C595.855 158.208 595.975 157.888 596.215 157.648C596.471 157.392 596.799 157.264 597.199 157.264C597.583 157.264 597.903 157.392 598.159 157.648C598.415 157.888 598.543 158.208 598.543 158.608C598.543 158.992 598.415 159.312 598.159 159.568C597.903 159.824 597.583 159.952 597.199 159.952Z"
                    fill={fill}
                />
                <path
                    d="M603.717 158.392C603.669 158.472 603.686 158.512 603.766 158.512H611.302C611.462 158.512 611.542 158.592 611.542 158.752V159.76C611.542 159.92 611.462 160 611.302 160H601.725C601.565 160 601.485 159.92 601.485 159.76V158.752C601.485 158.64 601.525 158.544 601.605 158.464C602.149 157.824 603.373 156.32 605.277 153.952L607.053 151.744C608.557 149.84 609.31 148.36 609.31 147.304C609.31 146.456 609.021 145.776 608.445 145.264C607.869 144.752 607.118 144.496 606.19 144.496C605.278 144.496 604.542 144.76 603.982 145.288C603.422 145.8 603.149 146.48 603.165 147.328V148.072C603.165 148.232 603.085 148.312 602.925 148.312H601.677C601.517 148.312 601.438 148.232 601.438 148.072V147.112C601.47 145.88 601.925 144.888 602.805 144.136C603.701 143.384 604.83 143.008 606.19 143.008C607.15 143.008 607.997 143.192 608.733 143.56C609.469 143.928 610.037 144.44 610.438 145.096C610.838 145.736 611.037 146.464 611.037 147.28C611.037 148.64 610.285 150.28 608.781 152.2C607.997 153.208 606.485 155.064 604.245 157.768L603.717 158.392Z"
                    fill={fill}
                />
                <path
                    d="M623.356 152.656C623.5 153.36 623.572 154.08 623.572 154.816C623.572 155.6 623.5 156.272 623.356 156.832C623.084 157.856 622.54 158.672 621.724 159.28C620.908 159.872 619.923 160.168 618.771 160.168C617.635 160.168 616.667 159.88 615.867 159.304C615.067 158.728 614.524 157.96 614.236 157C614.124 156.648 614.052 156.296 614.02 155.944V155.896C614.02 155.768 614.092 155.704 614.236 155.704H615.508C615.668 155.704 615.755 155.784 615.771 155.944C615.771 156.04 615.803 156.2 615.867 156.424C616.027 157.112 616.356 157.664 616.852 158.08C617.364 158.48 617.995 158.68 618.747 158.68C619.515 158.68 620.156 158.464 620.668 158.032C621.196 157.6 621.54 157.008 621.7 156.256C621.796 155.888 621.844 155.408 621.844 154.816C621.844 154.272 621.804 153.752 621.724 153.256C621.612 152.52 621.291 151.96 620.763 151.576C620.235 151.192 619.58 151 618.796 151C618.092 151 617.46 151.16 616.9 151.48C616.34 151.8 615.98 152.224 615.82 152.752C615.772 152.896 615.684 152.968 615.556 152.968H614.26C614.1 152.968 614.02 152.888 614.02 152.728V143.44C614.02 143.28 614.1 143.2 614.26 143.2H622.659C622.819 143.2 622.9 143.28 622.9 143.44V144.448C622.9 144.608 622.819 144.688 622.659 144.688H615.82C615.756 144.688 615.724 144.72 615.724 144.784L615.7 150.592C615.7 150.624 615.708 150.648 615.724 150.664C615.756 150.68 615.788 150.672 615.82 150.64C616.22 150.272 616.692 149.992 617.236 149.8C617.78 149.592 618.356 149.488 618.964 149.488C620.1 149.488 621.06 149.768 621.844 150.328C622.628 150.872 623.132 151.648 623.356 152.656Z"
                    fill={fill}
                />
                <path
                    d="M627.335 143.2C627.415 143.2 627.471 143.232 627.503 143.296C627.535 143.344 627.535 143.408 627.503 143.488L625.727 147.928C625.679 148.056 625.591 148.12 625.463 148.12H624.551C624.375 148.12 624.311 148.032 624.359 147.856L625.775 143.392C625.823 143.264 625.919 143.2 626.063 143.2H627.335Z"
                    fill={fill}
                />
                <path
                    d="M630.187 143.2C630.267 143.2 630.323 143.232 630.355 143.296C630.387 143.344 630.387 143.408 630.355 143.488L628.579 147.928C628.531 148.056 628.443 148.12 628.315 148.12H627.403C627.227 148.12 627.163 148.032 627.211 147.856L628.627 143.392C628.675 143.264 628.771 143.2 628.915 143.2H630.187Z"
                    fill={fill}
                />
                <path
                    d="M499.928 64.264C500.024 64.216 500.128 64.192 500.24 64.192H501.536C501.696 64.192 501.776 64.272 501.776 64.432V80.752C501.776 80.912 501.696 80.992 501.536 80.992H500.312C500.152 80.992 500.072 80.912 500.072 80.752V66.136C500.072 66.104 500.056 66.08 500.024 66.064C500.008 66.048 499.984 66.048 499.952 66.064L497.408 67.096L497.312 67.12C497.216 67.12 497.152 67.056 497.12 66.928L497 66.016V65.968C497 65.888 497.056 65.808 497.168 65.728L499.928 64.264Z"
                    fill={fill}
                />
                <path
                    d="M506.813 80.944C506.413 80.944 506.085 80.816 505.829 80.56C505.589 80.304 505.469 79.984 505.469 79.6C505.469 79.2 505.589 78.88 505.829 78.64C506.085 78.384 506.413 78.256 506.813 78.256C507.197 78.256 507.517 78.384 507.773 78.64C508.029 78.88 508.157 79.2 508.157 79.6C508.157 79.984 508.029 80.304 507.773 80.56C507.517 80.816 507.197 80.944 506.813 80.944Z"
                    fill={fill}
                />
                <path
                    d="M513.327 79.384C513.279 79.464 513.295 79.504 513.375 79.504H520.911C521.071 79.504 521.151 79.584 521.151 79.744V80.752C521.151 80.912 521.071 80.992 520.911 80.992H511.335C511.175 80.992 511.095 80.912 511.095 80.752V79.744C511.095 79.632 511.135 79.536 511.215 79.456C511.759 78.816 512.983 77.312 514.887 74.944L516.663 72.736C518.167 70.832 518.919 69.352 518.919 68.296C518.919 67.448 518.631 66.768 518.055 66.256C517.479 65.744 516.727 65.488 515.799 65.488C514.887 65.488 514.151 65.752 513.591 66.28C513.031 66.792 512.759 67.472 512.775 68.32V69.064C512.775 69.224 512.695 69.304 512.535 69.304H511.287C511.127 69.304 511.047 69.224 511.047 69.064V68.104C511.079 66.872 511.535 65.88 512.415 65.128C513.311 64.376 514.439 64 515.799 64C516.759 64 517.607 64.184 518.343 64.552C519.079 64.92 519.647 65.432 520.047 66.088C520.447 66.728 520.647 67.456 520.647 68.272C520.647 69.632 519.895 71.272 518.391 73.192C517.607 74.2 516.095 76.056 513.855 78.76L513.327 79.384Z"
                    fill={fill}
                />
                <path
                    d="M532.965 73.648C533.109 74.352 533.181 75.072 533.181 75.808C533.181 76.592 533.109 77.264 532.965 77.824C532.694 78.848 532.149 79.664 531.333 80.272C530.517 80.864 529.534 81.16 528.382 81.16C527.246 81.16 526.278 80.872 525.478 80.296C524.678 79.72 524.134 78.952 523.846 77.992C523.734 77.64 523.661 77.288 523.629 76.936V76.888C523.629 76.76 523.702 76.696 523.846 76.696H525.118C525.278 76.696 525.366 76.776 525.382 76.936C525.382 77.032 525.414 77.192 525.478 77.416C525.638 78.104 525.966 78.656 526.462 79.072C526.974 79.472 527.605 79.672 528.358 79.672C529.126 79.672 529.766 79.456 530.278 79.024C530.806 78.592 531.15 78 531.31 77.248C531.406 76.88 531.453 76.4 531.453 75.808C531.453 75.264 531.413 74.744 531.333 74.248C531.221 73.512 530.902 72.952 530.374 72.568C529.846 72.184 529.19 71.992 528.406 71.992C527.702 71.992 527.07 72.152 526.51 72.472C525.95 72.792 525.59 73.216 525.43 73.744C525.382 73.888 525.294 73.96 525.166 73.96H523.869C523.709 73.96 523.629 73.88 523.629 73.72V64.432C523.629 64.272 523.709 64.192 523.869 64.192H532.27C532.43 64.192 532.509 64.272 532.509 64.432V65.44C532.509 65.6 532.43 65.68 532.27 65.68H525.43C525.366 65.68 525.333 65.712 525.333 65.776L525.309 71.584C525.309 71.616 525.317 71.64 525.333 71.656C525.365 71.672 525.398 71.664 525.43 71.632C525.83 71.264 526.302 70.984 526.846 70.792C527.39 70.584 527.965 70.48 528.573 70.48C529.709 70.48 530.669 70.76 531.453 71.32C532.237 71.864 532.741 72.64 532.965 73.648Z"
                    fill={fill}
                />
                <path
                    d="M536.945 64.192C537.025 64.192 537.081 64.224 537.113 64.288C537.145 64.336 537.145 64.4 537.113 64.48L535.337 68.92C535.289 69.048 535.201 69.112 535.073 69.112H534.161C533.985 69.112 533.921 69.024 533.969 68.848L535.385 64.384C535.433 64.256 535.529 64.192 535.673 64.192H536.945Z"
                    fill={fill}
                />
                <path
                    d="M539.796 64.192C539.876 64.192 539.932 64.224 539.964 64.288C539.996 64.336 539.996 64.4 539.964 64.48L538.188 68.92C538.14 69.048 538.052 69.112 537.924 69.112H537.012C536.836 69.112 536.772 69.024 536.82 68.848L538.236 64.384C538.284 64.256 538.38 64.192 538.524 64.192H539.796Z"
                    fill={fill}
                />
                <path
                    d="M370.647 158.392C370.599 158.472 370.615 158.512 370.695 158.512H378.231C378.391 158.512 378.471 158.592 378.471 158.752V159.76C378.471 159.92 378.391 160 378.231 160H368.655C368.495 160 368.415 159.92 368.415 159.76V158.752C368.415 158.64 368.455 158.544 368.535 158.464C369.079 157.824 370.303 156.32 372.207 153.952L373.983 151.744C375.487 149.84 376.239 148.36 376.239 147.304C376.239 146.456 375.951 145.776 375.375 145.264C374.799 144.752 374.047 144.496 373.119 144.496C372.207 144.496 371.471 144.76 370.911 145.288C370.351 145.8 370.079 146.48 370.095 147.328V148.072C370.095 148.232 370.015 148.312 369.855 148.312H368.607C368.447 148.312 368.367 148.232 368.367 148.072V147.112C368.399 145.88 368.855 144.888 369.735 144.136C370.631 143.384 371.759 143.008 373.119 143.008C374.079 143.008 374.927 143.192 375.663 143.56C376.399 143.928 376.967 144.44 377.367 145.096C377.767 145.736 377.967 146.464 377.967 147.28C377.967 148.64 377.215 150.28 375.711 152.2C374.927 153.208 373.415 155.064 371.175 157.768L370.647 158.392Z"
                    fill={fill}
                />
                <path
                    d="M382.68 159.952C382.28 159.952 381.952 159.824 381.696 159.568C381.456 159.312 381.336 158.992 381.336 158.608C381.336 158.208 381.456 157.888 381.696 157.648C381.952 157.392 382.28 157.264 382.68 157.264C383.064 157.264 383.384 157.392 383.64 157.648C383.896 157.888 384.024 158.208 384.024 158.608C384.024 158.992 383.896 159.312 383.64 159.568C383.384 159.824 383.064 159.952 382.68 159.952Z"
                    fill={fill}
                />
                <path
                    d="M389.17 160C389.09 160 389.026 159.976 388.978 159.928C388.946 159.864 388.946 159.792 388.978 159.712L394.522 144.808C394.538 144.776 394.538 144.752 394.522 144.736C394.506 144.704 394.482 144.688 394.45 144.688H387.97C387.906 144.688 387.874 144.72 387.874 144.784V145.888C387.874 146.048 387.794 146.128 387.634 146.128H386.65C386.49 146.128 386.41 146.048 386.41 145.888L386.434 143.44C386.434 143.28 386.514 143.2 386.674 143.2H396.13C396.29 143.2 396.37 143.28 396.37 143.44V144.544C396.37 144.608 396.354 144.704 396.322 144.832L390.802 159.808C390.754 159.936 390.658 160 390.514 160H389.17Z"
                    fill={fill}
                />
                <path
                    d="M407.309 152.656C407.453 153.36 407.525 154.08 407.525 154.816C407.525 155.6 407.453 156.272 407.309 156.832C407.037 157.856 406.493 158.672 405.677 159.28C404.861 159.872 403.877 160.168 402.725 160.168C401.589 160.168 400.621 159.88 399.821 159.304C399.021 158.728 398.477 157.96 398.189 157C398.077 156.648 398.005 156.296 397.973 155.944V155.896C397.973 155.768 398.045 155.704 398.189 155.704H399.461C399.621 155.704 399.709 155.784 399.725 155.944C399.725 156.04 399.757 156.2 399.821 156.424C399.981 157.112 400.309 157.664 400.805 158.08C401.317 158.48 401.949 158.68 402.701 158.68C403.469 158.68 404.109 158.464 404.621 158.032C405.149 157.6 405.493 157.008 405.653 156.256C405.749 155.888 405.797 155.408 405.797 154.816C405.797 154.272 405.757 153.752 405.677 153.256C405.565 152.52 405.245 151.96 404.717 151.576C404.189 151.192 403.533 151 402.749 151C402.045 151 401.413 151.16 400.853 151.48C400.293 151.8 399.933 152.224 399.773 152.752C399.725 152.896 399.637 152.968 399.509 152.968H398.213C398.053 152.968 397.973 152.888 397.973 152.728V143.44C397.973 143.28 398.053 143.2 398.213 143.2H406.613C406.773 143.2 406.853 143.28 406.853 143.44V144.448C406.853 144.608 406.773 144.688 406.613 144.688H399.773C399.709 144.688 399.677 144.72 399.677 144.784L399.653 150.592C399.653 150.624 399.661 150.648 399.677 150.664C399.709 150.68 399.741 150.672 399.773 150.64C400.173 150.272 400.645 149.992 401.189 149.8C401.733 149.592 402.309 149.488 402.917 149.488C404.053 149.488 405.013 149.768 405.797 150.328C406.581 150.872 407.085 151.648 407.309 152.656Z"
                    fill={fill}
                />
                <path
                    d="M411.288 143.2C411.368 143.2 411.424 143.232 411.456 143.296C411.488 143.344 411.488 143.408 411.456 143.488L409.68 147.928C409.632 148.056 409.544 148.12 409.416 148.12H408.504C408.328 148.12 408.264 148.032 408.312 147.856L409.728 143.392C409.776 143.264 409.872 143.2 410.016 143.2H411.288Z"
                    fill={fill}
                />
                <path
                    d="M414.143 143.2C414.223 143.2 414.28 143.232 414.312 143.296C414.344 143.344 414.344 143.408 414.312 143.488L412.536 147.928C412.488 148.056 412.4 148.12 412.272 148.12H411.359C411.183 148.12 411.12 148.032 411.168 147.856L412.584 143.392C412.632 143.264 412.727 143.2 412.871 143.2H414.143Z"
                    fill={fill}
                />
                <path
                    d="M204.319 248.272C204.415 248.224 204.519 248.2 204.631 248.2H205.927C206.087 248.2 206.167 248.28 206.167 248.44V264.76C206.167 264.92 206.087 265 205.927 265H204.703C204.543 265 204.463 264.92 204.463 264.76V250.144C204.463 250.112 204.447 250.088 204.415 250.072C204.399 250.056 204.375 250.056 204.343 250.072L201.799 251.104L201.703 251.128C201.607 251.128 201.543 251.064 201.511 250.936L201.391 250.024V249.976C201.391 249.896 201.447 249.816 201.559 249.736L204.319 248.272Z"
                    fill={fill}
                />
                <path
                    d="M211.199 264.952C210.799 264.952 210.471 264.824 210.215 264.568C209.975 264.312 209.855 263.992 209.855 263.608C209.855 263.208 209.975 262.888 210.215 262.648C210.471 262.392 210.799 262.264 211.199 262.264C211.583 262.264 211.903 262.392 212.159 262.648C212.415 262.888 212.543 263.208 212.543 263.608C212.543 263.992 212.415 264.312 212.159 264.568C211.903 264.824 211.583 264.952 211.199 264.952Z"
                    fill={fill}
                />
                <path
                    d="M217.717 263.392C217.669 263.472 217.686 263.512 217.766 263.512H225.301C225.461 263.512 225.542 263.592 225.542 263.752V264.76C225.542 264.92 225.461 265 225.301 265H215.726C215.566 265 215.486 264.92 215.486 264.76V263.752C215.486 263.64 215.525 263.544 215.605 263.464C216.149 262.824 217.373 261.32 219.277 258.952L221.053 256.744C222.557 254.84 223.31 253.36 223.31 252.304C223.31 251.456 223.022 250.776 222.446 250.264C221.87 249.752 221.117 249.496 220.189 249.496C219.277 249.496 218.542 249.76 217.982 250.288C217.422 250.8 217.149 251.48 217.165 252.328V253.072C217.165 253.232 217.086 253.312 216.926 253.312H215.678C215.518 253.312 215.438 253.232 215.438 253.072V252.112C215.47 250.88 215.925 249.888 216.805 249.136C217.701 248.384 218.829 248.008 220.189 248.008C221.149 248.008 221.998 248.192 222.734 248.56C223.47 248.928 224.037 249.44 224.438 250.096C224.838 250.736 225.038 251.464 225.038 252.28C225.038 253.64 224.286 255.28 222.782 257.2C221.998 258.208 220.485 260.064 218.245 262.768L217.717 263.392Z"
                    fill={fill}
                />
                <path
                    d="M237.356 257.656C237.5 258.36 237.572 259.08 237.572 259.816C237.572 260.6 237.5 261.272 237.356 261.832C237.084 262.856 236.54 263.672 235.724 264.28C234.908 264.872 233.924 265.168 232.772 265.168C231.636 265.168 230.668 264.88 229.868 264.304C229.068 263.728 228.524 262.96 228.236 262C228.124 261.648 228.052 261.296 228.02 260.944V260.896C228.02 260.768 228.092 260.704 228.236 260.704H229.508C229.668 260.704 229.756 260.784 229.772 260.944C229.772 261.04 229.804 261.2 229.868 261.424C230.028 262.112 230.356 262.664 230.852 263.08C231.364 263.48 231.996 263.68 232.748 263.68C233.516 263.68 234.156 263.464 234.668 263.032C235.196 262.6 235.54 262.008 235.7 261.256C235.796 260.888 235.844 260.408 235.844 259.816C235.844 259.272 235.804 258.752 235.724 258.256C235.612 257.52 235.292 256.96 234.764 256.576C234.236 256.192 233.58 256 232.796 256C232.092 256 231.46 256.16 230.9 256.48C230.34 256.8 229.98 257.224 229.82 257.752C229.772 257.896 229.684 257.968 229.556 257.968H228.26C228.1 257.968 228.02 257.888 228.02 257.728V248.44C228.02 248.28 228.1 248.2 228.26 248.2H236.66C236.82 248.2 236.9 248.28 236.9 248.44V249.448C236.9 249.608 236.82 249.688 236.66 249.688H229.82C229.756 249.688 229.724 249.72 229.724 249.784L229.7 255.592C229.7 255.624 229.708 255.648 229.724 255.664C229.756 255.68 229.788 255.672 229.82 255.64C230.22 255.272 230.692 254.992 231.236 254.8C231.78 254.592 232.356 254.488 232.964 254.488C234.1 254.488 235.06 254.768 235.844 255.328C236.628 255.872 237.132 256.648 237.356 257.656Z"
                    fill={fill}
                />
                <path
                    d="M241.335 248.2C241.415 248.2 241.471 248.232 241.503 248.296C241.535 248.344 241.535 248.408 241.503 248.488L239.727 252.928C239.679 253.056 239.591 253.12 239.463 253.12H238.551C238.375 253.12 238.311 253.032 238.359 252.856L239.775 248.392C239.823 248.264 239.919 248.2 240.063 248.2H241.335Z"
                    fill={fill}
                />
                <path
                    d="M244.187 248.2C244.267 248.2 244.323 248.232 244.355 248.296C244.387 248.344 244.387 248.408 244.355 248.488L242.579 252.928C242.531 253.056 242.443 253.12 242.315 253.12H241.403C241.227 253.12 241.163 253.032 241.211 252.856L242.627 248.392C242.675 248.264 242.771 248.2 242.915 248.2H244.187Z"
                    fill={fill}
                />
                <path
                    d="M11.5198 153.832C11.6798 153.832 11.7598 153.912 11.7598 154.072V155.128C11.7598 155.288 11.6798 155.368 11.5198 155.368H10.4878C10.4238 155.368 10.3918 155.4 10.3918 155.464V159.76C10.3918 159.92 10.3118 160 10.1518 160H8.92778C8.76778 160 8.68778 159.92 8.68778 159.76V155.464C8.68778 155.4 8.65578 155.368 8.59178 155.368H1.41578C1.25578 155.368 1.17578 155.288 1.17578 155.128V154.24C1.17578 154.16 1.19978 154.064 1.24778 153.952L6.16778 143.368C6.21578 143.256 6.30378 143.2 6.43178 143.2H7.70378C7.78378 143.2 7.83978 143.232 7.87178 143.296C7.91978 143.344 7.92778 143.408 7.89578 143.488L3.11978 153.712C3.10378 153.744 3.10378 153.776 3.11978 153.808C3.13578 153.824 3.15978 153.832 3.19178 153.832H8.59178C8.65578 153.832 8.68778 153.8 8.68778 153.736V149.872C8.68778 149.712 8.76778 149.632 8.92778 149.632H10.1518C10.3118 149.632 10.3918 149.712 10.3918 149.872V153.736C10.3918 153.8 10.4238 153.832 10.4878 153.832H11.5198Z"
                    fill={fill}
                />
                <path
                    d="M15.7922 143.2C15.8722 143.2 15.9282 143.232 15.9602 143.296C15.9922 143.344 15.9922 143.408 15.9602 143.488L14.1842 147.928C14.1362 148.056 14.0482 148.12 13.9202 148.12H13.0082C12.8322 148.12 12.7682 148.032 12.8162 147.856L14.2322 143.392C14.2802 143.264 14.3762 143.2 14.5202 143.2H15.7922Z"
                    fill={fill}
                />
                <path
                    d="M18.6438 143.2C18.7238 143.2 18.7798 143.232 18.8118 143.296C18.8438 143.344 18.8438 143.408 18.8118 143.488L17.0358 147.928C16.9878 148.056 16.8998 148.12 16.7718 148.12H15.8598C15.6838 148.12 15.6198 148.032 15.6678 147.856L17.0838 143.392C17.1318 143.264 17.2278 143.2 17.3718 143.2H18.6438Z"
                    fill={fill}
                />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
