import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--editPen" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1323 -528) translate(1060 396.894) translate(0 109.106)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M10.678 0c.51 0 1.004.155 1.418.44.974-.66 2.309-.557 3.17.304.973.971.98 2.544.013 3.527l-2.473 2.47-.004.004-.004.003-6.361 6.359c-.247.246-.57.398-.915.433l-.149.007c-.175 0-.348-.03-.51-.09-.068.41-.263.803-.583 1.115-.315.315-.947.598-1.863.9-.573.19-1.17.357-1.688.487l-.105.026c-.368.09-.7-.242-.609-.61l.075-.288.195-.692c.083-.278.169-.552.258-.815.207-.61.41-1.103.613-1.446.093-.16.188-.29.286-.386.311-.32.702-.517 1.112-.588-.204-.533-.09-1.16.341-1.592l6.36-6.355.007-.006.007-.007 2.051-2.05c-.198-.094-.417-.145-.642-.145-.347 0-.682.121-.949.34l-.11.099-2.122 2.12c-.196.197-.515.197-.711 0-.175-.174-.194-.445-.059-.64l.059-.07L8.908.732C9.378.263 10.014 0 10.678 0zM3.523 12.335c-.413-.302-.995-.261-1.366.12-.017.016-.037.04-.06.072l-.074.116c-.16.27-.34.709-.527 1.26-.043.125-.084.252-.126.383l-.152.5.221-.062.332-.1.33-.106c.063-.02.125-.041.185-.062l.341-.123.3-.12.132-.057.227-.11c.134-.07.232-.134.287-.189.406-.397.42-1.046.029-1.459l.009.011zm6.094-8.063l-6.01 6.007c-.194.194-.194.509 0 .703l1.415 1.414c.093.093.22.146.351.146.132 0 .259-.053.352-.146l6.009-6.006-2.117-2.118zm4.825-2.92c-.584-.476-1.446-.446-1.993.092L10.328 3.56l2.118 2.117 2.118-2.115c.541-.55.571-1.41.094-1.995l-.103-.113z"
                        transform="translate(263 22)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
