import PropTypes from 'prop-types';

const SearchIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--search" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1272 -40) translate(39.878 40)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M1.489 2.388C2.905.664 5.109-.224 7.337.048c2.32.284 4.312 1.784 5.226 3.935 1.005 2.365.512 4.99-1.053 6.818l4.343 4.345c.196.195.196.512 0 .708-.174.174-.443.193-.639.058l-.069-.058-4.343-4.344c-.497.426-1.066.784-1.701 1.053-3.325 1.413-7.167-.137-8.58-3.462-.914-2.151-.611-4.626.795-6.494zm5.726-1.345c-1.965-.24-3.909.585-5.1 2.167C.926 4.79.67 6.888 1.444 8.71c1.197 2.816 4.45 4.128 7.266 2.931.671-.285 1.257-.687 1.743-1.171.001-.004.004-.007.008-.01l.012-.01c1.541-1.551 2.077-3.939 1.17-6.075-.775-1.822-2.462-3.092-4.427-3.332z"
                        transform="translate(1232.122)"
                    />
                </g>
            </g>
        </svg>
    );
};

SearchIcon.defaultProps = {
    fill: 'currentColor',
};

SearchIcon.propTypes = {
    fill: PropTypes.string,
};

export default SearchIcon;
