import PropTypes from 'prop-types';
import Link from 'components/atoms/Link';

const Breadcrumb = ({ pageTitle }) => {
    return (
        <div className="container flex flex-wrap md:justify-center mt-4 lg:mt-8">
            <Link href="/" className="opacity-60">
                Main Page
            </Link>
            <span className="mx-3">/</span>
            <span>{pageTitle}</span>
        </div>
    );
};

Breadcrumb.propTypes = {
    pageTitle: PropTypes.string,
};

export default Breadcrumb;
