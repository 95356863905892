import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--fireplace" width="64" height="64" viewBox="0 0 70 55" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M0 0L68 0 68 9 0 9zM8 9L2 9 2 53 20 53M47 53L65 53 65 9 59 9M21 43.5L2 43.5M65 43.5L48 43.5M65 34.5L47 34.5M22 34.5L2 34.5M2 26.5L65 26.5M2 17.5L65 17.5"
                        transform="translate(-835 -5305) translate(720 5240) translate(116 66)"
                    />
                    <path
                        d="M48 40c0-7.18-6.044-13-13.5-13S21 32.82 21 40v13h27V40zM11.5 26L11.5 18M56.5 26L56.5 18M11.5 53L11.5 44M56.5 53L56.5 44M51.5 34L51.5 27M16.5 34L16.5 27M22.5 17L22.5 9M44.5 17L44.5 9M34.5 26L34.5 18"
                        transform="translate(-835 -5305) translate(720 5240) translate(116 66)"
                    />
                    <path
                        d="M41 42.667c0 3.28-2.081 6.162-5.094 7.055-3.013.894-6.226-.418-7.867-3.213-1.642-2.794-1.312-6.39.806-8.806.165-.188.411-.273.65-.223.239.05.437.225.523.464.369 1.145 1.432 1.882 2.582 1.79 3.08 0 2.4-3.917.725-6.599-.172-.276-.148-.638.06-.886.208-.247.547-.32.83-.176 4.052 1.829 6.706 5.973 6.785 10.594h0z"
                        transform="translate(-835 -5305) translate(720 5240) translate(116 66)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
