import { Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from 'styles/breakpoints';

export const PrimaryLargeButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    position: relative;
    z-index: 1;
    color: #fff;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        ${props =>
            props.$inverted ? `background-color: transparent;` : `background-color: #000;`};
        z-index: -1;
        transition: 0.2s all linear;
    }
    ${props => (props.$inverted ? `border: 1px solid #fff;` : `border: 1px solid #000;`)};
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    ${props =>
        props.$addLabelIconGap &&
        `
        column-gap: 20px;
    `}
    svg,
    svg g {
        height: 20px;
        width: auto;
        margin: 4px 0 0 0;
        fill: currentColor;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            ${props =>
                props.$inverted
                    ? `color: #000;
                        &:after {
                            background-color: #fff;
                        }
                    `
                    : `color: #000;
                        &:after {
                            background-color: #fff;
                        }                        
                    `}
        }
    }
`;
export const SecondaryLargeButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 17px 20px;
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    ${props =>
        props.$addLabelIconGap &&
        `
        column-gap: 20px;
    `}
    svg,
    svg g {
        height: 20px;
        width: auto;
        transition: 0.2s all linear;
        margin: 4px 0 0 0;
        fill: #000;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            border-color: #000;
        }
    }
`;
export const PrimaryButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    font-size: 14px;
    letter-spacing: 1px;
    text-transform: uppercase;
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: center;
    align-items: center;
    padding: ${props => (props.$iconOnly ? '4px' : '8px 18px')};
    ${props =>
        props.$iconOnly &&
        `
        width: 40px;
        height: 40px;
    `}
    background-color: transparent;
    border-color: currentColor;
    border-width: 1px 0;
    outline: none;
    cursor: pointer;
    position: relative;
    ${props => (props.$inverted ? `color: #fff;` : `color: #000;`)};
    .svg-cont {
        width: 22px;
        height: 22px;
        margin: ${props => (props.$iconOnly ? '0' : '0 -8px 0 auto')};
        padding: 4px;
        /* ${props =>
            props.$inverted
                ? `background-color: #fff;
                    border: 1px solid #fff;`
                : `background-color: #000;
                    border: 1px solid #000;
        `}; */
        transition: 0.2s all linear;
        svg,
        svg g {
            fill: ${props => (props.$inverted ? '#fff' : '#000')};
        }
    }

    &:before,
    &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 120%;
        background-color: currentColor;
        transition: 0.4s all cubic-bezier(0.55, 0.09, 0.68, 0.53);
        transform: scaleY(0.2);
    }

    &:before {
        left: 0;
        top: 0;
        transform-origin: top;
    }

    &:after {
        right: 0;
        bottom: 0;
        transform-origin: bottom;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }

    &:hover:not(:disabled) {
        &:before,
        &:after {
            transform: scaleY(1);
        }
        ${props =>
            props.$inverted
                ? `.svg-cont {
                        background-color: #000;
                        border-color: #000;
                        svg,
                        svg g,
                        svg g path {
                            fill: #fff;
                        }
                    `
                : `.svg-cont {
                            background-color: #fff;
                            border-color: #000;
                            svg,
                            svg g {
                                fill: #000;
                            }
                        }
                        border-color: #000;
                    `}
    }

    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
    }
`;
export const SecondaryButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: #fff;
    color: #7f7f7f;
    border-color: #ccc;
    border-width: 0 0 1px 0;
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    .svg-cont {
        width: 30px;
        height: 30px;
        margin: 0 0 0 20px;
        padding: 6px;
        background-color: #7f7f7f;
        border: 1px solid #7f7f7f;
        transition: 0.2s all linear;
        svg,
        svg g {
            color: #fff;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            .svg-cont {
                background-color: #000;
                border-color: #000;
                svg,
                svg g {
                    fill: #fff;
                }
            }
            border-color: #ccc;
        }
    }
`;
export const TernaryButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: transparent;
    color: #000;
    border-color: #000;
    border-width: 0 0 1px 0;
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    .svg-cont {
        width: 30px;
        height: 30px;
        margin: 0 0 0 20px;
        padding: 6px;
        background-color: #000;
        border: 1px solid #000;
        transition: 0.2s all linear;
        border-radius: 6px;
        svg,
        svg g {
            color: #fff;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            .svg-cont {
                background-color: transparent;
                svg,
                svg g {
                    color: #000;
                }
            }
        }
    }
`;

export const TransparentButton = styled.button`
    font-family: var(--heading-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    color: #fff;
    border-color: #fff;
    border-width: 0 0 1px 0;
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    .svg-cont {
        width: 30px;
        height: 30px;
        margin: 0 0 0 20px;
        padding: 6px;
        background-color: #fff;
        border: 1px solid #7f7f7f;
        transition: 0.2s all linear;
        svg,
        svg g {
            fill: #000;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            .svg-cont {
                background-color: #000;
                border-color: #000;
                svg,
                svg g {
                    fill: #fff;
                }
            }
            border-color: #000;
        }
    }
`;

export const SmallButton = styled.button`
    font-family: var(--heading-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    max-width: 100%;
    width: ${props => props.$width};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    background-color: #fff;
    color: #7f7f7f;
    border-width: 0;
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    .svg-cont {
        ${props => (props.$iconPosition === 'left' ? 'margin-right: 5px' : 'margin-left: 5px')};
    }
    svg,
    svg g {
        fill: #7f7f7f;
        transition: 0.2s all linear;
    }
    &.stroke-icon {
        svg,
        svg g {
            stroke: #7f7f7f;
            fill: #fff;
            transition: 0.2s all linear;
        }
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            svg,
            svg g {
                fill: #000;
            }
            &.stroke-icon {
                svg,
                svg g {
                    fill: #fff;
                    stroke: #000;
                }
            }
            color: #000;
        }
    }
`;
export const IconButton = styled.button`
    --border-color: var(--primary-button-bg);
    max-width: 100%;
    width: ${props => props.$width};
    max-width: ${props => props.$maxWidth};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: center;
    align-items: center;
    padding: ${props => (props.$padding ? props.$padding : '9px')};
    border: 1px solid rgb(var(--border-color));
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: 0.2s all linear;
    }

    @media screen and ${breakpoints.maxMd} {
        padding: ${props => props.$paddingMobile};
    }

    svg,
    svg g,
    svg g path {
        fill: currentColor;
    }

    svg {
        transition: 0.2s all linear;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    ${props =>
        props.$theme === 'lightTransparent'
            ? `
        color: rgb(var(--primary-button-color));
        &:after {
            background-color: transparent;
        }
        @media screen and ${breakpoints.md} {
            &:not(:disabled) {
                &:focus {
                    background-color: transparent;
                    color: rgb(var(--primary-button-color));
                }
                &:hover {
                    background-color: rgb(var(--primary-button-color));
                    color: #000;
                }
            }
        }
    `
            : `
        color: ${
            props.$inverted ? 'rgb(var(--primary-button-bg))' : 'rgb(var(--primary-button-color))'
        };

        &:after {
            background-color: ${
                props.$inverted
                    ? 'rgb(var(--primary-button-color))'
                    : 'rgb(var(--primary-button-bg))'
            };
        }
        @media screen and ${breakpoints.md} {
            &:not(:disabled) {
                &:hover,
                &:focus {
                    color: ${
                        props.$inverted
                            ? 'rgb(var(--primary-button-color))'
                            : 'rgb(var(--primary-button-bg))'
                    };
                    &:after {
                        background-color: ${
                            props.$inverted
                                ? 'rgb(var(--primary-button-bg))'
                                : 'rgb(var(--primary-button-color))'
                        };
                    }
                }
            }
        }
    `}
`;
export const PrimarySmallButton = styled.button`
    font-family: var(--text-font-family);
    font-weight: ${props => (props?.fontWeight ? props.fontWeight : 'var(--text-font-weight)')};
    line-height: 1;
    max-width: 100%;
    width: ${props => props.$width};
    max-width: ${props => props.$maxWidth};
    display: ${props => (props?.display ? props.display : 'flex')};
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    position: relative;
    z-index: 1;
    color: #fff;
    &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        ${props =>
            props.$inverted ? `background-color: transparent;` : `background-color: #000;`};
        z-index: -1;
        transition: 0.2s all linear;
    }
    ${props => (props.$inverted ? `border: 1px solid #fff;` : `border: 1px solid #000;`)};
    transition: 0.2s all linear;
    outline: none;
    cursor: pointer;
    ${props =>
        props.$addLabelIconGap &&
        `
        column-gap: 10px;
    `}
    svg,
    svg g {
        height: 16px;
        width: auto;
        margin: 2px 0 0 0;
        fill: currentColor;
    }
    &:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
    @media screen and ${breakpoints.md} {
        max-width: ${props => props.$maxWidth};
        &:hover:not(:disabled) {
            ${props =>
                props.$inverted
                    ? `color: #000;
                        &:after {
                            background-color: #fff;
                        }
                    `
                    : `color: #000;
                        &:after {
                            background-color: #fff;
                        }                        
                    `}
        }
    }
`;

const Button = ({
    type,
    buttonType,
    size,
    label,
    labelClassName,
    iconClassName = '',
    addLabelIconGap,
    icon,
    iconPosition,
    width,
    inverted,
    maxWidth,
    padding,
    paddingMobile,
    theme,
    ...props
}) => {
    const customAttrs = {
        $width: width,
        $inverted: inverted,
        $maxWidth: maxWidth,
        $padding: padding,
        $paddingMobile: paddingMobile,
        $theme: theme,
    };
    if (type === 'primary' && size === 'large') {
        return (
            <PrimaryLargeButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className={`svg-cont ${iconClassName}`}>{icon}</span>}
            </PrimaryLargeButton>
        );
    }
    if (type === 'primary' && size === 'small') {
        return (
            <PrimarySmallButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </PrimarySmallButton>
        );
    }
    if (type === 'secondary' && size === 'large') {
        return (
            <SecondaryLargeButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </SecondaryLargeButton>
        );
    }
    if (type === 'primary' && size === 'medium') {
        return (
            <PrimaryButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </PrimaryButton>
        );
    }
    if (type === 'secondary' && size === 'medium') {
        return (
            <SecondaryButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </SecondaryButton>
        );
    }
    if (type === 'ternary' && size === 'medium') {
        return (
            <TernaryButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </TernaryButton>
        );
    }
    // Adding button with transparent background, used in Blog Secondary Hero
    if (type === 'transparent' && size === 'medium') {
        return (
            <TransparentButton
                type={buttonType}
                $addLabelIconGap={addLabelIconGap}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                <span className={labelClassName}>{label}</span>
                {icon && <span className="svg-cont">{icon}</span>}
            </TransparentButton>
        );
    }
    if (type === 'icon') {
        return (
            <IconButton type={buttonType} $iconOnly={true} {...customAttrs} {...props}>
                {icon}
            </IconButton>
        );
    }
    if (size === 'small') {
        return (
            <SmallButton
                type={buttonType}
                $iconPosition={iconPosition}
                $iconOnly={!label}
                {...customAttrs}
                {...props}
            >
                {iconPosition === 'left' ? (
                    <Fragment>
                        {icon && <span className="svg-cont">{icon}</span>}
                        <span className={labelClassName}>{label}</span>
                    </Fragment>
                ) : (
                    <Fragment>
                        <span className={labelClassName}>{label}</span>
                        {icon && <span className="svg-cont">{icon}</span>}
                    </Fragment>
                )}
            </SmallButton>
        );
    }
};

export default Button;

Button.propTypes = {
    type: PropTypes.string.isRequired,
    buttonType: PropTypes.string,
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    label: PropTypes.string,
    labelClassName: PropTypes.string,
    iconClassName: PropTypes.string,
    addLabelIconGap: PropTypes.bool,
    icon: PropTypes.element,
    iconPosition: PropTypes.string,
    onClick: PropTypes.func,
    width: PropTypes.string,
    inverted: PropTypes.bool,
    maxWidth: PropTypes.string,
    padding: PropTypes.string,
    paddingMobile: PropTypes.string,
    theme: PropTypes.string,
};

Button.defaultProps = {
    type: 'primary',
    size: 'medium',
    labelClassName: '',
    addLabelIconGap: true,
    iconPosition: 'right',
    onClick: undefined,
};
