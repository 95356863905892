import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--biblios"
                width="200"
                viewBox="0 0 200 132"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polyline points="42.2,1.2 41.7,6.2 41.1,11.2 " />
                <polyline points="184.7,16.6 184.1,21.6 183.6,26.6 " />
                <line x1="41.7" y1="6.2" x2="184.1" y2="21.6" />
                <polyline points="188.5,32.2 193.5,32.2 198.5,32.2 " />
                <polyline points="186.4,117.7 191.4,117.7 196.4,117.7 " />
                <line x1="191.4" y1="117.7" x2="193.5" y2="32.2" />
                <polygon points="1.5,22.4 1.5,103.2 149.1,130.8 151.4,40.9 " />
                <polygon points="1.5,22.4 151.4,40.9 183.3,32.4 40.8,16.2 " />
                <polygon points="151.4,40.9 149.1,130.8 181.2,117.6 183.3,32.4 " />
                <polygon points="8.9,34.5 19.1,35.7 34.8,100.9 9.3,96.3 " />
                <polygon points="29.5,37 44.4,102.6 47.7,103.2 61.1,40.7 " />
                <polygon points="70,41.8 80,43 80.1,109.1 57.2,104.9 " />
                <polygon points="90.4,110.9 101,112.8 125.3,48.3 89.6,44.1 " />
                <polygon points="110.1,114.5 139.8,119.8 141.8,50.3 137.4,49.8 " />
                <line x1="110.1" y1="114.5" x2="140.3" y2="103.3" />
                <line x1="90.4" y1="110.9" x2="103.1" y2="107.2" />
                <line x1="57.2" y1="104.9" x2="80.1" y2="97.6" />
                <line x1="44.4" y1="102.6" x2="48.2" y2="101.1" />
                <line x1="9.3" y1="96.3" x2="32.1" y2="89.9" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
