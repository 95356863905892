import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--star"
                width="120"
                viewBox="0 0 337.9 329.5"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="107.5,283.9 45.7,283.9 45.7,222 2,178.3 45.7,134.6 45.7,72.7 107.5,72.7 151.3,29 195,72.7 256.8,72.7 256.8,134.6 300.6,178.3 256.8,222 256.8,283.9 195,283.9 151.3,327.6      " />
                <path d="M151.3,29.7l43.2,43.2l0.3,0.3h0.4h61.1v61.2v0.4l0.3,0.3l43.3,43.2l-43.3,43.2l-0.3,0.3v0.4v61.2h-61.1h-0.4l-0.3,0.3 l-43.2,43.2L108,283.7l-0.3-0.3h-0.4H46.2v-61.2v-0.4l-0.3-0.3L2.7,178.3l43.2-43.2l0.3-0.3v-0.4V73.2h61.1h0.4l0.3-0.3 L151.3,29.7 M151.3,28.3l-44,43.9H45.2v62.2L1.3,178.3l43.9,43.9v62.2h62.1l44,43.9l43.9-43.9h62.1v-62.2l44-43.9l-44-43.9V72.2 h-62.1L151.3,28.3L151.3,28.3z" />
                <line x1="1.2" y1="8.8" x2="301.2" y2="8.8" />
                <line x1="301.2" y1="1.2" x2="301.2" y2="16.3" />
                <line x1="1.2" y1="1.2" x2="1.2" y2="16.3" />
                <line x1="328.5" y1="28.4" x2="328.5" y2="328.3" />
                <line x1="336" y1="328.3" x2="320.9" y2="328.3" />
                <line x1="336" y1="28.4" x2="320.9" y2="28.4" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
