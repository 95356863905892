import moment from 'moment';
import Fraction from 'fraction.js';

export const delay = ms => new Promise(res => setTimeout(res, ms));

export const getCloudinaryId = url => {
    return url?.replace(/.*\/image\/upload\/[A-Za-z,0-9]*/, '');
};

export const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

export const formatDateForAccountOrderTable = dateStr => {
    const d = new Date(dateStr);
    return `${months[d.getMonth()].slice(0, 3)} ${d.getDate()}, ${d.getFullYear()}`;
};

export const formatDateForAccountProjectTable = dateStr => {
    const d = new Date(dateStr);
    return `${months[d.getMonth()].slice(0, 3)} ${d.getDate()}, ${d.getFullYear()}`;
};

export const formatDateForAccountOrderData = dateStr => {
    return `${moment(dateStr).format('MMMM D, YYYY')} at ${moment(dateStr).format('hh:mm a')}`;
};

export const formatStorefrontProductId = id =>
    Number(atob(id).replace('gid://shopify/Product/', ''));

export const formatAdminProductId = id => btoa('gid://shopify/Product/' + id);

export const formatAdminCustomerId = id => btoa('gid://shopify/Customer/' + id);

export const shopifyLoader = ({ src, width, quality }) => {
    return `${src.slice(0, src.length - 17)}_${width}x${src.slice(-17)}`;
};

export const newLineToBr = ({ string }) => {
    if (typeof string !== 'undefined') {
        return string.replace(/(?:\r\n|\r|\n)/g, '<br />');
    }
    return null;
};

export const removeEmpty = obj => {
    return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export const stripWhitepace = str => {
    return str.trim().replace(/  +/g, ' ');
};

export const isSampleProduct = product => {
    return product?.title?.toLowerCase().includes(', sample');
};

export const getLineItemSampleType = lineItem => {
    const isSample =
        lineItem?.customAttributes?.find(attr => attr.key === '__is_sample')?.value === 'true';
    const isPaidSample =
        lineItem?.customAttributes?.find(attr => attr.key === '__is_paid_sample')?.value === 'true';
    return isSample ? 'sample' : isPaidSample ? 'paid-sample' : 'non-sample';
};

export const isAPIError = varToBeChecked => varToBeChecked?.error_code !== undefined;

/**
 * to be more specific and to ensure things like 1.005 round correctly, use Number.EPSILON
 *
 * @param {Number} num
 * @returns
 */
export const roundToTwo = num => {
    return (Math.round((Number(num) + Number.EPSILON) * 100) / 100).toFixed(2);
};

export const formatFinancialStatus = financialStatus => financialStatus?.replace(/_/g, ' ');

export const formatFulfillmentStatus = fulfillmentStatus => {
    if (fulfillmentStatus === 'UNFULFILLED') {
        return 'PROCESSING';
    }
    if (fulfillmentStatus === 'FULFILLED') {
        return 'SHIPPED/PICKED UP';
    }
    return fulfillmentStatus?.replace(/_/g, ' ');
};

export const debounce = (func, timeout = 300) => {
    let timer;
    return (...args) => {
        if (!timer) {
            func.apply(this, args);
        }
        clearTimeout(timer);
        timer = setTimeout(() => {
            timer = undefined;
        }, timeout);
    };
};

export const arraysHaveMatchingObjects = (arr1, arr2) => {
    if (arr1.length !== arr2.length) {
        return false; // Arrays have different lengths
    }

    // Helper function to check if two objects have the same key-value pairs
    const objectsMatch = (obj1, obj2) => {
        const keys1 = Object.keys(obj1).sort();
        const keys2 = Object.keys(obj2).sort();

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let i = 0; i < keys1.length; i++) {
            const key1 = keys1[i];
            const key2 = keys2[i];

            if (key1 !== key2 || obj1[key1] !== obj2[key2]) {
                return false;
            }
        }

        return true;
    };

    // Check if each object in arr1 has a matching object in arr2
    for (const obj1 of arr1) {
        let found = false;
        for (const obj2 of arr2) {
            if (objectsMatch(obj1, obj2)) {
                found = true;
                break;
            }
        }
        if (!found) {
            return false; // No matching object found in arr2
        }
    }

    return true;
};

export const isInternalLink = href => {
    // Get the hostname of your Next.js application
    const appHostname = window.location.hostname;

    // Parse the provided href to extract the hostname
    const parsedHref = new URL(href, window.location.href);

    // Compare the hostnames, excluding www subdomains
    return parsedHref.hostname && appHostname && parsedHref.hostname === appHostname;
};

export const getUrlWithUtmParams = (req, urlString) => {
    const persistUtm = req.cookies?.['persist_utm'];

    if (!persistUtm) {
        return urlString;
    }

    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    const queryParams = [];

    utmParameters.forEach(param => {
        const value = req.cookies?.[param];
        if (value) {
            queryParams.push(`${param}=${value}`);
        }
    });

    if (queryParams.length === 0) {
        return urlString;
    }

    const separator = urlString.includes('?') ? '&' : '?';
    const updatedUrl = `${urlString}${separator}${queryParams.join('&')}`;

    return updatedUrl;
};

/**
 *
 * @param {string} path
 * @returns
 */
export const removeQueryParametersFromPath = path => {
    // Split the path by "?" to separate the path and query parameters
    const parts = path.split('?');
    // The first part (index 0) contains the path, and the rest (if any) are query parameters
    const pathWithoutQuery = parts[0];
    return pathWithoutQuery;
};

/**
 *
 * @param {{key: string, namespace: string, value: string}[]} metafields
 * @param {string} namespace
 * @param {string} key
 * @returns {string}
 */
export const getMetafieldValue = (metafields, namespace, key) => {
    return metafields?.find(mf => mf?.namespace === namespace && mf?.key === key)?.value ?? null;
};

export const decimalToFraction = decimal => {
    const fractionHelper = new Fraction(decimal);
    let fractionFormatted = fractionHelper.simplify().toFraction(true);
    const fractionParts = fractionFormatted.split(' ');

    if (fractionParts.length > 1) {
        const [wholeNum, numDen] = fractionParts;
        const [num, den] = numDen.split('/');

        fractionFormatted = (
            <>
                {wholeNum} <sup>{num}</sup>/<sub>{den}</sub>
            </>
        );
    }

    return fractionFormatted;
};
// export const decimalToFraction = decimal => {
//     if (Number.isInteger(decimal)) return `${decimal}/1`;

//     let negative = decimal < 0;
//     if (negative) decimal = -decimal;

//     const tolerance = 1.0e-10;
//     let numerator = 1;
//     let denominator = 1;
//     let fraction = numerator / denominator;

//     while (Math.abs(fraction - decimal) > tolerance) {
//         if (fraction < decimal) {
//             numerator++;
//         } else {
//             denominator++;
//             numerator = Math.round(decimal * denominator);
//         }
//         fraction = numerator / denominator;
//     }

//     return `${negative ? '-' : ''}${numerator}/${denominator}`;
// };

export const identifyShape = shape => {
    /**
     * Removed in 2024-05-01
     */
    // const mosaicShapes = ['Prismatic', 'Gambit', 'Lattice', 'Perpetual Check', 'Rubric', 'Radian'];
    // if (mosaicShapes.includes(shape)) return 'Mosaic';
    // TODO: remove after product metafield data set
    if (shape === 'PerpetualCheck') return 'Perpetual Check';
    if (shape === 'RadianOffsetInline') return 'Radian (Offset + Inline)';
    if (shape === 'Star') return 'Star & Cross';
    // ENDTODO
    return shape;
};

export const splitIntoNSubarrays = (arr, n) => {
    const length = arr.length;

    if (length % n !== 0) {
        // If the length is not divisible evenly by n, you may need to decide how to handle the remainder.
        // In this example, the remainder is ignored, and the last subarray may be shorter than the others.
        console.warn(`Warning: The length of the array is not divisible evenly by ${n}.`);
    }

    const subarrays = Array.from({ length: n }, (_, index) =>
        arr.filter((_, i) => i % n === index)
    );

    return subarrays;
};

/**
 *
 * @param {Number} quantity
 * @param {Number} quantityAvailable
 * @param {Number} inventoryQuantityThreshold
 * @param {String} forceInventoryStatus
 * @returns {String}
 */
export const getInventoryStatus = (
    quantity,
    quantityAvailable,
    inventoryQuantityThreshold,
    forceInventoryStatus
) => {
    if (forceInventoryStatus === 'Special Order') {
        return forceInventoryStatus;
    }
    if (quantityAvailable > inventoryQuantityThreshold && quantityAvailable >= quantity) {
        return 'In Stock';
    }

    return forceInventoryStatus || 'In Transit';
};

// Throttle function to limit the rate of function execution
export const throttle = (fn, wait = 100) => {
    let lastTime = 0;
    return (...args) => {
        const now = new Date().getTime();
        if (now - lastTime >= wait) {
            lastTime = now;
            fn(...args);
        }
    };
};
