import SiteContext from 'context/SiteContext';
import { initialArea, initialOverage, tagValueMapping } from 'helpers/constants/product';

const { getMetafieldValue, roundToTwo } = require('helpers');
const { useMemo, useState, useEffect, useCallback, useContext } = require('react');

// TODO: Mote to useProductStore
const useProductForm = ({ selectedVariant, quantity, setQuantity, product, shippingInterval }) => {
    const {
        store: { checkoutCreated, cartType },
        addVariantToCart,
        setSidebarCartOpen,
        openCartTypeErrorModal,
    } = useContext(SiteContext);

    const [area, setArea] = useState(initialArea);
    const [overage, setOverage] = useState(initialOverage);

    const [isAdding, setIsAdding] = useState(false);

    const data = useMemo(() => {
        const minimumCoverage = Number(
            getMetafieldValue(product.metafields, 'accentuate', 'minimum_coverage') || initialArea
        );

        setArea(minimumCoverage);

        return {
            length: product?.metafields?.find(mf => mf?.key === 'length')?.value,
            width: product?.metafields?.find(mf => mf?.key === 'width')?.value,
            shape: product?.metafields?.find(mf => mf?.key === 'shape')?.value,
            tilesPerBox: getMetafieldValue(
                product?.metafields || [],
                'accentuate',
                'tiles_per_box'
            ),
            sqFtPerBox: getMetafieldValue(
                product?.metafields || [],
                'accentuate',
                'square_feet_box_ratio'
            ),
            minimumCoverage,
        };
    }, [product?.metafields]);

    const pricePerSquareFoot = useMemo(() => {
        return roundToTwo(Number(selectedVariant.price) / Number(data.sqFtPerBox));
    }, [selectedVariant, data.sqFtPerBox]);

    const pricePerTile = useMemo(() => {
        return roundToTwo(Number(selectedVariant.price) / Number(data.tilesPerBox));
    }, [selectedVariant.price, data.tilesPerBox]);

    const totalSquareFeet = useMemo(() => {
        return roundToTwo(quantity * Number(data.sqFtPerBox));
    }, [quantity, data.sqFtPerBox]);

    const addToCart = useCallback(
        async event => {
            event.preventDefault();

            if (isAdding || !checkoutCreated || Number(area) < data.minimumCoverage) {
                return;
            }

            if (cartType === 'sample') {
                openCartTypeErrorModal();
                return;
            }

            const items = [
                {
                    key: 'Total ft²',
                    value: `${totalSquareFeet}`,
                },
                {
                    key: '_template',
                    value:
                        product?.metafields?.find(mf => mf?.key === 'template')?.value || 'default',
                },
                {
                    key: '_square_feet_box_ratio',
                    value: `${data.sqFtPerBox}`,
                },
            ];

            const upsellItems = product?.tags
                ?.map(tag => {
                    if (tag.includes('__upsell:')) {
                        const tagValue = tag.split(':');
                        if (tagValue[1] && tagValue[1].trim().length > 0) {
                            const cleanedValue = tagValue[1].trim();
                            return tagValueMapping[cleanedValue] || cleanedValue;
                        }
                    }
                    return null;
                })
                ?.filter(tag => tag !== null)
                ?.join(', ');

            if (upsellItems) {
                items.push({
                    key: '__upsellItems',
                    value: upsellItems,
                });
            }
            items.push({
                key: 'Dimensions',
                value: `${data.length}” x ${data.width}” ${data.shape}`,
            });
            if (shippingInterval) {
                items.push({
                    key: 'Lead Time',
                    value: `${
                        shippingInterval.charAt(0).toUpperCase() + shippingInterval.slice(1)
                    }`,
                });
            }
            setIsAdding(true);
            /* items.push({ key: 'overage', value: overage }); */
            items.push({
                key: 'Boxes',
                value: `${quantity}`,
            });
            addVariantToCart(selectedVariant.id, quantity, items).then(() => {
                if (window.fbq) {
                    window.fbq('track', 'AddToCart');
                }
                setIsAdding(false);
                setSidebarCartOpen(true);
            });
        },
        [
            product,
            selectedVariant,
            quantity,
            overage,
            setIsAdding,
            setSidebarCartOpen,
            totalSquareFeet,
            cartType,
            data.sqFtPerBox,
        ]
    );

    useEffect(() => {
        if (!area) {
            setQuantity(1);
        } else {
            setQuantity(Math.ceil((area / Number(data.sqFtPerBox)) * Number(overage)));
        }
    }, [area, data.sqFtPerBox, overage]);
    return {
        area,
        setArea,
        minimumCoverage: data.minimumCoverage,
        overage,
        setOverage,
        pricePerSquareFoot,
        pricePerTile,
        tilesPerBox: data.tilesPerBox,
        sqFtPerBox: data.sqFtPerBox,
        totalSquareFeet,
        addToCart,
    };
};

export default useProductForm;
