import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import { CustomEase } from 'gsap/dist/CustomEase';

if (typeof window !== 'undefined') {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CustomEase);
    CustomEase.create('customDefault', 'M0,0 C0.25,0.1 0.25,1 1,1');
}

const RevealElementStyles = styled.div`
    opacity: 0;
    backface-visibility: hidden;
    will-change: opacity;
    position: relative;
    transform: translateY(50px);
    ${props => (props?.block ? 'display: block' : null)}
`;

const RevealElement = ({ delay, duration, start, block, children, ...props }) => {
    const ref = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            if (ref?.current) {
                gsap.to(ref.current, {
                    scrollTrigger: {
                        trigger: ref.current || '',
                        start,
                        end: '+=500',
                        toggleActions: 'play none play none',
                        scrub: false,
                        markers: false,
                    },
                    autoAlpha: 1,
                    y: 0,
                    duration,
                    delay: delay || 0,
                    ease: 'customDefault',
                });
            }
        }, 1000);
    }, [delay, duration, start, ref]);

    if (!children) return null;

    return (
        <RevealElementStyles ref={ref} block={block} {...props}>
            {children}
        </RevealElementStyles>
    );
};

RevealElement.defaultProps = {
    delay: 0,
    duration: 0.6,
    start: 'top 90%',
    block: true,
};

RevealElement.propTypes = {
    delay: PropTypes.number,
    duration: PropTypes.number,
    start: PropTypes.string,
    block: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default RevealElement;
