import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--link" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <path
                d="M7 8.76024C7.2517 9.11522 7.57282 9.40895 7.94158 9.6215C8.31035 9.83404 8.71813 9.96043 9.13726 9.9921C9.5564 10.0238 9.97709 9.95997 10.3708 9.80503C10.7645 9.6501 11.122 9.40764 11.4191 9.09412L13.1774 7.23922C13.7112 6.65615 14.0066 5.87523 13.9999 5.06465C13.9932 4.25406 13.685 3.47867 13.1417 2.90548C12.5984 2.33229 11.8634 2.00716 11.095 2.00012C10.3267 1.99307 9.58642 2.30468 9.03373 2.86782L8.02566 3.92512"
                fill="none"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
            <path
                d="M9 7.23976C8.7483 6.88478 8.42718 6.59105 8.05842 6.3785C7.68965 6.16596 7.28187 6.03957 6.86273 6.0079C6.4436 5.97623 6.02291 6.04003 5.62919 6.19497C5.23548 6.3499 4.87796 6.59236 4.58088 6.90588L2.82261 8.76078C2.28881 9.34385 1.99343 10.1248 2.00011 10.9354C2.00679 11.7459 2.31498 12.5213 2.85831 13.0945C3.40164 13.6677 4.13663 13.9928 4.90499 13.9999C5.67334 14.0069 6.41358 13.6953 6.96627 13.1322L7.96848 12.0749"
                fill="none"
                stroke={fill}
                strokeWidth="1.5"
                strokeLinecap="square"
                strokeLinejoin="round"
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
