import PropTypes from 'prop-types';

const ArrowLeftIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--arrowLeft" width="16" height="16" viewBox="0 0 16 10" {...attrs}>
            <path
                d="M4.22605 9.6109C4.47121 9.85389 4.86694 9.85212 5.10993 9.60696C5.35291 9.36179 5.35115 8.96607 5.10598 8.72308L3.63684 7.26699C3.0406 6.67605 2.63159 6.26934 2.35484 5.92474C2.2649 5.81275 2.19473 5.71416 2.14025 5.62533L14.666 5.62532C15.0112 5.62532 15.291 5.3455 15.291 5.00032C15.291 4.65515 15.0112 4.37532 14.666 4.37532L2.14025 4.37533C2.19473 4.28649 2.2649 4.1879 2.35484 4.07591C2.63159 3.73131 3.0406 3.32461 3.63684 2.73366L5.10598 1.27757C5.35115 1.03458 5.35291 0.638858 5.10993 0.393693C4.86694 0.148529 4.47121 0.146764 4.22605 0.38975L2.73043 1.87208C2.16712 2.43036 1.70673 2.88665 1.38023 3.29321C1.04071 3.71598 0.794928 4.1451 0.729274 4.66019C0.71488 4.77312 0.707683 4.88672 0.707683 5.00033C0.707683 5.11393 0.71488 5.22753 0.729274 5.34046C0.794928 5.85556 1.04071 6.28467 1.38023 6.70744C1.70674 7.11401 2.16713 7.5703 2.73045 8.12859L4.22605 9.6109Z"
                fill={fill}
            />
        </svg>
    );
};

ArrowLeftIcon.defaultProps = {
    fill: 'currentColor',
};

ArrowLeftIcon.propTypes = {
    fill: PropTypes.string,
};

export default ArrowLeftIcon;
