import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--shipping" width="16" height="17" viewBox="0 0 16 17" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-655 -787) translate(379 780) translate(269)">
                    <g fill={fill} fillRule="nonzero">
                        <path
                            d="M9.137.203c.529-.27 1.155-.27 1.685 0l4.127 2.109c.636.33 1.033.987 1.03 1.7v4.584c.003.716-.395 1.374-1.033 1.705l-4.124 2.107c-.53.27-1.157.27-1.685 0l-4.128-2.109c-.635-.33-1.033-.986-1.03-1.7V4.015c-.003-.716.394-1.373 1.03-1.703zm5.84 3.76l-1.749.892v1.636c0 .276-.223.5-.5.5-.267 0-.486-.211-.499-.476V6.49l-.001-1.125-1.75.894v5.2l4.01-2.049c.27-.139.449-.402.484-.7l.007-.112V4.01l-.001-.048zm-9.996-.001l-.002.05v4.589c-.002.34.188.654.488.809l4.011 2.049V6.26L4.981 3.962zM.703 6.663l.023.01 1.958.992c.246.125.345.426.22.672-.12.24-.407.339-.65.231l-.022-.01-1.958-.992c-.246-.125-.345-.426-.22-.672.121-.239.408-.339.65-.23zM.777 4.01l.022.012 1.874 1.004c.244.13.336.433.205.676-.126.236-.415.33-.654.216l-.023-.01L.327 4.901c-.244-.13-.335-.433-.205-.677.126-.236.415-.33.655-.216zM7.229 2.3l-1.651.844 4.4 2.248 1.651-.843-4.4-2.249zm3.138-1.207c-.244-.124-.532-.124-.775 0l-1.265.646 4.401 2.248 1.651-.843zm-8.773.72l.021.012 1.126.694c.235.145.308.453.163.688-.14.228-.434.304-.666.176l-.022-.013-1.126-.694c-.235-.145-.308-.453-.163-.688.14-.228.435-.303.667-.176z"
                            transform="translate(7 7.366) translate(.01 1.095)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
