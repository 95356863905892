import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

const StoryblokSection = ({
    _uid,
    component,
    children,
    className = '',
    blockId,
    setBlockInView,
}) => {
    const blockRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setBlockInView(blockId);
                }
            },
            {
                root: null, // viewport
                rootMargin: '0px',
                threshold: 0.6, // 60% of the block is visible
            }
        );

        if (blockRef.current && blockId && setBlockInView) {
            observer.observe(blockRef.current);
        }

        return () => {
            if (blockRef.current && blockId && setBlockInView) {
                observer.unobserve(blockRef.current);
            }
        };
    }, [blockId, setBlockInView]);

    return (
        <div
            ref={blockRef}
            id={`storyblokSection-${_uid}`}
            className={`storyblokSection storyblokSection--${component} ${className}`}
        >
            {children}
        </div>
    );
};
StoryblokSection.propTypes = {
    _uid: PropTypes.string,
    component: PropTypes.string,
    children: PropTypes.oneOf([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    className: PropTypes.string,
    blockId: PropTypes.string,
    setBlockInView: PropTypes.func,
};
export default StoryblokSection;
