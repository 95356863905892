import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', thicknessAdjustment = 0, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--thickness"
                viewBox={`0 0 336 ${thicknessAdjustment + 37}`}
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="1" y="1" width="299" height={thicknessAdjustment + 34} />
                <line x1="328" y1="1" x2="328" y2={thicknessAdjustment + 36} />
                <line
                    x1="335"
                    y1={thicknessAdjustment + 36}
                    x2="320"
                    y2={thicknessAdjustment + 36}
                />
                <line x1="335" y1="1" x2="320" y2="1" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
    thicknessAdjustment: PropTypes.number,
};

export default Icon;
