import produce from 'immer';
import { createStore, useStore } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createContext, useContext, useMemo, useRef } from 'react';

/**
 *
 * @param {Number} quantity
 * @param {Number} quantityAvailable
 * @param {Number} inventoryQuantityThreshold
 * @param {String} forceInventoryStatus
 * @returns {String}
 */
const getInventoryStatus = (
    quantity,
    quantityAvailable,
    inventoryQuantityThreshold,
    forceInventoryStatus
) => {
    if (forceInventoryStatus === 'Special Order') {
        return forceInventoryStatus;
    }
    if (quantityAvailable > inventoryQuantityThreshold && quantityAvailable >= quantity) {
        return 'In Stock';
    }

    return forceInventoryStatus || 'In Transit';
};

/**
 *
 * @param {Number} quantity
 * @param {Number} quantityAvailable
 * @param {Number} inventoryQuantityThreshold
 * @param {String} forceInventoryStatus
 * @param {Array} specialOrderLeadTimes
 * @param {Array} shippingIntervals
 * @param {String} fallbackShippingInterval
 * @param {String} collectionsHandle
 * @returns {String}
 */
const getShippingInterval = (
    quantity,
    quantityAvailable,
    inventoryQuantityThreshold,
    forceInventoryStatus,
    specialOrderLeadTimes,
    shippingIntervals,
    fallbackShippingInterval,
    collectionsHandle
) => {
    if (forceInventoryStatus === 'Special Order') {
        return fallbackShippingInterval;
        /* return (
            specialOrderLeadTimes?.find(({ collectionHandle }) =>
                collectionsHandle?.includes(collectionHandle)
            )?.leadTime || null
        ); */
    }
    if (quantityAvailable > inventoryQuantityThreshold && quantity <= quantityAvailable) {
        return 'Ready to ship';
    }

    if (shippingIntervals?.length) {
        return (
            shippingIntervals?.find(({ cutoff }) => quantity <= cutoff).message ||
            shippingIntervals?.at(-1) ||
            null
        );
    }

    return fallbackShippingInterval;
};

const createProductStore = initProps => {
    const DEFAULT_PROPS = {
        collectionsHandle: [],
        selectedVariant: null,
        quantity: 1,
        inventoryStatus: null,
        forceInventoryStatus: null,
        inventoryQuantityThreshold: 0,
        shippingInterval: null,
        shippingIntervals: null,
        fallbackShippingInterval: null,
    };

    return createStore(
        immer((set, get) => ({
            ...DEFAULT_PROPS,
            ...initProps,
            setSelectedVariant: variant =>
                set(
                    produce(draft => {
                        draft.selectedVariant = variant;

                        // Handle inventoryStatus
                        draft.inventoryStatus = getInventoryStatus(
                            draft.quantity,
                            variant.quantityAvailable,
                            draft.inventoryQuantityThreshold,
                            draft.forceInventoryStatus
                        );

                        // Handle shippingInterval
                        draft.shippingInterval = getShippingInterval(
                            draft.quantity,
                            variant.quantityAvailable,
                            draft.inventoryQuantityThreshold,
                            draft.forceInventoryStatus,
                            draft.specialOrderLeadTimes,
                            draft.shippingIntervals,
                            draft.fallbackShippingInterval,
                            draft.collectionHandle
                        );
                    })
                ),
            setQuantity: quantity =>
                set(
                    produce(draft => {
                        draft.quantity = quantity;

                        // Handle shippingInterval
                        draft.shippingInterval = getShippingInterval(
                            quantity,
                            draft.selectedVariant.quantityAvailable,
                            draft.inventoryQuantityThreshold,
                            draft.forceInventoryStatus,
                            draft.specialOrderLeadTimes,
                            draft.shippingIntervals,
                            draft.fallbackShippingInterval,
                            draft.collectionHandle
                        );

                        // Handle inventoryStatus
                        draft.inventoryStatus = getInventoryStatus(
                            quantity,
                            draft.selectedVariant.quantityAvailable,
                            draft.inventoryQuantityThreshold,
                            draft.forceInventoryStatus
                        );
                    })
                ),
            setInventoryQuantityThreshold: threshold =>
                set(
                    produce(draft => {
                        draft.inventoryQuantityThreshold = threshold;
                    })
                ),
            setForceInventoryStatus: inventoryStatus =>
                set(
                    produce(draft => {
                        draft.forceInventoryStatus = inventoryStatus;
                    })
                ),
            setShippingInvervals: shippingIntervals =>
                set(
                    produce(draft => {
                        draft.shippingIntervals = shippingIntervals;
                    })
                ),
            setProductStore: (product, inventoryQuantityThreshold) =>
                set(
                    produce(draft => {
                        const inventoryStatus =
                            product?.metafields?.find(mf => mf?.key === 'select_lead_time')
                                ?.value || null;
                        const shippingIntervals = product?.metafields?.find(
                            mf => mf?.key === 'lead_times'
                        )?.value
                            ? JSON.parse(
                                  product.metafields.find(mf => mf?.key === 'lead_times').value
                              )
                            : null;
                        const fallbackShippingInterval =
                            product?.metafields?.find(mf => mf?.key === 'lead_time')?.value || null;

                        const variant = product?.variants?.[0] || null;

                        draft.forceInventoryStatus = inventoryStatus;
                        draft.shippingIntervals = shippingIntervals;
                        draft.fallbackShippingInterval = fallbackShippingInterval;
                        draft.selectedVariant = variant;
                        draft.inventoryQuantityThreshold = inventoryQuantityThreshold;

                        if (variant) {
                            // Handle shippingInterval
                            draft.shippingInterval = getShippingInterval(
                                draft.quantity,
                                variant.quantityAvailable,
                                inventoryQuantityThreshold,
                                inventoryStatus,
                                draft.specialOrderLeadTimes,
                                shippingIntervals,
                                fallbackShippingInterval,
                                draft.collectionsHandle
                            );

                            // Handle inventoryStatus
                            draft.inventoryStatus = getInventoryStatus(
                                draft.quantity,
                                variant.quantityAvailable,
                                inventoryQuantityThreshold,
                                inventoryStatus
                            );
                        }
                    })
                ),
        }))
    );
};

const ProductContext = createContext(createProductStore);

const ProductProvider = ({ children, settings, product, ...props }) => {
    const productStoreRef = useRef();
    if (!product) return null;

    const computedProps = useMemo(() => {
        return {
            inventoryQuantityThreshold: Number(settings?.product?.inventoryQuantityThreshold) || 0,
            forceInventoryStatus:
                product?.metafields?.find(mf => mf?.key === 'select_lead_time')?.value || null,
            shippingIntervals: product?.metafields?.find(mf => mf?.key === 'lead_times')?.value
                ? JSON.parse(product.metafields.find(mf => mf?.key === 'lead_times').value)
                : null,
            fallbackShippingInterval:
                product?.metafields?.find(mf => mf?.key === 'lead_time')?.value || null,
            selectedVariant: product.variants[0],
            specialOrderLeadTimes: settings?.product?.specialOrderLeadTimes || [],
            collectionsHandle: product?.collectionsHandle || [],
        };
    }, [settings, product]);

    if (!productStoreRef.current) {
        productStoreRef.current = createProductStore({ ...computedProps, ...props });
    }

    return (
        <ProductContext.Provider value={productStoreRef.current}>
            {children}
        </ProductContext.Provider>
    );
};

export const useProductContext = (selector, equalityFn) => {
    const productStore = useContext(ProductContext);

    if (!productStore) throw new Error('Missing ProductContext.Provider in the tree');

    return useStore(productStore, selector, equalityFn);
};

export default ProductProvider;
