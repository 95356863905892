import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from 'styles/breakpoints';

export const H0 = styled.h1`
    font-family: ${({ fontFamily }) =>
        fontFamily === 'subheading'
            ? 'var(--subheading-font-family)'
            : fontFamily === 'text'
            ? 'var(--text-font-family)'
            : 'var(--heading-font-family)'};
    font-size: ${props => (props.fontSize ? props.fontSize : '96px')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'var(--heading-font-weight)')};
    font-stretch: normal;
    text-transform: ${props =>
        props.textTransform ? props.textTransform : 'var(--heading-capitalize)'};
    margin: ${props => props.margin};
    text-align: ${props => props.align};
    color: ${props => (props.color ? props.color : '#000')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '1')};
    letter-spacing: ${props => (props.lineHeight ? props.lineHeight : '0.02em')};
    opacity: ${props => props.opacity};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    @media screen and ${breakpoints.maxMd} {
        margin: ${props => props.marginMobile};
        ${({ fontFamilyMobile }) =>
            fontFamilyMobile === 'subheading'
                ? `font-family: var(--subheading-font-family);`
                : fontFamilyMobile === 'text'
                ? `font-family: var(--text-font-family);`
                : ''};
        font-size: ${props => (props.fontSizeMobile ? props.fontSizeMobile : '56px')};
        line-height: ${props => props.lineHeightMobile};
        font-weight: ${props => props.fontWeightMobile};
    }
`;

export const H0_5 = styled.h1`
    font-family: ${({ fontFamily }) =>
        fontFamily === 'subheading'
            ? 'var(--subheading-font-family)'
            : fontFamily === 'text'
            ? 'var(--text-font-family)'
            : 'var(--heading-font-family)'};
    font-size: ${props => (props.fontSize ? props.fontSize : 'var(--h0-5-font-size)')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'var(--heading-font-weight)')};
    font-stretch: normal;
    text-transform: ${props =>
        props.textTransform ? props.textTransform : 'var(--heading-capitalize)'};
    margin: ${props => props.margin};
    text-align: ${props => props.align};
    color: ${props => (props.color ? props.color : '#000')};
    line-height: ${props => (props.lineHeight ? props.lineHeight : '0.9')};
    letter-spacing: -0.0179em;
    opacity: ${props => props.opacity};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    @media screen and ${breakpoints.maxMd} {
        margin: ${props => props.marginMobile};
        ${({ fontFamilyMobile }) =>
            fontFamilyMobile === 'subheading'
                ? `font-family: var(--subheading-font-family);`
                : fontFamilyMobile === 'text'
                ? `font-family: var(--text-font-family);`
                : ''};
        font-size: ${props => props.fontSizeMobile};
        line-height: ${props => props.lineHeightMobile};
        font-weight: ${props => props.fontWeightMobile};
    }
`;

const h1Styles = css`
    font-family: ${({ fontFamily }) =>
        fontFamily === 'subheading'
            ? 'var(--subheading-font-family)'
            : fontFamily === 'text'
            ? 'var(--text-font-family)'
            : 'var(--heading-font-family)'};
    font-size: ${props => (props.fontSize ? props.fontSize : 'var(--h1-font-size)')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'var(--heading-font-weight)')};
    font-stretch: normal;
    letter-spacing: -0.0208em;
    line-height: ${props => (props.lineHeight ? props.lineHeight : '1.125')};
    text-transform: ${props =>
        props.textTransform ? props.textTransform : 'var(--heading-capitalize)'};
`;

export const H1 = styled.h1`
    ${h1Styles};
    margin: ${props => props.margin};
    text-align: ${props => props.align};
    color: ${props => (props.color ? props.color : '#000')};
    opacity: ${props => props.opacity};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    @media screen and ${breakpoints.maxMd} {
        margin: ${props => props.marginMobile};
        ${({ fontFamilyMobile }) =>
            fontFamilyMobile === 'subheading'
                ? `font-family: var(--subheading-font-family);`
                : fontFamilyMobile === 'text'
                ? `font-family: var(--text-font-family);`
                : ''};
        font-size: ${props => props.fontSizeMobile};
        line-height: ${props => props.lineHeightMobile};
        font-weight: ${props => props.fontWeightMobile};
    }
`;

const h2Styles = css`
    font-family: ${({ fontFamily }) =>
        fontFamily === 'subheading'
            ? 'var(--subheading-font-family)'
            : fontFamily === 'text'
            ? 'var(--text-font-family)'
            : 'var(--heading-font-family)'};
    text-align: ${props => props.align};
    font-size: ${props => (props.fontSize ? props.fontSize : 'var(--h2-font-size)')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'var(--heading-font-weight)')};
    font-stretch: normal;
    letter-spacing: -0.025em;
    line-height: ${props => (props.lineHeight ? props.lineHeight : '1.125')};
    text-transform: ${props =>
        props.textTransform ? props.textTransform : 'var(--subheading-capitalize)'};
`;

export const H2 = styled.h2`
    ${h2Styles}
    color: ${props => (props.color ? props.color : '#000')};
    margin: ${props => props.margin};
    opacity: ${props => (props.opacity ? props.opacity : 1)};
`;

const h3Styles = css`
    font-family: ${({ fontFamily }) =>
        fontFamily === 'subheading'
            ? 'var(--subheading-font-family)'
            : fontFamily === 'text'
            ? 'var(--text-font-family)'
            : 'var(--heading-font-family)'};
    text-align: ${props => props.align};
    font-size: ${props => (props.fontSize ? props.fontSize : 'var(--h3-font-size)')};
    font-weight: ${props => (props.fontWeight ? props.fontWeight : 'var(--heading-font-weight)')};
    font-stretch: normal;
    letter-spacing: -0.021em;
    line-height: ${props => (props.lineHeight ? props.lineHeight : '1.167')};
    text-transform: ${props =>
        props.textTransform ? props.textTransform : 'var(--subheading-capitalize)'};
`;

export const H3 = styled.h2`
    ${h3Styles}
    color: ${props => (props.color ? props.color : '#000')};
    margin: ${props => props.margin};
    opacity: ${props => (props.opacity ? props.opacity : 1)};
`;

const bodyTextStyles = css`
    font-family: ${({ font }) =>
        font === 'subheading'
            ? 'var(--subheading-font-family)'
            : font === 'heading'
            ? 'var(--heading-font-family)'
            : 'var(--text-font-family)'};
    font-weight: ${props =>
        props?.fontWeight
            ? props?.fontWeight
            : props?.font === 'subheading'
            ? 'var(--subheading-font-weight)'
            : props?.font === 'heading'
            ? 'var(--heading-font-weight)'
            : 'var(--text-font-weight)'};
    font-stretch: normal;
    font-style: normal;
`;

export const BodyText = styled.p`
    ${bodyTextStyles}
    margin: ${props => props.margin};
    color: ${props => (props.color ? props.color : props.invert ? '#fff' : '#000')};
    font-size: ${props => (props.fontSize ? props.fontSize : '16px')};
    text-align: ${props => props.align};
    line-height: ${props => (props.lineHeight ? props.lineHeight : 1.5)};
    opacity: ${props => props.opacity};
    letter-spacing: ${props => props.letterSpacing};
    max-width: ${props => props.maxWidth};
    ${props =>
        props.link
            ? `
    text-decoration: underline;
    cursor: pointer;
    `
            : null};
    @media screen and ${breakpoints.maxMd} {
        margin: ${props => props.marginMobile};
        font-size: ${props => props.fontSizeMobile};
    }
    a {
        text-decoration: underline;
    }
`;

export const BodyTextAlt = styled.p`
    font-family: ${({ font }) =>
        font === 'text'
            ? 'var(--subheading-font-family)'
            : font === 'heading'
            ? 'var(--heading-font-family)'
            : 'var(--text-font-family)'};
    margin: ${props => props.margin};
    text-align: ${props => props.align};
    color: #000;
    color: ${props => props.color};
    line-height: normal;
    line-height: ${props => props.lineHeight};
    /* opacity: 1; */
    opacity: ${props => props.opacity};
    font-size: 12px;
    font-size: ${props => props.fontSize};
    font-weight: ${({ font }) =>
        font === 'subheading'
            ? 'var(--subheading-font-weight)'
            : font === 'heading'
            ? 'var(--heading-font-weight)'
            : 'var(--text-font-weight)'};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
`;

export const DateText = styled.p`
    font-family: ${({ font }) =>
        font === 'subheading'
            ? 'var(--subheading-font-family)'
            : font === 'heading'
            ? 'var(--heading-font-family)'
            : 'var(--text-font-family)'};
    margin: ${props => props.margin};
    text-align: ${props => props.align};
    color: #7f7f7f;
    color: ${props => props.color};
    line-height: normal;
    line-height: ${props => props.lineHeight};
    opacity: 1;
    opacity: ${props => props.opacity};
    font-size: 12px;
    font-size: ${props => props.fontSize};
    font-weight: ${({ font }) =>
        font === 'subheading'
            ? 'var(--subheading-font-weight)'
            : font === 'heading'
            ? 'var(--heading-font-weight)'
            : 'var(--text-font-weight)'};
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 2px;
    text-transform: uppercase;
`;

const Typography = ({ type, label, children, fontFamily, ...props }) => {
    if (type === 'h1') {
        return (
            <H1 fontFamily={fontFamily || 'heading'} {...props}>
                {label ? label : null}
                {children}
            </H1>
        );
    }
    if (type === 'h2') {
        return (
            <H2 fontFamily={fontFamily || 'heading'} {...props}>
                {label ? label : null}
                {children}
            </H2>
        );
    }
    if (type === 'body') {
        return (
            <BodyText fontFamily={fontFamily || 'text'} {...props}>
                {label ? label : null}
                {children}
            </BodyText>
        );
    }
    if (type === 'alt') {
        return (
            <BodyTextAlt fontFamily={fontFamily || 'text'} {...props}>
                {label ? label : null}
                {children}
            </BodyTextAlt>
        );
    }
};

export const Rte = styled.div`
    color: ${({ color }) => (color ? color : '#000')};
    opacity: ${props => props.opacity};
    h1 {
        ${h1Styles};
        line-height: 1;
        margin-top: 150px;
        margin-bottom: 30px;
    }
    h2 {
        ${h2Styles}
        line-height: 1.17;
        margin-top: 65px;
        margin-bottom: 35px;
    }
    h3 {
        ${bodyTextStyles}
        font-weight: var(--heading-font-weight);
    }
    p {
        font-size: 16px;
        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }
    :is(ul, ol):not(:last-child) {
        margin-bottom: 30px;
    }
    ul {
        padding-left: 25px;
        line-height: 1.75;
        li {
            display: flex;
            &:before {
                display: block;
                content: '•';
                width: 5px;
                margin-right: 5px;
            }
        }
    }
    p + ul,
    p + ol {
        margin-top: -25px;
    }
    ol {
        list-style: decimal;
        padding-left: 14px;
        li:not(:last-child) {
            margin-block-end: 25px;
        }
    }
    a {
        opacity: 0.5;
        text-decoration: underline;
        &:hover {
            opacity: 1;
        }
    }
`;

export default Typography;

Typography.propTypes = {
    type: PropTypes.oneOf(['h1', 'h2', 'alt', 'body', 'date']),
    label: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    fontFamily: PropTypes.oneOf(['heading', 'subheading', 'text']),
};

Typography.defaultProps = {
    type: 'body',
};
