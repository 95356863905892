import { storyblokEditable } from '@storyblok/react';
import PropTypes from 'prop-types';
import { useMemo, useRef } from 'react';
import ReactPlayer from 'react-player';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
    100% {
        transform: translateX(100%);
    }
`;

const VideoWrapper = styled.div`
    position: relative;
    padding-top: 56.25%;
    z-index: 1;
    .skeleton-box {
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background-color: #e2e8f0;
        &:before {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(255, 255, 255, 0) 0,
                rgba(255, 255, 255, 0.2) 20%,
                rgba(255, 255, 255, 0.5) 60%,
                rgba(255, 255, 255, 0)
            );
            -webkit-animation: ${shimmer} 3s infinite;
            animation: ${shimmer} 3s infinite;
            content: '';
        }
    }
    .react-player {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
`;

const Video = ({ blok }) => {
    const data = useMemo(() => {
        const { url, poster } = { ...blok };
        return {
            url,
            poster: poster?.filename ? poster.filename : true,
        };
    }, [blok]);
    const playerRef = useRef(null);

    const playVideo = () => {
        playerRef.current.props.playing = true;
    };
    return (
        data.url && (
            <section className="overflow-hidden" {...storyblokEditable(blok)}>
                <div className="container">
                    <VideoWrapper>
                        <ReactPlayer
                            ref={playerRef}
                            className="react-player"
                            controls={true}
                            light={data.poster}
                            url={data.url}
                            onClickPreview={playVideo}
                            width="100%"
                            height="100%"
                        />
                        <span className="skeleton-box"></span>
                    </VideoWrapper>
                </div>
            </section>
        )
    );
};

Video.defaultProps = {
    blok: {},
};

Video.propTypes = {
    blok: PropTypes.object,
};

export default Video;
