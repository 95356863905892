import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--iso"
                width="200"
                viewBox="0 0 188 200"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="38.5,52.9 17.4,163.2 63,170.3 " />
                <polygon points="112.5,60.9 87.3,174.3 136.7,183.5 " />
                <polygon points="25.9,45.5 5.4,172.6 152.5,198.6 125.8,56.3 " />
                <polyline points="48.8,102.1 40.9,144.8 58.3,147.5 " />
                <line x1="17.4" y1="163.2" x2="40.9" y2="144.8" />
                <polyline points="119.8,97.9 107.7,155 132,159.6 " />
                <line x1="87.3" y1="174.3" x2="107.7" y2="155" />
                <polygon points="152.5,198.6 125.8,56.3 148.4,58.5 168.7,177.5 " />
                <polygon points="1.4,30.4 29.7,17.2 170.1,31.6 150.9,45.5 " />
                <polyline points="170.1,31.6 170.1,42.4 150.9,58.8 1.2,42.9 1.4,30.4 " />
                <polyline points="30.8,1.4 30.3,6.4 29.7,11.4 " />
                <polyline points="172.9,14.4 172.4,19.4 171.8,24.4 " />
                <line x1="30.3" y1="6.4" x2="172.4" y2="19.4" />
                <polyline points="176.6,30.3 181.6,30.3 186.6,30.3 " />
                <polyline points="176.8,177.6 181.8,177.6 186.8,177.6 " />
                <line x1="181.8" y1="177.6" x2="181.6" y2="30.3" />
                <line x1="150.9" y1="45.5" x2="150.9" y2="58.8" />
                <polyline points="49.9,48 74.9,167.1 101.8,53.6 " />
                <line x1="75.8" y1="50.9" x2="88.4" y2="110.1" />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
