import PropTypes from 'prop-types';

const Print = ({ fill, className, ...attrs }) => {
    return (
        <svg
            className={`icon icon--print ${className}`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M2.201 3.602L5.605 3.602M2.201 1.801L5.605 1.801M7.806 5.404c0 .332-.268.6-.6.6H.6c-.331 0-.6-.268-.6-.6V0h7.806v5.404z"
                        transform="translate(-992 -2320) translate(380 2308) translate(600) translate(12 12) translate(1.094 .5) translate(3.002 9)"
                    />
                    <path
                        d="M3.002 10.202H1.201C.539 10.2.002 9.664 0 9.002V5.397c.002-.662.539-1.199 1.201-1.2h11.41c.662.001 1.198.538 1.2 1.2v3.603c-.002.663-.538 1.2-1.2 1.201h-1.802"
                        transform="translate(-992 -2320) translate(380 2308) translate(600) translate(12 12) translate(1.094 .5)"
                    />
                    <path
                        d="M0 2.403V0h5.756c.16 0 .312.063.425.176l1.45 1.45c.112.112.175.264.175.424v.352"
                        transform="translate(-992 -2320) translate(380 2308) translate(600) translate(12 12) translate(1.094 .5) translate(3.002)"
                    />
                    <path
                        d="M5.404 0L5.404 2.402 7.806 2.402"
                        transform="translate(-992 -2320) translate(380 2308) translate(600) translate(12 12) translate(1.094 .5) translate(3.002)"
                    />
                    <path
                        d="M1.406 6.5c0 .552.447 1 1 1 .552 0 1-.448 1-1s-.448-1-1-1c-.553 0-1 .448-1 1z"
                        transform="translate(-992 -2320) translate(380 2308) translate(600) translate(12 12) translate(1.094 .5)"
                    />
                </g>
            </g>
        </svg>
    );
};

Print.defaultProps = {
    fill: 'currentColor',
    className: '',
};

Print.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default Print;
