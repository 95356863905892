import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'components/atoms/Link';
import ReactToPrint from 'react-to-print';
import { render } from 'storyblok-rich-text-react-renderer';

import { newLineToBr } from 'helpers';

import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';

import { H2, Rte } from 'components/atoms/Typography';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import { PlusIcon, QuotesIcon, PrintIcon } from 'components/atoms/Icons';
import { storyblokEditable } from '@storyblok/react';

const ImageWithText = ({ blok }) => {
    const { blocks } = blok;

    const sectionRef = useRef();

    return (
        <div ref={sectionRef} id={blok.id} className="mt-16 mb-24" {...storyblokEditable(blok)}>
            {blocks.map(({ _uid, ...block }) => (
                <Block key={_uid} block={block} sectionRef={sectionRef} />
            ))}
        </div>
    );
};

ImageWithText.defaultProps = {
    blok: {},
};

ImageWithText.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

const MigratedText = styled.div`
    p,
    span,
    img {
        display: block;
        margin-bottom: 30px;
        overflow-wrap: break-word;
    }
    img {
        max-width: 100%;
    }
    h2 {
        font-family: Barlow;
        font-size: 24px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.5px;
        margin: 20px 0;
    }
    @media screen and ${breakpoints.maxSm} {
        width: calc(100vw - 60px);
    }
`;

const Block = ({ sectionRef, block: { component, ...block } }) => {
    if (component === 'section_heading') {
        return <Header sectionRef={sectionRef} block={block} {...storyblokEditable(block)} />;
    }
    if (component === 'section_content') {
        return (
            <div
                className={`container ${
                    block.layout === 'narrow'
                        ? 'grid sm:gap-x-20x sm:grid-cols-6 lg:gap-x-40px lg:grid-cols-12'
                        : ''
                }`}
                {...storyblokEditable(block)}
            >
                <div
                    className={`${
                        block.layout === 'narrow' ? 'w-full col-span-6 lg:col-start-4' : ''
                    }`}
                >
                    {block.isMigration ? (
                        typeof block?.text === 'object' ? (
                            <MigratedText
                                dangerouslySetInnerHTML={{
                                    __html: block?.text?.content?.[0]?.content?.[0]?.text,
                                }}
                            />
                        ) : (
                            <MigratedText
                                dangerouslySetInnerHTML={{
                                    __html: block?.text,
                                }}
                            />
                        )
                    ) : (
                        <Rte>{render(block?.text)}</Rte>
                    )}
                    {block?.button?.length > 0 && (
                        <Link href={block.button[0].button_url || '#'}>
                            <Button
                                as="span"
                                className="mt-10 mb-16"
                                type="primary"
                                size="large"
                                icon={<PlusIcon fill="currentColor" />}
                                label={block.button[0]?.button_text}
                                width="330px"
                            />
                        </Link>
                    )}
                </div>
            </div>
        );
    }
    if (component === 'section_image') {
        return block?.image[0]?.image?.filename && block?.image[0]?.image?.filename !== '' ? (
            <SectionImage block={block} />
        ) : null;
    }
    if (component === 'section_quote') {
        return block?.text ? (
            <div className="container my-28" {...storyblokEditable(block)}>
                <div className="grid gap-x-40px grid-cols-6 lg:grid-cols-12">
                    <div className="col-span-6 lg:col-span-1 lg:col-start-3">
                        <div>
                            <QuotesIcon fill="currentColor" />
                        </div>
                    </div>
                    <div className="col-span-6 lg:col-span-6">
                        <H2 className="max-w-md w-full" lineHeight="1.5">
                            {block.text}
                        </H2>
                    </div>
                </div>
            </div>
        ) : null;
    }
    return null;
};

Block.propTypes = {
    sectionRef: PropTypes.object,
    block: PropTypes.object,
};

const Header = ({ sectionRef, block }) => {
    if (block.layout === 'regular') {
        return (
            <div className="container mb-5 grid sm:gap-x-20x sm:grid-cols-6 lg:gap-x-40px lg:grid-cols-12 items-end">
                {block?.heading && (
                    <H2
                        className="col-span-3 mb-5"
                        dangerouslySetInnerHTML={{
                            __html: newLineToBr({ string: block.heading }),
                        }}
                    />
                )}
                {block?.subheading && (
                    <Rte
                        className={`col-span-6 mb-5 ${
                            block.layout === 'narrow' ? 'order-last' : 'order-last lg:order-none '
                        }`}
                    >
                        {render(block.subheading)}
                    </Rte>
                )}
                {block?.add_print_button && (
                    <ReactToPrint
                        trigger={() => {
                            // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                            // to the root node of the returned component as it will be overwritten.
                            return (
                                <div className={`col-span-3`}>
                                    <Button
                                        className="w-10 h-10 print:hidden mb-5"
                                        type="icon"
                                        icon={<PrintIcon fill="currentColor" />}
                                    />
                                </div>
                            );
                        }}
                        content={() => sectionRef.current}
                    />
                )}
            </div>
        );
    }
    if (block.layout === 'narrow') {
        return (
            <div className="container mb-5 grid sm:gap-x-20x sm:grid-cols-6 lg:gap-x-40px lg:grid-cols-12">
                <div className="col-span-6 lg:col-start-4 flex flex-wrap">
                    {block?.heading && (
                        <H2
                            className="flex-1 mb-5"
                            dangerouslySetInnerHTML={{
                                __html: newLineToBr({ string: block.heading }),
                            }}
                        />
                    )}
                    {block?.add_print_button && (
                        <ReactToPrint
                            trigger={() => {
                                // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                                // to the root node of the returned component as it will be overwritten.
                                return (
                                    <div className={`col-span-3`}>
                                        <Button
                                            className="w-10 h-10 print:hidden mb-5"
                                            type="icon"
                                            icon={<PrintIcon fill="currentColor" />}
                                        />
                                    </div>
                                );
                            }}
                            content={() => sectionRef.current}
                        />
                    )}
                    {block?.subheading && (
                        <Rte className={`min-w-full mb-5`}>{render(block.subheading)}</Rte>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

Header.propTypes = {
    sectionRef: PropTypes.object,
    block: PropTypes.object,
};

const SectionImage = ({ block }) => {
    if (block.content_position === 'right') {
        return (
            <figure
                className={`${
                    block.layout === 'narrow'
                        ? 'container grid sm:gap-x-20x sm:grid-cols-6 lg:gap-x-40px lg:grid-cols-12 my-5'
                        : block.layout === 'regular'
                        ? 'container my-5'
                        : 'mt-5 mb-20'
                }`}
                {...storyblokEditable(block)}
            >
                <div
                    className={`flex flex-wrap -mx-xxs relative ${
                        block.layout === 'narrow' ? 'col-span-6 lg:col-start-4' : ''
                    }`}
                >
                    <Image
                        className={`px-xxs ${
                            block?.caption !== '' || block?.button?.length > 0
                                ? 'lg:w-7/12'
                                : 'flex-1'
                        }`}
                        src={block.image[0].image.filename}
                        alt={block.image[0].image.alt || ''}
                    />
                    {(block?.caption !== '' || block?.button?.length > 0) && (
                        <div className="px-xxs lg:w-5/12 flex flex-col justify-between">
                            {block?.caption !== '' && (
                                <figcaption className="opacity-50 mt-4 lg:mt-0">
                                    {block.caption}
                                </figcaption>
                            )}
                            {block?.button?.length > 0 && (
                                <Link href={block.button[0].button_url || '#'}>
                                    <Button
                                        as="span"
                                        className="w-full mt-10"
                                        type="primary"
                                        size="large"
                                        icon={<PlusIcon fill="currentColor" />}
                                        label={block.button[0]?.button_text}
                                    />
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </figure>
        );
    }
    return (
        <figure
            className={`${
                block.layout === 'narrow'
                    ? 'container grid sm:gap-x-20x sm:grid-cols-6 lg:gap-x-40px lg:grid-cols-12 my-5'
                    : block.layout === 'regular'
                    ? 'container my-5'
                    : 'mt-5 mb-20'
            }`}
            {...storyblokEditable(block)}
        >
            <div
                className={`relative ${
                    block.layout === 'narrow' ? 'col-span-6 lg:col-start-4' : ''
                }`}
            >
                <Image
                    className="w-full"
                    src={block.image[0].image.filename}
                    dimension={block.image[0].dimension}
                    alt={block.image[0].image.alt || ''}
                />
                {block?.caption !== '' && (
                    <figcaption className="opacity-50 mt-4">{block.caption}</figcaption>
                )}
                {block?.button?.length > 0 && (
                    <Link href={block.button[0].button_url || '#'}>
                        <Button
                            as="span"
                            className="mt-10"
                            type="primary"
                            size="large"
                            icon={<PlusIcon fill="currentColor" />}
                            label={block.button[0]?.button_text}
                            width="330px"
                        />
                    </Link>
                )}
            </div>
        </figure>
    );
};

SectionImage.propTypes = {
    block: PropTypes.object,
};

export default ImageWithText;
