import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--instagram" width="18" height="18" viewBox="0 0 24 24" {...attrs}>
            <path
                d="M12.0002 9.13624C10.4232 9.13624 9.13629 10.4232 9.13629 12.0001C9.13629 13.5771 10.4232 14.864 12.0002 14.864C13.5771 14.864 14.8641 13.5771 14.8641 12.0001C14.8641 10.4232 13.5771 9.13624 12.0002 9.13624ZM20.5897 12.0001C20.5897 10.8142 20.6005 9.63898 20.5339 8.45518C20.4673 7.08016 20.1536 5.85984 19.1481 4.85436C18.1405 3.84674 16.9223 3.53521 15.5473 3.46861C14.3613 3.40201 13.1861 3.41275 12.0023 3.41275C10.8164 3.41275 9.64117 3.40201 8.45738 3.46861C7.08236 3.53521 5.86204 3.84889 4.85656 4.85436C3.84893 5.86199 3.53741 7.08016 3.47081 8.45518C3.4042 9.64113 3.41495 10.8163 3.41495 12.0001C3.41495 13.1839 3.4042 14.3613 3.47081 15.5451C3.53741 16.9201 3.85108 18.1404 4.85656 19.1459C5.86419 20.1535 7.08236 20.4651 8.45738 20.5317C9.64332 20.5983 10.8185 20.5875 12.0023 20.5875C13.1883 20.5875 14.3635 20.5983 15.5473 20.5317C16.9223 20.4651 18.1426 20.1514 19.1481 19.1459C20.1557 18.1383 20.4673 16.9201 20.5339 15.5451C20.6026 14.3613 20.5897 13.1861 20.5897 12.0001ZM12.0002 16.4066C9.56168 16.4066 7.59369 14.4386 7.59369 12.0001C7.59369 9.56163 9.56168 7.59365 12.0002 7.59365C14.4387 7.59365 16.4067 9.56163 16.4067 12.0001C16.4067 14.4386 14.4387 16.4066 12.0002 16.4066ZM16.5871 8.44229C16.0178 8.44229 15.558 7.98252 15.558 7.41318C15.558 6.84383 16.0178 6.38406 16.5871 6.38406C17.1565 6.38406 17.6162 6.84383 17.6162 7.41318C17.6164 7.54837 17.5899 7.68227 17.5383 7.8072C17.4866 7.93213 17.4108 8.04565 17.3152 8.14125C17.2196 8.23684 17.1061 8.31264 16.9812 8.3643C16.8562 8.41595 16.7223 8.44246 16.5871 8.44229Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
