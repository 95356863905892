import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Client from 'shopify-buy';
import SiteContext from './SiteContext';
import { SHOP_NAME, STOREFRONT_ACCESS_TOKEN } from 'helpers/constants/urls';
import { handleTradeDiscountValue } from 'helpers/trade';
import { QueryClient, QueryClientProvider } from 'react-query';
import Cookies from 'js-cookie';
import { customerAccessTokenDelete, getShopifyCustomer } from 'helpers/requests/customer-requests';
import {
    arraysHaveMatchingObjects,
    getLineItemSampleType,
    isSampleProduct,
    roundToTwo,
} from 'helpers';
import { logError, logInfo } from 'helpers/logging';
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router';
import { APPS } from 'helpers/constants/general';
import {
    transformLineItemsToElevarProducts,
    transformToElevarUserProperties,
} from 'helpers/elevar';
import { transformLineItemsToAlgoliaObjectData } from 'helpers/algolia';
import aa from 'search-insights';

const client = Client.buildClient({
    storefrontAccessToken: STOREFRONT_ACCESS_TOKEN,
    domain: `${SHOP_NAME}.myshopify.com`,
});

const initialStoreState = {
    client,
    adding: false,
    checkoutCreated: false,
    checkout: {
        lineItems: [],
        appliedDiscount: '',
        shippingAddress: {},
    },
    tradeDiscountValue: null,
    addressModalIsOpen: false,
    activeAddressId: null,
    sidebarMenuOpen: false,
    sidebarCartOpen: false,
    customerNameModalIsOpen: false,
    customerAddresses: [],
    customerDefaultAddress: null,
    notificationBar: {
        isOpen: false,
        content: null,
    },
    searchModalIsOpen: false,
    loginModalIsOpen: false,
    registerModalIsOpen: false,
    forgotPwModalIsOpen: false,
    resetModalIsOpen: false,
    projectsModalOpen: false,
    activeProjectModalStep: 1,
    customer: null,
    customerLoggedIn: false,
    customerIsTaxExempt: false,
    customerIsPendingTradeAccount: false,
    cartType: null /* standard or sample */,
    cartTypeErrorModalOpen: false,
    afterLoginUrl: '/account/dashboard',
    isLoginModalOpenOnLoad: false,
    userWayWidget: {
        visibility: 'HIDDEN',
        scriptLoaded: false,
    },
    dataLayerList: null,
};
const SiteProvider = ({ children, settings }) => {
    const defaultStoreState = {
        ...initialStoreState,
        settings,
    };
    const [store, updateStore] = useState(defaultStoreState);
    const customerAccessToken = Cookies.get('ZIA_SESS');
    const { enqueueSnackbar } = useSnackbar();
    const router = useRouter();

    const handleModalOpen = form => {
        document.querySelector('body').classList.add('scrollLock');

        if (window.innerWidth > 767) {
            document.querySelector('body').classList.add('offsetHeader');
            const formModalHeight = document.querySelector(`#${form}-modal`).clientHeight;
            document.querySelector('body').style.marginTop = `${formModalHeight}px`;
            document.documentElement.style.setProperty(
                '--header-top-offset',
                formModalHeight + 'px'
            );

            /* if (form === 'search') {
                // Cancel body margin top with .aa-panel negative margin top
                const styleSheet = document.createElement('style');
                styleSheet.setAttribute('id', 'aaPanelMod');
                styleSheet.innerText = `.aa-Panel--searchModal{margin-top:-${
                    formModalHeight - 8
                }px;}`;
                document.head.appendChild(styleSheet);
            } */
        }
    };
    const handleModalClose = () => {
        document.querySelector('body').classList.remove('scrollLock');
        if (window.innerWidth > 767) {
            document.querySelector('body').classList.remove('offsetHeader');
            document.querySelector('body').style.marginTop = '0';
            /* document.getElementById('aaPanelMod')?.remove(); */
            document.documentElement.style.setProperty('--header-top-offset', 0);
        }
    };

    /**
     * Updates the customer information in the store.
     *
     * @param {Object} customer - The customer object.
     * @param {string} customer.id - The unique identifier for the customer. (required)
     * @param {Array<string>} customer.tags - The tags associated with the customer. (required)
     * @param {Object} [customer.rest] - Any additional properties of the customer.
     */
    const setCustomer = customer => {
        updateStore(store => {
            return {
                ...store,
                customer: {
                    ...store.customer,
                    ...customer,
                },
                customerIsTaxExempt: !!customer?.tags?.includes('TAX-EXEMPT'),
                customerIsPendingTradeAccount: !!customer?.tags.includes('TR-PENDING'),
            };
        });
    };
    useEffect(() => {
        const initializeCheckout = async () => {
            // Check for an existing cart.
            const isBrowser = typeof window !== 'undefined';
            const existingCheckoutID = isBrowser
                ? localStorage.getItem('ziatile_shopify_checkout_id')
                : null;

            const setCheckoutInState = checkout => {
                if (isBrowser) {
                    localStorage.setItem('ziatile_shopify_checkout_id', checkout.id);
                }

                updateStore(prevState => {
                    return {
                        ...prevState,
                        checkoutCreated: true,
                        checkout: {
                            ...checkout,
                            lineItems: checkout.lineItems.reverse(),
                        },
                    };
                });
            };

            const createNewCheckout = () => store.client.checkout.create();
            const fetchCheckout = id => store.client.checkout.fetch(id);

            if (existingCheckoutID) {
                try {
                    const checkout = await fetchCheckout(existingCheckoutID);
                    // Make sure this cart hasn’t already been purchased.
                    if (!checkout.completedAt) {
                        setCheckoutInState(checkout);
                        return;
                    }
                } catch (e) {
                    localStorage.setItem('ziatile_shopify_checkout_id', null);
                }
            }

            const newCheckout = await createNewCheckout();
            setCheckoutInState(newCheckout);
        };

        initializeCheckout();
    }, [store.client.checkout]);

    // Handle Customer Discount based on Customer Tag
    useEffect(() => {
        (async () => {
            const tradeDiscountValue = await handleTradeDiscountValue();
            updateStore(store => ({
                ...store,
                tradeDiscountValue,
            }));
        })();
    }, []);

    // Creat a client
    const queryClient = new QueryClient();

    useEffect(() => {
        (async () => {
            if (Cookies.get('ZIA_SESS')) {
                const { data } = await getShopifyCustomer(Cookies.get('ZIA_SESS'));
                const customer = await data?.customer;
                if (!customer) {
                    window.customer = {
                        id: null,
                        email: null,
                    };
                    Cookies.remove('ZIA_SESS');
                    Cookies.remove('ZIA_USER');
                    localStorage.removeItem('ziatile_shopify_checkout_id');
                    return;
                }
                window.customer = {
                    id: customer.id,
                    email: customer.email,
                };
                // Get a unique, pseudonymous identifier for signed-in users
                const authenticatedUserToken = Cookies.get('ZIA_SESS');
                aa('setAuthenticatedUserToken', authenticatedUserToken);

                updateStore(prevState => ({
                    ...prevState,
                    customer,
                    customerLoggedIn: !!customer.id,
                    customerIsTaxExempt: !!customer?.tags?.includes('TAX-EXEMPT'),
                    customerIsPendingTradeAccount: !!customer?.tags?.includes('TR-PENDING'),
                }));
                return;
            }

            updateStore(prevState => ({
                ...prevState,
                customerLoggedIn: false,
                tradeDiscountValue: null,
            }));
        })();
    }, [customerAccessToken]);

    // Set cartType
    useEffect(() => {
        updateStore(store => ({
            ...store,
            cartType: store?.checkout?.lineItems?.[0]
                ? getLineItemSampleType(store.checkout.lineItems[0]) === 'non-sample'
                    ? 'standard'
                    : 'sample'
                : null,
        }));
    }, [store.checkout.lineItems]);

    // Prevent empty algolia search request
    useEffect(() => {
        const handleEmptyAlgoliaSearchEnter = event => {
            const selectors = ['input.aa-Input'];

            let target = event.target;

            // null
            if (!target) {
                return;
            }

            // if target matches selectors we have what we looking for.
            if (!target.matches(selectors)) {
                // if not keep looking
                target = target.closest(selectors);
            }

            // null
            if (!target) {
                return;
            }

            if (target.matches(selectors)) {
                if (!target?.value?.length && event.keyCode === 13) {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                }
            }
        };
        const handleEmptyAlgoliaSearchClick = event => {
            const selectors = ['button.aa-SubmitButton'];

            let target = event.target;

            // null
            if (!target) {
                return;
            }

            // if target matches selectors we have what we looking for.
            if (!target.matches(selectors)) {
                // if not keep looking
                target = target.closest(selectors);
            }

            // null
            if (!target) {
                return;
            }

            if (target.matches(selectors)) {
                const $input = target.closest('form.aa-Form')?.querySelector('input.aa-Input');
                if (!$input?.value?.length) {
                    event.preventDefault();
                    event.stopPropagation();
                    event.stopImmediatePropagation();
                }
            }
        };

        document.addEventListener('keydown', handleEmptyAlgoliaSearchEnter);
        document.addEventListener('click', handleEmptyAlgoliaSearchClick);

        return () => {
            document.removeEventListener('keydown', handleEmptyAlgoliaSearchEnter);
            document.removeEventListener('click', handleEmptyAlgoliaSearchClick);
        };
    }, []);

    useEffect(() => {
        updateStore(prevState => ({ ...prevState, settings }));
    }, [settings]);

    /**
     * Notify Elevar any navigation changes witout a reload via a utility
     */
    useEffect(() => {
        const handleRouteChange = () => {
            window.ElevarInvalidateContext?.();
        };

        router.events.on('routeChangeComplete', handleRouteChange);

        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    const handleLogout = async () => {
        const customerAccessToken = Cookies.get('ZIA_SESS');

        if (customerAccessToken || customerAccessToken !== null) {
            try {
                const { userErrors } = await customerAccessTokenDelete(customerAccessToken);
                if (userErrors?.length) {
                    throw new Error(userErrors[0].message);
                }
            } catch (error) {
                logError(`Error logout`, APPS.FRONTEND, error);
                return;
            }
        }

        window.customer = {
            id: null,
            email: null,
        };
        Cookies.remove('ZIA_SESS');
        Cookies.remove('ZIA_USER');
        localStorage.removeItem('ziatile_shopify_checkout_id');
        router.push('/');
    };

    return (
        // Provide the client to app
        <QueryClientProvider client={queryClient}>
            <SiteContext.Provider
                value={{
                    store,
                    getUpdatedCheckout: async () => {
                        const newCheckout = await store.client.checkout.fetch(store.checkout.id);
                        updateStore(prevState => {
                            return {
                                ...prevState,
                                checkout: {
                                    ...newCheckout,
                                    lineItems: newCheckout.lineItems.reverse(),
                                },
                            };
                        });
                        return newCheckout;
                    },
                    updateCheckoutWithAbandonedCart: (lineItemsToUpdate, id) => {
                        updateStore(prevState => {
                            return { ...prevState, adding: true };
                        });
                        return client.checkout
                            .updateLineItems(id, lineItemsToUpdate)
                            .then(checkout => {
                                updateStore(prevState => {
                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...checkout,
                                            lineItems: checkout.lineItems.reverse(),
                                        },
                                        adding: false,
                                    };
                                });
                            })
                            .catch(err => console.error(err));
                    },
                    setAddressModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('address');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            addressModalIsOpen: bool,
                            ...(!bool && { activeAddressId: null }),
                        }));
                    },
                    setActiveAddressId: addressId => {
                        updateStore(store => ({
                            ...store,
                            activeAddressId: addressId,
                        }));
                    },
                    setCustomerAddresses: addresses => {
                        updateStore(store => ({
                            ...store,
                            customerAddresses: addresses,
                        }));
                    },
                    onUpdateCustomerAddress: (addressId, address) => {
                        updateStore(store => ({
                            ...store,
                            customerAddresses: store.customerAddresses?.map(customerAddress => ({
                                ...(addressId === customerAddress.id
                                    ? { ...address }
                                    : { ...customerAddress }),
                            })),
                        }));
                    },
                    addCustomerAddress: address => {
                        updateStore(store => ({
                            ...store,
                            customerAddresses: [...store.customerAddresses, address],
                        }));
                    },
                    setCustomerDefaultAddress: async addressId => {
                        updateStore(store => ({
                            ...store,
                            customerDefaultAddress: store.customerAddresses.find(
                                ({ id }) => addressId === id
                            ),
                        }));
                    },
                    removeCustomerAddress: async addressId => {
                        updateStore(store => ({
                            ...store,
                            customerAddresses: store.customerAddresses.filter(
                                ({ id }) => addressId !== id
                            ),
                            ...(store.customerAddresses.length > 0 &&
                                store.customerDefaultAddress.id === addressId && {
                                    customerDefaultAddress: store.customerAddresses.findLast(
                                        ({ id }) => id !== addressId
                                    ),
                                }),
                        }));
                    },
                    setSidebarMenuOpen: bool => {
                        if (bool) {
                            document.querySelector('body').classList.add('sidebarMenuOpen');
                        } else {
                            document.querySelector('body').classList.remove('sidebarMenuOpen');
                        }
                        updateStore(store => ({
                            ...store,
                            sidebarMenuOpen: bool,
                        }));
                    },
                    setSidebarCartOpen: bool => {
                        updateStore(store => ({
                            ...store,
                            sidebarCartOpen: bool,
                        }));
                    },
                    /**
                     * TODO: Refactor addVariantToCart and make it more readable
                     */
                    /**
                     * Add a product variant to the shopping cart.
                     *
                     * @param {string} variantId - The ID of the product variant to add to the cart.
                     * @param {number} quantity - The quantity of the product variant to add.
                     * @param {Array} customAttributes - An array of custom attributes associated with the product variant.
                     */
                    addVariantToCart: async (variantId, quantity, customAttributes) => {
                        // Check if variantId is empty or quantity is missing
                        if (variantId === '' || !quantity) {
                            return;
                        }

                        const tradeDiscountValue = await handleTradeDiscountValue();

                        updateStore(store => ({
                            ...store,
                            tradeDiscountValue,
                        }));

                        /**
                         * When a customer adds items to their cart, we include the UTM parameters as metadata for the cart.
                         * This can be done by adding the UTM parameters as custom attributes to the line items in the cart.
                         */
                        const utmSource = Cookies.get('utm_source');
                        const utmMedium = Cookies.get('utm_medium');
                        const utmCampaign = Cookies.get('utm_campaign');
                        const utmTerm = Cookies.get('utm_term');
                        const utmContent = Cookies.get('utm_content');
                        const eventSourceUrl = Cookies.get('event_source_url');
                        const eventTime = Cookies.get('event_time');

                        if (utmSource && !customAttributes?.find(({ key }) => key === 'utm_source'))
                            customAttributes.push({ key: '__utm_source', value: utmSource });
                        if (utmMedium && !customAttributes?.find(({ key }) => key === 'utm_medium'))
                            customAttributes.push({ key: '__utm_medium', value: utmMedium });
                        if (
                            utmCampaign &&
                            !customAttributes?.find(({ key }) => key === 'utm_campaign')
                        )
                            customAttributes.push({ key: '__utm_campaign', value: utmCampaign });
                        if (utmTerm && !customAttributes?.find(({ key }) => key === 'utm_term'))
                            customAttributes.push({ key: '__utm_term', value: utmTerm });
                        if (
                            utmContent &&
                            !customAttributes?.find(({ key }) => key === 'utm_content')
                        )
                            customAttributes.push({ key: '__utm_content', value: utmContent });
                        if (
                            eventSourceUrl &&
                            !customAttributes?.find(({ key }) => key === 'event_source_url')
                        )
                            customAttributes.push({
                                key: '__event_source_url',
                                value: eventSourceUrl,
                            });
                        if (eventTime && !customAttributes?.find(({ key }) => key === 'event_time'))
                            customAttributes.push({ key: '__event_time', value: eventTime });

                        // Retrieve the current checkout and client from the store
                        const { checkout, client } = store;
                        const checkoutId = checkout.id;

                        // Filter line items in the cart to find those with the same variantId
                        const lineItemsByVariantId = checkout.lineItems.filter(
                            ({ variant }) => variantId === variant.id
                        );

                        // Extract custom attributes related to boxes, area, and linear feet
                        const additionalBoxes = customAttributes?.find(
                            ({ key }) => key === 'Boxes'
                        )?.value;
                        const additionalArea = customAttributes?.find(
                            ({ key }) => key === 'Total ft²'
                        )?.value;
                        const additionalLinearFeet = customAttributes?.find(
                            ({ key }) => key === 'Total linear feet'
                        )?.value;

                        // Check if variant is already in cart and customAttributes has 'Boxes'
                        if (lineItemsByVariantId && additionalBoxes) {
                            // Filter line items with 'Boxes' customAttributes
                            const lineItemsByVariantIdWithBoxes = lineItemsByVariantId?.filter(
                                ({ customAttributes }) =>
                                    customAttributes?.find(({ key }) => key === 'Boxes')
                            );

                            // Find a matching line item with customAttributes excluding 'Boxes', 'Total ft²', and 'Total linear feet'
                            const lineItem = lineItemsByVariantIdWithBoxes?.find(lineItem =>
                                arraysHaveMatchingObjects(
                                    lineItem.customAttributes
                                        .filter(
                                            ({ key }) =>
                                                key !== 'Boxes' &&
                                                key !== 'Total ft²' &&
                                                key !== 'Total linear feet'
                                        )
                                        .map(({ key, value }) => ({ key, value })),
                                    customAttributes.filter(
                                        ({ key }) =>
                                            key !== 'Boxes' &&
                                            key !== 'Total ft²' &&
                                            key !== 'Total linear feet'
                                    )
                                )
                            );

                            // If a matching line item is found, update its quantity and customAttributes
                            if (lineItem) {
                                const lineItemsToUpdate = [
                                    {
                                        id: lineItem.id,
                                        quantity: lineItem.quantity + parseInt(quantity, 10),
                                        customAttributes: lineItem.customAttributes?.map(
                                            ({ key, value }) => ({
                                                key,
                                                value,
                                                ...(key === 'Boxes' && {
                                                    value: (
                                                        Number(value) + Number(additionalBoxes)
                                                    ).toString(),
                                                }),
                                                ...(key === 'Total ft²' && {
                                                    value: roundToTwo(
                                                        Number(value) + Number(additionalArea)
                                                    ).toString(),
                                                }),
                                                ...(key === 'Total linear feet' && {
                                                    value: roundToTwo(
                                                        Number(value) + Number(additionalLinearFeet)
                                                    ).toString(),
                                                }),
                                            })
                                        ),
                                    },
                                ];

                                try {
                                    logInfo(`Update to cart`, APPS.FRONTEND, lineItemsToUpdate);
                                    // Update the line items in the cart
                                    const res = await client.checkout.updateLineItems(
                                        checkoutId,
                                        lineItemsToUpdate
                                    );

                                    // Handle any user errors returned by the update operation
                                    if (res.userErrors?.length > 0) {
                                        res.userErrors.map(userError =>
                                            logError(`Error Update to cart: ${userError.message}`)
                                        );
                                        enqueueSnackbar('Something went wrong, please try again.', {
                                            variant: 'error',
                                        });
                                        throw 'Error';
                                    } else {
                                        logInfo(
                                            `Updated to cart`,
                                            APPS.FRONTEND,
                                            res.lineItems.reverse()
                                        );
                                    }
                                    // Update the store with the updated checkout information
                                    updateStore(prevState => {
                                        const addToCartData = {
                                            event: 'dl_add_to_cart',
                                            user_properties: {
                                                ...transformToElevarUserProperties({
                                                    customer: prevState.customer,
                                                    customerLoggedIn: prevState.customerLoggedIn,
                                                }),
                                            },
                                            ecommerce: {
                                                currencyCode: prevState.checkout.currencyCode,
                                                add: {
                                                    actionField: {
                                                        list: prevState.dataLayerList ?? '', // this should be the collection page URL that user clicked product from
                                                    },
                                                    products: transformLineItemsToElevarProducts({
                                                        lineItems: [
                                                            {
                                                                ...lineItem,
                                                                quantity,
                                                            },
                                                        ],
                                                        dl_event: 'dl_add_to_cart',
                                                        tradeDiscountValue:
                                                            prevState.tradeDiscountValue,
                                                        list: prevState.dataLayerList ?? '',
                                                    }),
                                                },
                                            },
                                        };
                                        window.ElevarDataLayer.push({ ...addToCartData });
                                        logInfo(
                                            'Fire Elevar dl_add_to_cart',
                                            APPS.FRONTEND,
                                            addToCartData
                                        );

                                        const queryID = Cookies.get('queryID');
                                        const objectIDs = Cookies.get('objectIDs');
                                        if (queryID && objectIDs) {
                                            const addedToCartObjectIDsAfterSearchData = {
                                                eventName: 'Product Added To Cart',
                                                index: 'shopify_products',
                                                queryID: queryID,
                                                objectIDs: objectIDs.split(',') || [],
                                                objectData: [
                                                    ...transformLineItemsToAlgoliaObjectData({
                                                        lineItems: [{ ...lineItem, quantity }],
                                                        tradeDiscountValue:
                                                            prevState.tradeDiscountValue,
                                                    }),
                                                ],
                                                currency: prevState.checkout.currencyCode,
                                            };
                                            aa('addedToCartObjectIDsAfterSearch', {
                                                ...addedToCartObjectIDsAfterSearchData,
                                            });
                                            logInfo(
                                                'Fire Algolia addedToCartObjectIDsAfterSearch',
                                                APPS.FRONTEND,
                                                addedToCartObjectIDsAfterSearchData
                                            );
                                        }
                                        return {
                                            ...prevState,
                                            checkout: {
                                                ...res,
                                                lineItems: res.lineItems.reverse(),
                                            },
                                        };
                                    });
                                } catch (error) {
                                    console.error('Error updating line item:', error);
                                    throw error;
                                }

                                return;
                            }
                        }

                        // Create an array of line items to add to the cart
                        const lineItemsToAdd = [
                            {
                                variantId,
                                quantity: parseInt(quantity, 10),
                                customAttributes,
                            },
                        ];

                        logInfo(`Add to cart:`, APPS.FRONTEND, lineItemsToAdd);
                        // If the variant is not in the cart or has different customAttributes, add it as a new line item
                        return client.checkout
                            .addLineItems(checkoutId, lineItemsToAdd)
                            .then(checkout => {
                                if (checkout.userErrors?.length > 0) {
                                    checkout.userErrors.map(userError =>
                                        logError(`Error Add to cart: ${userError.message}`)
                                    );
                                    enqueueSnackbar('Something went wrong, please try again.', {
                                        variant: 'error',
                                    });
                                    throw 'Error Add to cart';
                                } else {
                                    logInfo(
                                        `Added to cart`,
                                        APPS.FRONTEND,
                                        checkout.lineItems.reverse()
                                    );
                                }

                                updateStore(prevState => {
                                    /** Add to Cart // note the list object which carries from collection page **/
                                    const lineItem = checkout.lineItems.find(
                                        ({ variant }) => variantId === variant.id
                                    );
                                    const addToCartData = {
                                        event: 'dl_add_to_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            add: {
                                                actionField: {
                                                    list: prevState.dataLayerList ?? '', // this should be the collection page URL that user clicked product from
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: [
                                                        {
                                                            ...lineItem,
                                                            quantity,
                                                        },
                                                    ],
                                                    dl_event: 'dl_add_to_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: prevState.dataLayerList ?? '',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...addToCartData });
                                    logInfo(
                                        'Fire Elevar dl_add_to_cart',
                                        APPS.FRONTEND,
                                        addToCartData
                                    );

                                    const queryID = Cookies.get('queryID');
                                    const objectIDs = Cookies.get('objectIDs');
                                    if (queryID && objectIDs) {
                                        const addedToCartObjectIDsAfterSearchData = {
                                            eventName: 'Product Added To Cart',
                                            index: 'shopify_products',
                                            queryID: queryID,
                                            objectIDs: objectIDs.split(',') || [],
                                            objectData: [
                                                ...transformLineItemsToAlgoliaObjectData({
                                                    lineItems: [{ ...lineItem, quantity }],
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                }),
                                            ],
                                            currency: prevState.checkout.currencyCode,
                                        };
                                        aa('addedToCartObjectIDsAfterSearch', {
                                            ...addedToCartObjectIDsAfterSearchData,
                                        });
                                        logInfo(
                                            'Fire Algolia addedToCartObjectIDsAfterSearch',
                                            APPS.FRONTEND,
                                            addedToCartObjectIDsAfterSearchData
                                        );
                                    }
                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...checkout,
                                            lineItems: checkout.lineItems.reverse(),
                                        },
                                    };
                                });
                            });
                    },
                    /**
                     * TODO: Refactor addVariantsToCart and make it more readable
                     */
                    /**
                     * Add multiple product variants to the shopping cart.
                     *
                     * @param {Array} variants - An array of objects, each containing variantId, quantity, and customAttributes.
                     */
                    addVariantsToCart: async variants => {
                        // Ensure variants is a non-empty array
                        if (!Array.isArray(variants) || variants.length === 0) {
                            throw new Error('Invalid input: variants should be a non-empty array');
                        }

                        const tradeDiscountValue = await handleTradeDiscountValue();

                        updateStore(store => ({
                            ...store,
                            tradeDiscountValue,
                        }));

                        // Get UTM parameters and event metadata
                        const utmSource = Cookies.get('utm_source');
                        const utmMedium = Cookies.get('utm_medium');
                        const utmCampaign = Cookies.get('utm_campaign');
                        const utmTerm = Cookies.get('utm_term');
                        const utmContent = Cookies.get('utm_content');
                        const eventSourceUrl = Cookies.get('event_source_url');
                        const eventTime = Cookies.get('event_time');

                        // Retrieve the current checkout and client from the store
                        const { checkout, client } = store;
                        const checkoutId = checkout.id;

                        // Separate line items to update and add
                        const lineItemsToUpdate = [];
                        const lineItemsToAdd = [];

                        for (const { variantId, quantity, customAttributes } of variants) {
                            // Ensure variantId and quantity are valid
                            if (variantId === '' || !quantity) {
                                continue; // Skip invalid variant
                            }

                            // Enhance customAttributes with UTM parameters
                            const updatedCustomAttributes = [...customAttributes];
                            if (
                                utmSource &&
                                !updatedCustomAttributes.find(({ key }) => key === 'utm_source')
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__utm_source',
                                    value: utmSource,
                                });
                            }
                            if (
                                utmMedium &&
                                !updatedCustomAttributes.find(({ key }) => key === 'utm_medium')
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__utm_medium',
                                    value: utmMedium,
                                });
                            }
                            if (
                                utmCampaign &&
                                !updatedCustomAttributes.find(({ key }) => key === 'utm_campaign')
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__utm_campaign',
                                    value: utmCampaign,
                                });
                            }
                            if (
                                utmTerm &&
                                !updatedCustomAttributes.find(({ key }) => key === 'utm_term')
                            ) {
                                updatedCustomAttributes.push({ key: '__utm_term', value: utmTerm });
                            }
                            if (
                                utmContent &&
                                !updatedCustomAttributes.find(({ key }) => key === 'utm_content')
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__utm_content',
                                    value: utmContent,
                                });
                            }
                            if (
                                eventSourceUrl &&
                                !updatedCustomAttributes.find(
                                    ({ key }) => key === 'event_source_url'
                                )
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__event_source_url',
                                    value: eventSourceUrl,
                                });
                            }
                            if (
                                eventTime &&
                                !updatedCustomAttributes.find(({ key }) => key === 'event_time')
                            ) {
                                updatedCustomAttributes.push({
                                    key: '__event_time',
                                    value: eventTime,
                                });
                            }

                            // Filter line items in the cart to find those with the same variantId
                            const lineItemsByVariantId = checkout.lineItems.filter(
                                ({ variant }) => variantId === variant.id
                            );

                            // Extract custom attributes related to boxes, area, and linear feet
                            const additionalBoxes = updatedCustomAttributes.find(
                                ({ key }) => key === 'Boxes'
                            )?.value;
                            const additionalArea = updatedCustomAttributes.find(
                                ({ key }) => key === 'Total ft²'
                            )?.value;
                            const additionalLinearFeet = updatedCustomAttributes.find(
                                ({ key }) => key === 'Total linear feet'
                            )?.value;

                            // Check if variant is already in cart and customAttributes has 'Boxes'
                            if (lineItemsByVariantId.length > 0 && additionalBoxes) {
                                const lineItem = lineItemsByVariantId.find(lineItem =>
                                    arraysHaveMatchingObjects(
                                        lineItem.customAttributes.filter(
                                            ({ key }) =>
                                                ![
                                                    'Boxes',
                                                    'Total ft²',
                                                    'Total linear feet',
                                                ].includes(key)
                                        ),
                                        updatedCustomAttributes.filter(
                                            ({ key }) =>
                                                ![
                                                    'Boxes',
                                                    'Total ft²',
                                                    'Total linear feet',
                                                ].includes(key)
                                        )
                                    )
                                );

                                if (lineItem) {
                                    lineItemsToUpdate.push({
                                        id: lineItem.id,
                                        quantity: lineItem.quantity + parseInt(quantity, 10),
                                        customAttributes: lineItem.customAttributes.map(
                                            ({ key, value }) => ({
                                                key,
                                                value,
                                                ...(key === 'Boxes' && {
                                                    value: (
                                                        Number(value) + Number(additionalBoxes)
                                                    ).toString(),
                                                }),
                                                ...(key === 'Total ft²' && {
                                                    value: roundToTwo(
                                                        Number(value) + Number(additionalArea)
                                                    ).toString(),
                                                }),
                                                ...(key === 'Total linear feet' && {
                                                    value: roundToTwo(
                                                        Number(value) + Number(additionalLinearFeet)
                                                    ).toString(),
                                                }),
                                            })
                                        ),
                                    });
                                    continue; // Skip adding this variant since it's updated
                                }
                            }

                            // Create a new line item if not updated
                            lineItemsToAdd.push({
                                variantId,
                                quantity: parseInt(quantity, 10),
                                customAttributes: updatedCustomAttributes,
                            });
                        }

                        try {
                            // Update existing line items in the cart
                            if (lineItemsToUpdate.length > 0) {
                                logInfo(`Update to cart`, APPS.FRONTEND, lineItemsToUpdate);
                                const updateResponse = await client.checkout.updateLineItems(
                                    checkoutId,
                                    lineItemsToUpdate
                                );

                                if (updateResponse.userErrors?.length > 0) {
                                    updateResponse.userErrors.forEach(userError =>
                                        logError(`Error Update to cart: ${userError.message}`)
                                    );
                                    enqueueSnackbar('Something went wrong, please try again.', {
                                        variant: 'error',
                                    });
                                    throw new Error('Error updating cart');
                                }

                                logInfo(
                                    `Updated to cart`,
                                    APPS.FRONTEND,
                                    updateResponse.lineItems.reverse()
                                );

                                // Update the store with the updated checkout information
                                updateStore(prevState => {
                                    const allLineItems = [
                                        ...prevState.checkout.lineItems,
                                        ...lineItemsToUpdate.map(item => ({
                                            ...item,
                                            variant: { id: item.id },
                                        })),
                                    ];

                                    const addToCartData = {
                                        event: 'dl_add_to_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            add: {
                                                actionField: {
                                                    list: prevState.dataLayerList ?? '',
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: allLineItems,
                                                    dl_event: 'dl_add_to_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: prevState.dataLayerList ?? '',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...addToCartData });
                                    logInfo(
                                        'Fire Elevar dl_add_to_cart',
                                        APPS.FRONTEND,
                                        addToCartData
                                    );

                                    const queryID = Cookies.get('queryID');
                                    const objectIDs = Cookies.get('objectIDs');
                                    if (queryID && objectIDs) {
                                        const addedToCartObjectIDsAfterSearchData = {
                                            eventName: 'Product Added To Cart',
                                            index: 'shopify_products',
                                            queryID,
                                            objectIDs: objectIDs.split(',') || [],
                                            objectData: [
                                                ...transformLineItemsToAlgoliaObjectData({
                                                    lineItems: allLineItems,
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                }),
                                            ],
                                            currency: prevState.checkout.currencyCode,
                                        };
                                        aa('addedToCartObjectIDsAfterSearch', {
                                            ...addedToCartObjectIDsAfterSearchData,
                                        });
                                        logInfo(
                                            'Fire Algolia addedToCartObjectIDsAfterSearch',
                                            APPS.FRONTEND,
                                            addedToCartObjectIDsAfterSearchData
                                        );
                                    }
                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...prevState.checkout,
                                            lineItems: allLineItems.reverse(),
                                        },
                                    };
                                });
                            }

                            // Add new line items to the cart
                            if (lineItemsToAdd.length > 0) {
                                logInfo(`Add to cart:`, APPS.FRONTEND, lineItemsToAdd);
                                const addResponse = await client.checkout.addLineItems(
                                    checkoutId,
                                    lineItemsToAdd
                                );

                                if (addResponse.userErrors?.length > 0) {
                                    addResponse.userErrors.forEach(userError =>
                                        logError(`Error Add to cart: ${userError.message}`)
                                    );
                                    enqueueSnackbar('Something went wrong, please try again.', {
                                        variant: 'error',
                                    });
                                    throw new Error('Error adding to cart');
                                }

                                logInfo(
                                    `Added to cart`,
                                    APPS.FRONTEND,
                                    addResponse.lineItems.reverse()
                                );

                                // Update the store with the updated checkout information
                                updateStore(prevState => {
                                    const allLineItems = [...addResponse.lineItems.reverse()];

                                    const addToCartData = {
                                        event: 'dl_add_to_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            add: {
                                                actionField: {
                                                    list: prevState.dataLayerList ?? '',
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: allLineItems,
                                                    dl_event: 'dl_add_to_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: prevState.dataLayerList ?? '',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...addToCartData });
                                    logInfo(
                                        'Fire Elevar dl_add_to_cart',
                                        APPS.FRONTEND,
                                        addToCartData
                                    );

                                    const queryID = Cookies.get('queryID');
                                    const objectIDs = Cookies.get('objectIDs');
                                    if (queryID && objectIDs) {
                                        const addedToCartObjectIDsAfterSearchData = {
                                            eventName: 'Product Added To Cart',
                                            index: 'shopify_products',
                                            queryID,
                                            objectIDs: objectIDs.split(',') || [],
                                            objectData: [
                                                ...transformLineItemsToAlgoliaObjectData({
                                                    lineItems: allLineItems,
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                }),
                                            ],
                                            currency: prevState.checkout.currencyCode,
                                        };
                                        aa('addedToCartObjectIDsAfterSearch', {
                                            ...addedToCartObjectIDsAfterSearchData,
                                        });
                                        logInfo(
                                            'Fire Algolia addedToCartObjectIDsAfterSearch',
                                            APPS.FRONTEND,
                                            addedToCartObjectIDsAfterSearchData
                                        );
                                    }
                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...addResponse,
                                            lineItems: allLineItems.reverse(),
                                        },
                                    };
                                });
                            }
                        } catch (error) {
                            console.error('Error in addVariantsToCart:', error);
                            enqueueSnackbar('Something went wrong, please try again.', {
                                variant: 'error',
                            });
                        }
                    },
                    removeLineItem: async item => {
                        // Fetch the latest checkout data
                        const lineItemID = item.id;
                        const checkoutID = store.checkout.id;
                        const newCheckout = await store.client.checkout.fetch(checkoutID);
                        const newLineItems = newCheckout.lineItems;
                        updateStore(prevState => ({
                            ...prevState,
                            checkout: {
                                ...newCheckout,
                                lineItems: newLineItems.reverse(),
                            },
                        }));

                        let lineItems = newLineItems.filter(item => item.id === lineItemID);
                        if (!lineItems.length > 0) {
                            return;
                        }

                        let lineItemIDsToRemove = [];

                        if (getLineItemSampleType(item) !== 'paid-sample') {
                            lineItemIDsToRemove = [lineItemID];
                        } else {
                            // Find the IDs of line items that need to be removed
                            lineItemIDsToRemove = [
                                lineItemID,
                                ...newLineItems
                                    .filter(lineItem =>
                                        lineItem.customAttributes.some(
                                            attr =>
                                                attr.key === '__required_paid_sample_variant_id' &&
                                                attr.value === item.variant.id
                                        )
                                    )
                                    .map(lineItem => lineItem.id),
                            ];
                        }

                        // const varId = lineItem.variant.id;
                        return store.client.checkout
                            .removeLineItems(checkoutID, lineItemIDsToRemove)
                            .then(res => {
                                updateStore(prevState => {
                                    /** Remove from Cart // note the list object which carries from collection page **/
                                    const removeFromCartData = {
                                        event: 'dl_remove_from_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            remove: {
                                                actionField: {
                                                    list: 'Shopping cart', // this should be the collection page URL that user clicked product from
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: lineItems,
                                                    dl_event: 'dl_remove_from_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: 'Shopping cart',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...removeFromCartData });
                                    logInfo(
                                        'Fire Elevar dl_remove_from_cart',
                                        APPS.FRONTEND,
                                        removeFromCartData
                                    );

                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...res,
                                            lineItems: res.lineItems.reverse(),
                                        },
                                    };
                                });
                            })
                            .catch(err => console.error(err, lineItemIDsToRemove, lineItems));
                    },
                    removeLineItems: async (checkoutID, lineItemIDs) => {
                        const lineItems = store.checkout.lineItems.filter(
                            item => item.id === lineItemIDs
                        );
                        // const varId = lineItem.variant.id;
                        return store.client.checkout
                            .removeLineItems(checkoutID, lineItemIDs)
                            .then(res => {
                                updateStore(prevState => {
                                    /** Remove from Cart // note the list object which carries from collection page **/
                                    const removeFromCartData = {
                                        event: 'dl_remove_from_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            remove: {
                                                actionField: {
                                                    list: 'Shopping cart', // this should be the collection page URL that user clicked product from
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: lineItems,
                                                    dl_event: 'dl_remove_from_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: 'Shopping cart',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...removeFromCartData });
                                    logInfo(
                                        'Fire Elevar dl_remove_from_cart',
                                        APPS.FRONTEND,
                                        removeFromCartData
                                    );

                                    return {
                                        ...prevState,
                                        checkout: {
                                            ...res,
                                            lineItems: res.lineItems.reverse(),
                                        },
                                    };
                                });
                            })
                            .catch(err => console.error(err, lineItemIDs, lineItems));
                    },
                    updateLineItem: async (lineItemId, quantity) => {
                        if (lineItemId === '' || typeof quantity === 'undefined') {
                            return;
                        }

                        const { checkout, client } = store;

                        const checkoutId = checkout.id;

                        let lineItemsToUpdate = [];

                        const lineItem = checkout.lineItems.find(({ id }) => lineItemId === id);

                        const template =
                            lineItem.customAttributes?.find(({ key }) => key === '_template')
                                ?.value || 'default';
                        const isNonTile =
                            !!lineItem.customAttributes?.find(({ key }) => key === '_non_tile')
                                ?.value === 'true';

                        // Title
                        if (!isNonTile) {
                            // Base Molding
                            if (template === 'base-molding') {
                                const linearFeetPerBox = lineItem.customAttributes?.find(
                                    ({ key }) => key === '_linear_feet_box_ratio'
                                )?.value;

                                const customAttributes = [
                                    ...lineItem.customAttributes.map(({ key, value }) => ({
                                        key,
                                        value,
                                    })),
                                    ...(linearFeetPerBox
                                        ? [
                                              {
                                                  key: 'Total linear feet',
                                                  value: `${roundToTwo(
                                                      quantity * linearFeetPerBox
                                                  )}`,
                                              },
                                          ]
                                        : []),
                                    {
                                        key: 'Boxes',
                                        value: `${quantity}`,
                                    },
                                ];

                                lineItemsToUpdate = [
                                    {
                                        id: lineItemId,
                                        quantity: parseInt(quantity, 10),
                                        customAttributes,
                                    },
                                ];
                            }

                            // Barliner
                            else if (template === 'barliner') {
                                const customAttributes = [
                                    ...lineItem.customAttributes.map(({ key, value }) => ({
                                        key,
                                        value,
                                    })),
                                    {
                                        key: 'Total linear feet',
                                        value: `${roundToTwo(quantity / 2)}`,
                                    },
                                ];

                                lineItemsToUpdate = [
                                    {
                                        id: lineItemId,
                                        quantity: parseInt(quantity, 10),
                                        customAttributes,
                                    },
                                ];
                            }

                            // Default Tile
                            const sqFtPerBox = lineItem.customAttributes?.find(
                                ({ key }) => key === '_square_feet_box_ratio'
                            )?.value;

                            const customAttributes = [
                                ...lineItem.customAttributes.map(({ key, value }) => ({
                                    key,
                                    value,
                                })),
                                ...(sqFtPerBox
                                    ? [
                                          {
                                              key: 'Total ft²',
                                              value: `${roundToTwo(quantity * sqFtPerBox)}`,
                                          },
                                      ]
                                    : []),
                                {
                                    key: 'Boxes',
                                    value: `${quantity}`,
                                },
                            ];

                            lineItemsToUpdate = [
                                {
                                    id: lineItemId,
                                    quantity: parseInt(quantity, 10),
                                    customAttributes,
                                },
                            ];
                        }

                        // Non Tile
                        else {
                            lineItemsToUpdate = [
                                { id: lineItemId, quantity: parseInt(quantity, 10) },
                            ];
                        }

                        try {
                            const res = await client.checkout.updateLineItems(
                                checkoutId,
                                lineItemsToUpdate
                            );

                            if (res.userErrors.length > 0) throw res.userErrors[0].message;

                            updateStore(prevState => {
                                const lineItem = prevState.checkout.lineItems.find(
                                    ({ id }) => lineItemId === id
                                );
                                if (quantity > lineItem.quantity) {
                                    /** Add to Cart // note the list object which carries from collection page **/
                                    const addToCartData = {
                                        event: 'dl_add_to_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            add: {
                                                actionField: {
                                                    list: 'Shopping cart', // this should be the collection page URL that user clicked product from
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: [
                                                        {
                                                            ...lineItem,
                                                            quantity: quantity - lineItem.quantity,
                                                        },
                                                    ],
                                                    dl_event: 'dl_add_to_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: 'Shopping cart',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...addToCartData });
                                    logInfo(
                                        'Fire Elevar dl_add_to_cart',
                                        APPS.FRONTEND,
                                        addToCartData
                                    );
                                }
                                if (quantity < lineItem.quantity) {
                                    /** Remove from Cart // note the list object which carries from collection page **/
                                    const removeFromCartData = {
                                        event: 'dl_remove_from_cart',
                                        user_properties: {
                                            ...transformToElevarUserProperties({
                                                customer: prevState.customer,
                                                customerLoggedIn: prevState.customerLoggedIn,
                                            }),
                                        },
                                        ecommerce: {
                                            currencyCode: prevState.checkout.currencyCode,
                                            remove: {
                                                actionField: {
                                                    list: 'Shopping cart', // this should be the collection page URL that user clicked product from
                                                },
                                                products: transformLineItemsToElevarProducts({
                                                    lineItems: [
                                                        {
                                                            ...lineItem,
                                                            quantity: lineItem.quantity - quantity,
                                                        },
                                                    ],
                                                    dl_event: 'dl_remove_from_cart',
                                                    tradeDiscountValue:
                                                        prevState.tradeDiscountValue,
                                                    list: 'Shopping cart',
                                                }),
                                            },
                                        },
                                    };
                                    window.ElevarDataLayer.push({ ...removeFromCartData });
                                    logInfo(
                                        'Fire Elevar dl_remove_from_cart',
                                        APPS.FRONTEND,
                                        removeFromCartData
                                    );
                                }
                                return {
                                    ...prevState,
                                    checkout: {
                                        ...res,
                                        lineItems: res.lineItems.reverse(),
                                    },
                                };
                            });
                        } catch (error) {
                            console.error('Error updating line item:', error);
                            throw error;
                        }
                    },
                    showNotification: ({ content }) => {
                        updateStore(store => ({
                            ...store,
                            notificationBar: {
                                isOpen: true,
                                content,
                            },
                        }));
                        setTimeout(() => {
                            updateStore(store => ({
                                ...store,
                                notificationBar: {
                                    isOpen: false,
                                    content: null,
                                },
                            }));
                        }, 10000);
                    },
                    hideNotification: () => {
                        updateStore(store => ({
                            ...store,
                            notificationBar: {
                                isOpen: false,
                                content: null,
                            },
                        }));
                    },
                    setSearchModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('search');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            searchModalIsOpen: bool,
                        }));
                    },
                    setLoginModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('login');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            loginModalIsOpen: bool,
                        }));
                    },
                    setRegisterModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('register');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            registerModalIsOpen: bool,
                        }));
                    },
                    setForgotPwModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('forgotpw');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            forgotPwModalIsOpen: bool,
                        }));
                    },
                    setResetModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('reset');
                        } else {
                            handleModalClose();
                        }
                        updateStore(store => ({
                            ...store,
                            resetModalIsOpen: bool,
                        }));
                    },
                    setProjectsModalOpen: bool => {
                        updateStore(store => ({
                            ...store,
                            projectsModalOpen: bool,
                        }));
                    },
                    setActiveProjectModalStep: step => {
                        updateStore(store => ({
                            ...store,
                            activeProjectModalStep: step,
                        }));
                    },
                    setTradeDiscountValue: async () => {
                        const tradeDiscountValue = await handleTradeDiscountValue();

                        updateStore(store => ({
                            ...store,
                            tradeDiscountValue,
                        }));
                    },
                    setCustomer,
                    setCustomerIsPendingTradeAccount: isPending =>
                        updateStore(store => ({
                            ...store,
                            customerIsPendingTradeAccount: isPending,
                        })),
                    setCartType: cartType =>
                        updateStore(store => ({ ...store, cartType: cartType })),
                    openCartTypeErrorModal: () =>
                        updateStore(store => ({ ...store, cartTypeErrorModalOpen: true })),
                    closeCartTypeErrorModal: () =>
                        updateStore(store => ({ ...store, cartTypeErrorModalOpen: false })),

                    setCustomerNameModalIsOpen: bool => {
                        if (bool) {
                            handleModalOpen('customer-name');
                        } else {
                            handleModalClose();
                        }

                        updateStore(store => ({ ...store, customerNameModalIsOpen: bool }));
                    },

                    setAfterLoginUrl: (url, isLoginModalOpenOnLoad) =>
                        updateStore(store => ({
                            ...store,
                            afterLoginUrl: url,
                            isLoginModalOpenOnLoad: isLoginModalOpenOnLoad || false,
                        })),
                    showAccessibility: () => {
                        if (store.userWayWidget.visibility === 'HIDDEN') {
                            document.documentElement.style.setProperty(
                                '--uwy-visibility',
                                'visible'
                            );
                            document.documentElement.style.setProperty('--uwy-opacity', '1');
                            document.documentElement.style.setProperty(
                                '--uwy-pointer-events',
                                'all'
                            );

                            if (!store.userWayWidget.scriptLoaded) {
                                const script = document.createElement('script');
                                script.src = process.env.NEXT_PUBLIC_USERWAY_URL;
                                script.dataset.account = process.env.NEXT_PUBLIC_USERWAY_ACCOUNT;
                                script.async = true;
                                document.body.appendChild(script);
                            }
                            updateStore(store => ({
                                ...store,
                                userWayWidget: {
                                    ...store.userWayWidget,
                                    scriptLoaded: true,
                                    visibility: 'VISIBLE',
                                },
                            }));
                            return;
                        }
                    },
                    toggleAccessibilityVisibility: () => {
                        if (store.userWayWidget.visibility === 'HIDDEN') {
                            document.documentElement.style.setProperty(
                                '--uwy-visibility',
                                'visible'
                            );
                            document.documentElement.style.setProperty('--uwy-opacity', '1');
                            document.documentElement.style.setProperty(
                                '--uwy-pointer-events',
                                'all'
                            );

                            if (!store.userWayWidget.scriptLoaded) {
                                const script = document.createElement('script');
                                script.src = process.env.NEXT_PUBLIC_USERWAY_URL;
                                script.dataset.account = process.env.NEXT_PUBLIC_USERWAY_ACCOUNT;
                                script.async = true;
                                document.body.appendChild(script);
                            }
                            updateStore(store => ({
                                ...store,
                                userWayWidget: {
                                    ...store.userWayWidget,
                                    scriptLoaded: true,
                                    visibility: 'VISIBLE',
                                },
                            }));
                            return;
                        }

                        document.documentElement.style.setProperty('--uwy-visibility', 'hidden');
                        document.documentElement.style.setProperty('--uwy-opacity', '0');
                        document.documentElement.style.setProperty('--uwy-pointer-events', 'none');

                        updateStore(store => ({
                            ...store,
                            userWayWidget: {
                                ...store.userWayWidget,
                                visibility: 'HIDDEN',
                            },
                        }));
                    },
                    setDataLayerList: list =>
                        updateStore(store => ({ ...store, dataLayerList: list })),
                    handleLogout,
                }}
            >
                {children}
            </SiteContext.Provider>
        </QueryClientProvider>
    );
};

SiteProvider.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    settings: PropTypes.object,
};

export default SiteProvider;
