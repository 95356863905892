import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { BodyText, H2 } from 'components/atoms/Typography';
import Link from 'components/atoms/Link';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import styled from 'styled-components';
import RevealElement from 'components/atoms/RevealElement';
import ScrollingCarousel from 'components/molecules/ScrollingCarousel';
import { ChevronLeftIcon, ChevronRightIcon } from 'components/atoms/Icons';
import { Section } from 'components/atoms/Container';
import { storyblokEditable } from '@storyblok/react';

const Organism = styled.section`
    .styles-module_slider__o0fqa {
        display: flex;
        align-items: center;
    }
    [data-arrow] {
        display: none;
    }
`;
const ScrollerItem = styled.div`
    flex: 0 0 auto;
    img {
        max-height: 75vh;
        width: auto;
    }
`;

const Carousel = ({ blok }) => {
    const data = useMemo(() => {
        const { heading, text, buttonLink, buttonText, carouselItems } = { ...blok };
        return { heading, text, buttonLink, buttonText, carouselItems };
    }, [blok]);

    return (
        <Organism className="lg:py-7" {...storyblokEditable(blok)}>
            <div className="container grid grid-cols-6 lg:grid-cols-12 gap-x-10 gap-y-6 items-start mb-8 lg:mb-14">
                {data.heading && (
                    <RevealElement className="col-span-full lg:col-span-3">
                        <H2>{data.heading}</H2>
                    </RevealElement>
                )}
                {data.text && (
                    <RevealElement
                        className="col-span-full lg:col-span-5 lg:col-start-4"
                        delay={0.1}
                    >
                        <BodyText>{data.text}</BodyText>
                    </RevealElement>
                )}
                {data.buttonText && (
                    <RevealElement
                        className="lg:col-span-3 lg:col-start-10 hidden lg:flex justify-end"
                        delay={0.2}
                        block={false}
                    >
                        <Link href={data.buttonLink || '/'}>
                            <Button className="lg:w-52" as="span" label={data.buttonText} />
                        </Link>
                    </RevealElement>
                )}
            </div>

            <Section as="div" padding="0 60px" paddingMobile="0">
                <RevealElement as="div">
                    <ScrollingCarousel
                        ariaLabel="image"
                        alignItems="center"
                        prevNextButtons={true}
                        PreviousButton={PreviousButton}
                        NextButton={NextButton}
                    >
                        {data?.carouselItems?.map((carouselItem, index) => {
                            const { _uid, image, link } = { ...carouselItem };
                            return (
                                <ScrollerItem
                                    key={_uid}
                                    role="group"
                                    aria-roledescription="slide"
                                    {...storyblokEditable(carouselItem)}
                                >
                                    {image?.[0]?.image?.filename &&
                                        image?.[0]?.image?.filename !== '' &&
                                        (link ? (
                                            <Link href={link} className="px-2 lg:px-5 block">
                                                <Image
                                                    src={image[0].image.filename}
                                                    alt={
                                                        image[0].image?.alt || data.heading
                                                            ? `${data.heading} - Image carousel ${
                                                                  index + 1
                                                              }`
                                                            : ''
                                                    }
                                                    dimension={image[0].dimension || '350x450'}
                                                    loading="lazy"
                                                />
                                            </Link>
                                        ) : (
                                            <div className="px-2 lg:px-5">
                                                <Image
                                                    src={image[0].image.filename}
                                                    alt={
                                                        image[0].image?.alt || data.heading
                                                            ? `${data.heading} - Image carousel ${
                                                                  index + 1
                                                              }`
                                                            : ''
                                                    }
                                                    dimension={image[0].dimension || '350x450'}
                                                    loading="lazy"
                                                />
                                            </div>
                                        ))}
                                </ScrollerItem>
                            );
                        })}
                    </ScrollingCarousel>
                </RevealElement>
            </Section>
            {data.buttonText && (
                <RevealElement className="container block lg:hidden mt-8" block={false}>
                    <Link href={data.buttonLink || '/'}>
                        <Button className="w-full" as="span" label={data.buttonText} />
                    </Link>
                </RevealElement>
            )}
        </Organism>
    );
};

Carousel.propTypes = {
    blok: PropTypes.object,
};

const PreviousButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 left-0 -translate-y-1/2 -translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronLeftIcon />
    </button>
);

PreviousButton.propTypes = {
    disabled: PropTypes.bool,
};

const NextButton = ({ disabled, ...props }) => (
    <button
        className={`hidden lg:block absolute z-10 top-1/2 right-0 -translate-y-1/2  translate-x-full ${
            disabled ? 'opacity-50' : ''
        }`}
        {...props}
        disabled={disabled}
    >
        <ChevronRightIcon />
    </button>
);

NextButton.propTypes = {
    disabled: PropTypes.bool,
};

export default Carousel;
