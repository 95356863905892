import PropTypes from 'prop-types';

const QuotesIcon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--quotes"
            width="74px"
            height="74px"
            viewBox="0 0 74 74"
            {...attrs}
        >
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-266.000000, -2957.000000)">
                    <g transform="translate(266.000000, 2946.000000)">
                        <g transform="translate(0.000000, 11.000000)">
                            <path
                                d="M44.88,44.96 L44.88,38.42 L42.3,38.42 C42.36,35.6 43.14,31.52 44.22,29 L41.46,29 C39.72,32.96 38.94,37.28 38.94,41.54 L38.94,44.96 L44.88,44.96 Z M35.94,44.96 L35.94,38.42 L33.36,38.42 C33.36,35.42 34.32,31.82 35.34,29 L32.52,29 C30.78,32.96 30,37.22 30,41.54 L30,44.96 L35.94,44.96 Z"
                                fill={fill}
                                fillRule="nonzero"
                            />
                            <rect stroke="#CCCCCC" x="0.5" y="0.5" width="73" height="73" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

QuotesIcon.defaultProps = {
    fill: 'currentColor',
};

QuotesIcon.propTypes = {
    fill: PropTypes.string,
};

export default QuotesIcon;
