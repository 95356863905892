import { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import Image from 'components/atoms/Image';
import { BodyText, H3 } from 'components/atoms/Typography';
import useImageWithTextV2Store from './useImageWithTextV2Store';
import breakpoints from 'styles/breakpoints';

const Block = styled.div`
    opacity: ${({ visible }) => !visible && '0'};
    visibility: ${({ visible }) => !visible && 'hidden'};
    position: ${({ visible }) => !visible && 'absolute'};
    transform: ${({ visible }) => (visible ? 'translateY(0)' : 'translateY(60px)')};
    transition: all 0.4s cubic-bezier(0.55, 0.09, 0.68, 0.53);

    ${props =>
        props.isFeatured &&
        `
            .text {
                @media screen and ${breakpoints.lg} {
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 3;  
                }
            }
        `};
    .text {
        -webkit-line-clamp: ${props => props.textLineClampMobile};
    }
`;

const TabItem = ({
    className,
    item,
    isFeatured,
    visible,
    textLineClampMobile,
    imageWidth,
    imageHeight,
}) => {
    const data = useMemo(() => {
        const {
            heading,
            image,
            subheading,
            tag,
            date,
            text,
            popupHeading,
            popupImages,
            popupText,
            popupButtonText,
            popupButtonLink,
        } = { ...item };
        return {
            heading,
            image: image?.filename && image?.filename !== '' ? image : null,
            subheading,
            tag,
            date,
            text,
            popup: {
                popupHeading,
                popupImage:
                    popupImages?.[0]?.image?.filename && popupImages?.[0]?.image?.filename !== ''
                        ? popupImages?.[0]?.image?.filename
                        : null,
                popupImageDimension: popupImages?.[0]?.dimension,
                popupText,
                popupButtonText,
                popupButtonLink,
            },
        };
    }, [item]);

    const { setPopup, setPopupOpen } = useImageWithTextV2Store(({ setPopup, setPopupOpen }) => ({
        setPopup,

        setPopupOpen,
    }));

    const handleChangePopup = useCallback(
        popup => {
            setPopup(popup);
            setPopupOpen(true);
        },
        [setPopup]
    );

    return (
        <Block
            className={className}
            visible={visible}
            isFeatured={isFeatured}
            textLineClampMobile={textLineClampMobile}
        >
            <button className="w-full text-left" onClick={() => handleChangePopup(data.popup)}>
                <div className="mb-4">
                    {data.image ? (
                        <Image
                            className="w-full"
                            src={data.image.filename}
                            alt={data.image?.alt || data?.heading || data?.subheading || ''}
                            loading="lazy"
                            width={imageWidth || isFeatured ? 1320 : 620}
                            height={imageHeight || isFeatured ? 1734 : 600}
                        />
                    ) : (
                        <PostPlaceholderImage
                            className="bg-gray-100"
                            pb={isFeatured ? (1734 / 1320) * 100 : (600 / 620) * 100}
                        ></PostPlaceholderImage>
                    )}
                </div>
                <div className={`flex flex-wrap gap-x-4 ${isFeatured ? '' : ''}`}>
                    <span
                        className={`block mb-4 text-subdued border border-current py-1.5 px-3 uppercase text-2xs font-semibold tracking-widest ${
                            isFeatured ? 'lg:mr-auto' : ''
                        }`}
                    >
                        {data.tag}
                    </span>
                    <span
                        className={`flex flex-wrap justify-between gap-x-4 w-full ${
                            isFeatured ? 'lg:ml-auto lg:w-auto' : ''
                        }`}
                    >
                        <span className="block mb-4 lg:mb-3 text-subdued">{data.subheading}</span>
                        {data.date && (
                            <span className="block mb-4 lg:mb-3">
                                {moment(data.date).format('MMM D')}
                            </span>
                        )}
                    </span>
                </div>
                {data.heading && <H3 className="line-clamp-1 mb-4">{data.heading}</H3>}
                <BodyText className={`text ${isFeatured ? 'line-clamp-5' : 'line-clamp-2'}`}>
                    {data.text}
                </BodyText>
            </button>
        </Block>
    );
};

TabItem.defaultProps = {
    className: '',
    isFeatured: false,
    visible: true,
};

TabItem.propTypes = {
    className: PropTypes.string,
    item: PropTypes.object,
    isFeatured: PropTypes.bool,
    visible: PropTypes.bool,
    textLineClampMobile: PropTypes.number,
    imageWidth: PropTypes.number,
    imageHeight: PropTypes.number,
};

const PostPlaceholderImage = styled.div`
    height: 0;
    width: 100%;
    padding-bottom: ${props => props.pb};
`;

export default TabItem;
