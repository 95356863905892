import styled from 'styled-components';

export const NavLink = styled.button`
    background-color: ${({ active }) => (active ? 'rgb(var(--bg-dark))' : 'transparent')};
    color: ${({ active }) => (active ? 'rgb(var(--bg-light))' : 'rgb(var(--subdued-text-color))')};
    line-height: 1.1875;
    transition: 0.4s all cubic-bezier(0.55, 0.09, 0.68, 0.53);
    &:hover {
        background-color: rgba(var(--bg-dark), 0.75);
        color: rgb(var(--bg-light));
    }
    &:focus {
        background-color: rgb(var(--bg-dark));
    }
`;

/* export const TabPane = styled.div`
    opacity: ${({ active }) => !active && '0'};
    visibility: ${({ active }) => !active && 'hidden'};
    position: ${({ active }) => !active && 'absolute'};
    transform: ${({ active }) => (active ? 'translateY(0)' : 'translateY(60px)')};
    left: 0;
    top: 0;
    width: 100%;
    transition: all 0.4s cubic-bezier(0.55, 0.09, 0.68, 0.53);
`; */
