import PropTypes from 'prop-types';

const PlusIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--plus" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g fill={fill}>
                    <path d="M8.5 2v5.5H14v1H8.5V14h-1V8.5H2v-1h5.5V2h1z" />
                </g>
            </g>
        </svg>
    );
};

PlusIcon.defaultProps = {
    fill: 'currentColor',
};

PlusIcon.propTypes = {
    fill: PropTypes.string,
};

export default PlusIcon;
