import Cookies from 'js-cookie';
import { getShopifyCustomer } from './requests/shopify-data-requests';

export const handleTradeDiscountValue = async () => {
    if (Cookies.get('ZIA_SESS')) {
        const {
            data: { data },
        } = await getShopifyCustomer(Cookies.get('ZIA_SESS'));

        if (data?.customer) {
            const { tags } = data.customer;

            for (let tag of tags) {
                for (let discount = 10; discount <= 30; discount = discount + 5) {
                    if (tag === `TR-${discount}`) {
                        return discount;
                    }
                }
            }
        }
    }
    return null;
};
