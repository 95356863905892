import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--check" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M14 0L4.61 13.413c-.252.361-.662.58-1.102.587-.44.007-.856-.198-1.12-.55L0 10.266"
                        transform="translate(-963.000000, -346.000000) translate(420.000000, 340.000000) translate(543.000000, 6.000000) translate(1.000000, 1.000000)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
