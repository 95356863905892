import { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import 'styles/globals.scss';
import SiteProvider from 'context/SiteProvider';
import Script from 'next/script';
import { removeEmpty } from 'helpers';
import { storyblokInit, apiPlugin, useStoryblokState } from '@storyblok/react';
import { captureUTMParameters } from 'helpers/UTMTracking';

import dynamic from 'next/dynamic';
import { SnackbarProvider } from 'notistack';
import { InstagramCarousel } from 'components/organisms/General';

const AccessibilityButton = dynamic(() =>
    import('components/organisms/General/AccessibilityButton')
);
const Accordion = dynamic(() => import('components/organisms/General/Accordion'));
const Announcement = dynamic(() => import('components/organisms/General/Announcement'));
const Breadcrumb = dynamic(() => import('components/organisms/General/Breadcrumb'));
const PageHeader = dynamic(() => import('components/organisms/General/PageHeader'));
const BannerImage = dynamic(() => import('components/organisms/General/BannerImage'));
const Carousel = dynamic(() => import('components/organisms/General/Carousel'));
const FeaturedCollectionV2 = dynamic(() =>
    import('components/organisms/General/FeaturedCollectionV2')
);
const ImageWithTextV2 = dynamic(() => import('components/organisms/General/ImageWithTextV2'));
// const InstagramCarousel = dynamic(() => import('components/organisms/General/InstagramCarousel'));
const HeroV2 = dynamic(() => import('components/organisms/General/HeroV2'));
const HeroV3 = dynamic(() => import('components/organisms/General/HeroV3'));
const HeroV4 = dynamic(() => import('components/organisms/General/HeroV4'));
const ImageWithText = dynamic(() => import('components/organisms/General/ImageWithText'));
const TileUsage = dynamic(() => import('components/organisms/General/TileUsage'));
const Video = dynamic(() => import('components/organisms/General/Video'));

const AllCollectionsGrid = dynamic(() =>
    import('components/organisms/AllCollectionsPage/AllCollectionsGrid')
);
const AllCollectionsHero = dynamic(() =>
    import('components/organisms/AllCollectionsPage/AllCollectionsHero')
);

const BlogPostComments = dynamic(() =>
    import('components/organisms/BlogPostPage/BlogPostComments')
);
const PostSectionContent = dynamic(() =>
    import('components/organisms/BlogPostPage/PostSectionContent')
);
const PostSectionHeading = dynamic(() =>
    import('components/organisms/BlogPostPage/PostSectionHeading')
);
const PostSectionImage = dynamic(() =>
    import('components/organisms/BlogPostPage/PostSectionImage')
);
const PostSectionQuote = dynamic(() =>
    import('components/organisms/BlogPostPage/PostSectionQuote')
);
const BlogSplitSection = dynamic(() =>
    import('components/organisms/BlogPostPage/BlogSplitSection')
);
const BlogKeyPoints = dynamic(() => import('components/organisms/BlogPostPage/BlogKeyPoints'));
const BlogPhotoGallery = dynamic(() =>
    import('components/organisms/BlogPostPage/BlogPhotoGallery')
);

const CollectionBanner = dynamic(() => import('components/organisms/HomePage/CollectionBanner'));
const FeaturedCollection = dynamic(() =>
    import('components/organisms/HomePage/FeaturedCollection')
);
const FeaturedCollectionsProducts = dynamic(() =>
    import('components/organisms/HomePage/FeaturedCollectionsProducts')
);
const FeaturedListCollection = dynamic(() =>
    import('components/organisms/HomePage/FeaturedListCollection')
);
const Hero = dynamic(() => import('components/organisms/HomePage/Hero'));
const FeaturedProducts = dynamic(() => import('components/organisms/HomePage/FeaturedProducts'));
const InspoBoard = dynamic(() => import('components/organisms/HomePage/InspoBoard'));
const LearnMore = dynamic(() => import('components/organisms/HomePage/LearnMore'));

const FeaturedListCollectionV2 = dynamic(() =>
    import('components/organisms/General/FeaturedListCollectionV2')
);

const InstallationProcess = dynamic(() =>
    import('components/organisms/ProductPage/InstallationProcess')
);

const TradeProgramForm = dynamic(() =>
    import('components/organisms/TradeProgram/TradeProgramForm')
);

const Main404 = dynamic(() => import('components/organisms/404/Main404'));

/* New Blog */
const BlogBanner = dynamic(() => import('components/organisms/BlogPage/BlogBanner'));
const LatestPosts = dynamic(() => import('components/organisms/BlogPage/LatestPosts'));
const BlogCollectionV1 = dynamic(() => import('components/organisms/BlogPage/BlogCollectionV1'));
const BlogCollectionV2 = dynamic(() => import('components/organisms/BlogPage/BlogCollectionV2'));
const BlogCollectionWithTabs = dynamic(() =>
    import('components/organisms/BlogPage/BlogCollectionWithTabs')
);

/* New General */
const Newsletter = dynamic(() => import('components/organisms/General/Newsletter'));
const ShopTheLook = dynamic(() => import('components/organisms/General/ShopTheLook'));

/* Blog Collection */
const MainBlogCollection = dynamic(() =>
    import('components/organisms/BlogCollectionPage/MainBlogCollection')
);

// resolve Storyblok components to Next.js components
const components = {
    // Home
    Hero,
    FeaturedCollection,
    FeaturedCollectionsProducts,
    InspoBoard,
    // General
    AccessibilityButton,
    Announcement,
    Breadcrumb,
    BannerImage,
    Carousel,
    CollectionBanner,
    ImageWithTextV2,
    FeaturedCollectionV2,
    FeaturedListCollection,
    FeaturedListCollectionV2,
    FeaturedProducts,
    HeroV2,
    HeroV3,
    HeroV4,
    ImageWithText,
    InstagramCarousel,
    LearnMore,
    PageHeader,
    Video,
    Newsletter,
    SplitSection: BlogSplitSection,
    ShopTheLook,
    TileUsage,
    // Blog List
    BlogBanner,
    LatestPosts,
    BlogCollectionV1,
    BlogCollectionV2,
    BlogCollectionWithTabs,
    // Blog Collection
    MainBlogCollection,
    // Blog Post
    BlogPostComments,
    PostSectionContent,
    PostSectionHeading,
    PostSectionImage,
    PostSectionQuote,
    BlogSplitSection,
    BlogKeyPoints,
    BlogPhotoGallery,
    // All Collections Page
    AllCollectionsGrid,
    AllCollectionsHero,
    // PDP
    Accordion,
    InstallationProcess,
    // Trade Program
    TradeProgramForm,
    // 404
    Main404,
};

storyblokInit({
    accessToken: process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN,
    use: [apiPlugin],
    apiOptions: {
        region: 'eu',
    },
    components,
});

function MyApp({ Component, pageProps, story }) {
    const data = { story: useStoryblokState(story) };

    const settings = useMemo(() => {
        return {
            header: data.story.content.header[0],
            footer: data.story.content.footer[0],
            tileUsages: data.story.content.tileUsages,
            cartNote: {
                title: data.story.content.cartNoteTitle,
                text: data.story.content.cartNoteText,
            },
            cartNote2: {
                title: data.story.content.cartNote2Title,
                text: data.story.content.cartNote2Text,
            },
            product: {
                inventoryQuantityThreshold: data.story.content.inventoryQuantityThreshold,
                specialOrderLeadTimes: data.story.content.specialOrderLeadTimes,
            },
            productNotes: data.story.content.productNotes,
            mixedCartError: {
                title: data.story.content.mixedCartErrorTitle,
                text: data.story.content.mixedCartErrorText,
            },
            sampleNotes: data.story.content.sampleNotes,
            termsOfSaleNote: { text: data.story.content.termsOfSaleNoteText },
            links: {
                termsOfSaleText: data.story.content.termsOfSaleText || 'terms of sale',
                termsOfSaleLink: data.story.content.termsOfSaleLink || '/terms-of-sale',
                installationCareMaintenanceGuidelinesText:
                    data.story.content.installationCareMaintenanceGuidelinesText ||
                    'installation, care, and maintenance guidelines',
                installationCareMaintenanceGuidelinesLink:
                    data.story.content.installationCareMaintenanceGuidelinesLink ||
                    'https://support.ziatile.com/hc/en-us/categories/12710224763668-Installation-Guides',
            },
            globalMetadata: removeEmpty(data.story.content.metadata || {}),
            ...(data.story.content.keywords &&
                data.story.content.keywords !== '' && {
                    globalKeywords: data.story.content.keywords,
                }),
            favicon: data.story.content.favicon,
            phone: data.story.content.phone,
            email: data.story.content.email,
            pinterest: data.story.content.pinterest,
            facebook: data.story.content.facebook,
            instagram: data.story.content.instagram,
            organization: data?.story?.content?.organizations?.[0] || null,
        };
    }, [data]);

    const schema = useMemo(() => {
        const organization = data?.story?.content?.organizations?.[0] || null;

        if (!organization) return null;

        const {
            name = 'Zia Tile',
            url = 'https://www.ziatile.com',
            logo,
            description,
            sameAs = [],
            address = [],
            founders = [],
        } = { ...organization };

        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Organization',
            name: name,
            url: url,
            description: description,
            logo: logo && {
                '@type': 'ImageObject',
                url: logo.filename,
                width: logo.width,
                height: logo.height,
            },
            sameAs: sameAs.map(({ url }) => url),
            address: address.map(
                ({
                    streetAddress,
                    addressLocality,
                    addressRegion,
                    postalCode,
                    addressCountry,
                }) => ({
                    '@type': 'PostalAddress',
                    streetAddress: streetAddress,
                    addressLocality: addressLocality,
                    addressRegion: addressRegion,
                    postalCode: postalCode,
                    addressCountry: addressCountry,
                })
            ),
            founders: founders.map(({ name, jobTitle }) => ({
                '@type': 'Person',
                name: name,
                jobTitle: jobTitle,
            })),
        });
    }, [data]);

    const prefetchUserWayScript = () => {
        const link = document.createElement('link');
        link.rel = 'prefetch';
        link.href = process.env.NEXT_PUBLIC_USERWAY_URL;
        document.head.appendChild(link);
    };

    useEffect(() => {
        // Flag to track whether the user has scrolled down by 500 pixels
        let hasScrolled = false;

        // Function to check for the presence of the element and add the "show" class
        const checkAndAddClass = () => {
            const termlySnippetElement = document.querySelector('#termly-code-snippet-support');
            if (termlySnippetElement && hasScrolled) {
                // Add the "show" class to make the element visible
                termlySnippetElement.classList.add('show');
            }
        };

        // Function to be executed when the user scrolls
        const handleScroll = () => {
            if (!hasScrolled && window.scrollY >= 500) {
                hasScrolled = true;
                checkAndAddClass();
                // Remove the scroll event listener once the flag is set
                window.removeEventListener('scroll', handleScroll);
            }
        };

        // Add the scroll event listener when the component mounts
        window.addEventListener('scroll', handleScroll);

        // Set up a MutationObserver to watch for changes in the DOM
        const observer = new MutationObserver(checkAndAddClass);

        // Start observing changes to the DOM
        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up the scroll event listener and observer when the component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
            observer.disconnect();
        };
    }, []);

    // On Mount
    useEffect(() => {
        captureUTMParameters();
        prefetchUserWayScript();
    }, []);

    return (
        <SnackbarProvider maxSnack={3}>
            <SiteProvider settings={settings}>
                {/* Google Tag Manager */}
                <Script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                })(window,document,'script','dataLayer','GTM-MR2MHWV');`}</Script>
                {/* End Google Tag Manager */}
                <Script type="module">
                    {`try {
                        const settings = {};
                        const config = (await import("https://shopify-gtm-suite.getelevar.com/configs/c4b39362995d1cfeba89b0f6f0fa9ccc1216c815/config.js")).default;
                        const scriptUrl = settings.proxyPath
                        ? settings.proxyPath + config.script_src_custom_pages_proxied
                        : config.script_src_custom_pages;

                        if (scriptUrl) {
                        const { handler } = await import(scriptUrl);
                        await handler(config, settings);
                        }
                    } catch (error) {
                        console.error("Elevar Error:", error);
                    }`}
                </Script>
                <Script type="module" src={process.env.NEXT_PUBLIC_CONSPIRE_WISHLIST_URL} />
                {schema && <Script id="schema" type="application/ld+json">{`${schema}`}</Script>}
                <Script id="global-property">{`"use strict";var handleGlobalProperty=function(){document.documentElement.style.setProperty("--vh","".concat(.01*window.innerHeight,"px"))},timeoutId=null,resizeListener=function(){clearTimeout(timeoutId),timeoutId=setTimeout(handleGlobalProperty,20)},windowWidth=window.innerWidth;resizeListener(),window.addEventListener("resize",function(){windowWidth!==window.innerWidth&&(windowWidth=window.innerWidth,resizeListener())}),window.addEventListener("load",resizeListener),window.addEventListener("orientationchange",resizeListener);`}</Script>
                {/**
                 * TIME: 04/10/2024 12:50 AM
                 * Remove any facebook tracking code we have injected on ZIA. Elevar also noted that Termly is making some sort of call to facebook
                 */}
                {/* <Script strategy="lazyOnload">
                    {`!function(f,b,e,v,n,t,s)
                            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                            n.queue=[];t=b.createElement(e);t.async=!0;
                            t.src=v;s=b.getElementsByTagName(e)[0];
                            s.parentNode.insertBefore(t,s)}(window, document,'Script',
                            'https://connect.facebook.net/en_US/fbevents.js');
                            fbq('init', '1063796820423637');
                            fbq('track', 'PageView');`}
                </Script> */}
                <Script strategy="lazyOnload">
                    {`(function(c,l,a,r,i,t,y){
                    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
                    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
                    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
                    })(window, document, "clarity", "script", "l5d3ewy41o");`}
                </Script>
                {process.env.NEXT_PUBLIC_ENV === 'PRODUCTION' && (
                    <Script strategy="lazyOnload" src="https://www.dwin1.com/87115.js" />
                )}
                <Component {...pageProps} />
            </SiteProvider>
        </SnackbarProvider>
    );
}

MyApp.getInitialProps = async context => {
    // const pageProps = await MyApp.getInitialProps(context);
    // the slug of the story
    let fullSlug = 'settings';
    // the storyblok params
    let options = {
        version: process.env.NEXT_PUBLIC_STORYBLOK_VERSION || 'published', // or 'published'
        // appends the cache version to get the latest content
        cv: Date.now(),
    };

    // checks if Next.js is in preview mode
    if (context.preview) {
        // loads the draft version
        options.version = 'draft';
    }

    // const storyblokApi = getStoryblokApi();
    // let { data } = await storyblokApi.get(`cdn/stories/${fullSlug}`, options);

    const response = await fetch(
        `https://api.storyblok.com/v2/cdn/stories/${fullSlug}?token=${process.env.NEXT_PUBLIC_STORYBLOK_ACCESS_TOKEN}&version=${options.version}&cv=${options.cv}`
    );
    const data = await response.json();

    return {
        story: data?.story || null,
    };
};

MyApp.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    pageProps: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    story: PropTypes.object,
};

export default MyApp;
