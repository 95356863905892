import PropTypes from 'prop-types';

const Icon = ({ fill = 'currentColor', className = '', ...attrs }) => {
    return (
        <svg
            className={`icon icon--chevronRight ${className}`}
            width="30"
            height="30"
            viewBox="0 0 30 30"
            {...attrs}
        >
            <g>
                <path stroke={fill} d="m7.5,0l15,15l-15,15" fill="none"></path>
            </g>
        </svg>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
    className: PropTypes.string,
};

export default Icon;
