import { Backdrop, Fade, Dialog, makeStyles } from '@material-ui/core';
import Button from 'components/atoms/Button';
import { CloseIcon } from 'components/atoms/Icons';
import Image from 'components/atoms/Image';
import RevealElement from 'components/atoms/RevealElement';
import { BodyText, H2 } from 'components/atoms/Typography';
import Link from 'components/atoms/Link';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useCallback } from 'react';
import { useMemo } from 'react';
import TabItem from './components/TabItem';
import TabPaneV2 from './components/TabPaneV2';
import useImageWithTextV2Store from './components/useImageWithTextV2Store';
import { NavLink } from './ImageWithTextV2.styled';
import { storyblokEditable } from '@storyblok/react';

const useStyles = makeStyles({
    paper: {
        maxWidth: '1150px',
    },
});

const ImageWithTextV2 = ({ blok }) => {
    const data = useMemo(() => {
        const { title, tabs = [] } = { ...blok };
        return { title, tabs: tabs.filter(tab => !tab.disabled) };
    }, [blok]);
    const { activeTab, setActiveTab, popup, setPopup, popupOpen, setPopupOpen } =
        useImageWithTextV2Store(
            ({ activeTab, setActiveTab, popup, setPopup, popupOpen, setPopupOpen }) => ({
                activeTab,
                setActiveTab,
                popup,
                setPopup,
                popupOpen,
                setPopupOpen,
            })
        );

    useEffect(() => {
        setActiveTab(data?.tabs?.[0]?._uid || null);
    }, [setActiveTab]);

    const handleChangeActiveTab = useCallback(
        _uid => {
            setActiveTab(_uid);
        },
        [setActiveTab]
    );

    const handleClosePopup = useCallback(() => {
        setPopup(null);
        setPopupOpen(false);
    }, [setPopup]);

    const classes = useStyles();

    return (
        <section
            className="pb-6 lg:py-7 overflow-hidden my-16 lg:my-24"
            {...storyblokEditable(blok)}
        >
            <RevealElement className="container mb-7">
                <H2>{data.title}</H2>
            </RevealElement>
            <div className="container">
                {data.tabs?.length > 1 && (
                    <ul className="flex flex-wrap -m-1.5 mb-8">
                        {data.tabs.map((tab, index) => (
                            <li className="m-1.5" key={tab._uid} {...storyblokEditable(tab)}>
                                <RevealElement delay={0.1 * index}>
                                    <NavLink
                                        className={`py-3 px-6 font-semibold`}
                                        active={activeTab === tab._uid}
                                        onClick={() => handleChangeActiveTab(tab._uid)}
                                    >
                                        {tab.title}
                                    </NavLink>
                                </RevealElement>
                            </li>
                        ))}
                    </ul>
                )}
                <div className="relative">
                    {data.tabs.map(tab =>
                        tab.layout === 'v2'
                            ? activeTab === tab._uid && <TabPaneV2 key={tab._uid} tab={tab} />
                            : activeTab === tab._uid && (
                                  <ul
                                      key={tab._uid}
                                      className="grid grid-cols-6 lg:grid-cols-12 gap-y-12 lg:gap-10"
                                  >
                                      {tab?.items?.map(({ _uid, ...item }, index) => (
                                          <RevealElement
                                              className={`col-span-full ${
                                                  index === 0
                                                      ? 'lg:col-span-6 row-span-2'
                                                      : 'lg:col-span-3'
                                              }`}
                                              key={_uid}
                                              as="li"
                                              delay={0.05 * index}
                                              {...storyblokEditable(item)}
                                          >
                                              <TabItem item={item} isFeatured={index === 0} />
                                          </RevealElement>
                                      ))}
                                  </ul>
                              )
                    )}
                </div>
            </div>
            {popup && (
                <Dialog
                    open={popupOpen}
                    onClose={handleClosePopup}
                    aria-label={popup?.popupHeading || 'Modal label'}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                    PaperProps={{
                        square: true,
                    }}
                    classes={{
                        paper: classes.paper,
                    }}
                >
                    <Fade in={popupOpen}>
                        <div className="w-full outline-none">
                            <div className="relative p-6 lg:p-8 bg-light">
                                {popup?.popupImage && (
                                    <Image
                                        className="w-full mb-8"
                                        src={popup.popupImage}
                                        alt={popup?.popupHeading || ''}
                                        loading="lazy"
                                        dimension={popup?.popupImageDimension || '536x670'}
                                        maxWidth={1150}
                                    />
                                )}
                                {popup?.popupHeading && (
                                    <H2 className="mb-3" as="h1">
                                        {popup.popupHeading}
                                    </H2>
                                )}
                                {popup?.popupText && (
                                    <BodyText className="mb-8">{popup.popupText}</BodyText>
                                )}
                                {popup?.popupButtonText && (
                                    <Link href={popup?.popupButtonLink || '#'}>
                                        <Button
                                            as="span"
                                            label={popup.popupButtonText}
                                            width="200px"
                                            onClick={handleClosePopup}
                                        />
                                    </Link>
                                )}
                                <span className="absolute right-0 top-0">
                                    <Button
                                        type="icon"
                                        icon={<CloseIcon fill="currentColor" />}
                                        onClick={handleClosePopup}
                                        paddingMobile="0.3125rem"
                                    />
                                </span>
                            </div>
                        </div>
                    </Fade>
                </Dialog>
            )}
        </section>
    );
};

ImageWithTextV2.defaultProps = {
    block: {},
};

ImageWithTextV2.propTypes = {
    blok: PropTypes.object,
};

export default ImageWithTextV2;
