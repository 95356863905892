import styled from 'styled-components';
import PropTypes from 'prop-types';
import { CaretDownIcon } from 'components/atoms/Icons';

const SelectOuter = styled.div`
    width: 100%;
    max-width: ${props => props.maxWidth};
    position: relative;
    height: fit-content;
    label {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        color: ${props => (props.error ? '#ff3636' : props.inverted ? '#fff' : '#7f7f7f')};
        line-height: normal;
        opacity: 1;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: 2px;
        text-transform: uppercase;
    }
    ~ .error {
        display: flex;
        color: #ff3636;
        font-family: Barlow;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 1px;
        margin-top: 6px;
        position: absolute;
    }
    select {
        -webkit-appearance: none;
        -moz-appearance: none;
        width: 100%;
        background: transparent;
        color: ${props => (props.inverted ? '#fff' : '#000')};
        padding: ${props => props.padding || '10px 0'};
        outline: none;
        border-radius: 0;
        border-bottom: 1px solid
            ${props => (props.error ? '#ff3636' : props.inverted ? '#f2f2f2' : '#ccc')};
        position: relative;
        z-index: 1;
        cursor: pointer;
        padding-right: 16px;
        text-align: ${props => props.align};
    }
    select::-ms-expand {
        display: none;
    }
    select:invalid {
        color: #9ca3af;
    }
    .icon--caretDown {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
`;

const Select = ({
    name,
    label,
    defaultValue,
    value,
    customLabel,
    placeholder,
    options,
    onChange,
    required,
    align = 'left',
    ...props
}) => {
    const CustomLabel = customLabel;
    return (
        <SelectOuter {...props} align={align}>
            {customLabel && <CustomLabel />}
            {label && <label>{label}</label>}
            {/* eslint-disable-next-line */}
            <select
                required={required}
                defaultValue={defaultValue}
                value={value}
                name={name}
                onChange={onChange}
            >
                <option disabled defaultValue hidden value="">
                    {placeholder}
                </option>
                {Object.entries(options).map((opt, i) => (
                    <option key={i} value={opt[0]}>
                        {opt[1]}
                    </option>
                ))}
            </select>
            <CaretDownIcon fill="#aaa" />
        </SelectOuter>
    );
};

Select.defaultProps = {
    name: '',
    label: '',
    placeholder: '',
    options: {},
    onChange: () => {},
    align: 'left',
};

Select.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    defaultValue: PropTypes.string,
    value: PropTypes.string,
    customLabel: PropTypes.elementType,
    placeholder: PropTypes.string,
    options: PropTypes.oneOfType([PropTypes.object]),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    align: PropTypes.oneOf(['left', 'center', 'right']),
};

export default Select;
