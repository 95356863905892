import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--logout" width="16" height="17" viewBox="0 0 16 17" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g>
                    <g transform="translate(-1344 -193) translate(1290 190.308)">
                        <g>
                            <path
                                fill={fill}
                                fillRule="nonzero"
                                d="M9.613.5c.755.038 1.348.644 1.386 1.366V5c0 .278-.225.503-.503.503-.246 0-.451-.178-.494-.413l-.008-.09V1.98c.01-.223-.14-.415-.333-.464l-.073-.01-8.151-.001c-.222.01-.4.18-.428.409l-.003.088V14.02c-.01.223.14.415.332.463l.074.012h8.15c.223-.01.4-.18.429-.409l.003-.088v-3c0-.277.225-.502.502-.502.247 0 .452.178.495.413l.008.09v2.976c.034.756-.513 1.404-1.267 1.511l-.144.014H1.386C.631 15.46.04 14.855.001 14.133V2.025C-.032 1.27.514.621 1.269.514L1.412.5h8.2zm3.643 4.65l.07.059 2.485 2.486c.174.173.193.443.058.637l-.058.07-2.486 2.485c-.195.196-.512.196-.707 0-.174-.173-.193-.443-.058-.637l.058-.07 1.678-1.68h-9.34c-.252 0-.456-.224-.456-.5 0-.245.161-.45.374-.492l.082-.008h9.247l-1.585-1.584c-.174-.174-.193-.443-.058-.638l.058-.07c.174-.173.443-.192.638-.057z"
                                transform="translate(54 3)"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
