import React from 'react';
import PropTypes from 'prop-types';
import { H1, BodyText } from 'components/atoms/Typography';
import styled from 'styled-components';
import { storyblokEditable } from '@storyblok/react';

const TextContainer = styled.div`
    width: 100%;
    max-width: 412px;
`;

const PageHeader = ({ blok }) => {
    return (
        <section
            className="container overflow-hidden mb-md pt-6 lg:pt-16"
            {...storyblokEditable(blok)}
        >
            <div className="grid lg:gap-x-40px gap-y-80px grid-cols-3">
                <div className="col-span-3 lg:col-span-2">
                    <H1 maxWidth="400px">{blok.header}</H1>
                </div>
                {blok.text && (
                    <div className="hidden lg:block lg:col-span-1 ">
                        <div className="flex justify-end">
                            <TextContainer>
                                <BodyText>{blok.text}</BodyText>
                            </TextContainer>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

PageHeader.defaultProps = {
    blok: {},
};

PageHeader.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]),
};

export default PageHeader;
