import { getLineItemSampleType, roundToTwo } from 'helpers';

/**
 *
 * @param {Array} lineItems
 * @param {Number} lineItemsSubtotalPrice
 * @param {Number} tradeDiscountValue
 * @returns {Number} cartSubtotal
 */
export const getCartSubtotal = ({
    lineItems = [],
    lineItemsSubtotalPrice = 0,
    tradeDiscountValue = 0,
}) => {
    let subtotalDiscount = 0;
    lineItems?.map(item => {
        let totalDiscountedAmount = 0;
        for (let discount of item.discountAllocations) {
            if (discount.discountApplication.title.toUpperCase() !== 'TRADE DISCOUNT') {
                totalDiscountedAmount += Number(discount.allocatedAmount.amount);
            }
        }
        if (tradeDiscountValue && getLineItemSampleType(item) === 'non-sample') {
            totalDiscountedAmount +=
                (Number(item.variant.price.amount) * item.quantity * tradeDiscountValue) / 100;
        }

        if (totalDiscountedAmount) {
            subtotalDiscount += totalDiscountedAmount;
        }
    });
    return lineItemsSubtotalPrice?.amount
        ? roundToTwo(lineItemsSubtotalPrice?.amount - subtotalDiscount)
        : '0';
};

export const transformLineItemsToElevarProducts = ({
    lineItems = [],
    tradeDiscountValue = 0,
    dl_event = 'dl_user_data',
    list = '',
}) => {
    /** Products Array **/
    const elevarProducts = lineItems.map((lineItem, index) => {
        const compareAtPrice = Number(
            lineItem.compareAtPrice?.amount || lineItem.variant.price.amount
        );
        const price = lineItem.title?.toLowerCase()?.includes(', sample')
            ? 0
            : (Number(lineItem.variant.price.amount) * (100 - tradeDiscountValue)) / 100;
        return {
            id: lineItem?.variant?.sku || lineItem.variant.id, // SKU
            name: `${lineItem.title}${
                lineItem.variant.title !== 'Default Title' ? ` - ${lineItem.variant.title}` : ''
            }`, // Product title
            /* Brand not availabe in lineItems */
            brand: 'Zia Tile',
            /* Collection not availabe in lineItems */
            category: '',

            variant:
                lineItem.variant.title !== 'Default Title' ? lineItem.variant.title : 'Default',
            price: roundToTwo(price),
            ...(dl_event !== 'dl_select_item' &&
                dl_event !== 'dl_view_item' && {
                    quantity: String(lineItem.quantity), // Not required for dl_select_item & dl_view_item
                }),
            ...(dl_event === 'dl_select_item' && {
                position: index + 1, // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1
            }),
            list, // The list the product was discovered from
            product_id: lineItem.variant.product.id.replace('gid://shopify/Product/', ''), // The product_id
            variant_id: lineItem.variant.id.replace('gid://shopify/ProductVariant/', ''), // id or variant_id
            compare_at_price: compareAtPrice > price ? roundToTwo(compareAtPrice) : '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"})
            image: (lineItem.variant.image?.src ?? '').replace('https:', ''), // If available, otherwise use an empty string
            ...(dl_event === 'dl_add_to_cart' && {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${
                    lineItem.variant.product.handle
                }?variant=${lineItem.variant.id.replace('gid://shopify/ProductVariant/', '')}`, // URL for the Product Page. Only required for dl_add_to_cart.
            }),
        };
    });
    return elevarProducts;
};

export const transformToElevarUserProperties = ({ customer = {}, customerLoggedIn = false }) => {
    return {
        // The following fields aren't required if unavailable
        ...(customer && {
            ...(customer?.defaultAddress && {
                customer_address_1: customer?.defaultAddress.address1 || '',
                customer_address_2: customer?.defaultAddress.address2 || '',
                customer_city: customer?.defaultAddress.city || '',
                customer_province: customer?.defaultAddress.province || '',
                customer_province_code: customer?.defaultAddress.provinceCode || '',
                customer_zip: customer?.defaultAddress.zip || '',
                customer_country: customer?.defaultAddress.country || '',
            }),
            customer_email: customer?.email || '',
            customer_first_name: customer?.firstName || '',
            customer_id: customer?.id?.replace('gid://shopify/Customer/', '') || '',
            customer_last_name: customer?.lastName || '',
            customer_phone: customer?.phone || '',
            customer_order_count: customer?.numberOfOrders || '',
            customer_tags: customer?.tags?.join(',') || '',
            /* We need to calculate total spent so just ignored it */
            // customer_total_spent: '0.0',
        }),

        // The following fields are required
        user_consent: '', // Use an empty string
        visitor_type: customerLoggedIn ? 'logged_in' : 'guest', // "logged_in" || "guest"
    };
};

export const transformProductsToElevarImpressions = ({
    products = [],
    list = '',
    tradeDiscountValue = 0,
    dl_event = 'dl_view_item_list',
    startIndex = 1,
}) => {
    /** Products Array **/
    const elevarImpressions = products?.map((product, index) => {
        const compareAtPrice = Number(
            product.selectedOrFirstAvailableVariant.compareAtPrice?.amount || product.price
        );
        const price = (Number(product.price) * (100 - tradeDiscountValue)) / 100;
        return {
            id: product.selectedOrFirstAvailableVariant.sku || product.handle, // SKU
            name: product.title, // Product title
            brand: product.vendor || 'Zia Tile',
            category: product.collections.map(({ title }) => title)?.join(',') || '',
            variant:
                product.selectedOrFirstAvailableVariant.title !== 'Default Title'
                    ? product.selectedOrFirstAvailableVariant.title
                    : 'Default',
            price: roundToTwo(price),
            list, // Not required for dl_view_cart; The list the product is displayed in
            product_id: product.id.replace('gid://shopify/Product/', ''), // The product_id
            variant_id:
                product.selectedOrFirstAvailableVariant.id.replace(
                    'gid://shopify/ProductVariant/',
                    ''
                ) || product.id.replace('gid://shopify/Product/', ''), // id or variant_id
            compare_at_price: compareAtPrice > price ? roundToTwo(compareAtPrice) : '0.0', // If available
            position: startIndex + index, // position in the list of search results, collection views and position in cart indexed starting at 1
        };
    });
    return elevarImpressions;
};

export const transformLineItemsToElevarImpressions = ({
    lineItems = [],
    tradeDiscountValue = 0,
    dl_event = 'dl_view_cart',
    startIndex = 1,
    list = '',
}) => {
    /** Impressions Array **/
    // The impressions array must be less than 4000 characters.
    // The most logical way to limit this is by the number of products you send
    const elevarImpressions = lineItems?.map((lineItem, index) => {
        const compareAtPrice = Number(
            lineItem.compareAtPrice?.amount || lineItem.variant.price.amount
        );
        const price = lineItem.title?.toLowerCase()?.includes(', sample')
            ? 0
            : (Number(lineItem.variant.price.amount) * (100 - tradeDiscountValue)) / 100;
        return {
            id: lineItem?.variant?.sku || lineItem.variant.id, // SKU
            name: `${lineItem.title}${
                lineItem.variant.title !== 'Default Title' ? ` - ${lineItem.variant.title}` : ''
            }`, // Product title
            /* Brand not availabe in lineItems */
            brand: 'Zia Tile',
            /* Collection not availabe in lineItems */
            category: '',
            variant:
                lineItem.variant.title !== 'Default Title' ? lineItem.variant.title : 'Default',
            price: roundToTwo(price),
            ...(dl_event === 'dl_view_cart' && {
                quantity: String(lineItem.quantity), // Only required for dl_view_cart
            }),
            ...(dl_event !== 'dl_view_cart' && {
                list, // Not required for dl_view_cart; The list the product is displayed in
            }),
            product_id: lineItem.variant.product.id.replace('gid://shopify/Product/', ''), // The product_id
            variant_id: lineItem.variant.id.replace('gid://shopify/ProductVariant/', ''), // id or variant_id
            compare_at_price: compareAtPrice > price ? roundToTwo(compareAtPrice) : '0.0', // If available
            position: startIndex + index, // position in the list of search results, collection views and position in cart indexed starting at 1
            ...(dl_event === 'dl_view_cart' && {
                url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${
                    lineItem.variant.product.handle
                }?variant=${lineItem.variant.id.replace('gid://shopify/ProductVariant/', '')}`, // URL for the Product Page. Only required for dl_add_to_cart.
            }),
        };
    });
    return elevarImpressions;
};

export const transformProductsToElevarProducts = ({
    products = [],
    selectedVariant,
    tradeDiscountValue = 0,
    dl_event = 'dl_view_item',
    startIndex = 1,
    quantity = '1',
    list = '',
}) => {
    /** Products Array **/
    const elevarProducts = products?.map((product, index) => {
        const selectedOrFirstAvailableVariant = selectedVariant || product.variants[0];
        const compareAtPrice = Number(
            selectedOrFirstAvailableVariant.compareAtPrice || selectedOrFirstAvailableVariant.price
        );
        const price =
            (Number(selectedOrFirstAvailableVariant.price) * (100 - tradeDiscountValue)) / 100;
        return {
            id: selectedOrFirstAvailableVariant.sku || product.handle, // SKU
            name: product.title, // Product title
            brand: product.vendor || 'Zia Tile',
            category:
                product.collections
                    ?.slice(0, 5)
                    ?.map(({ title }) => title)
                    ?.join(',') || '',
            variant:
                selectedOrFirstAvailableVariant.title !== 'Default Title'
                    ? selectedOrFirstAvailableVariant.title
                    : 'Default',
            price: roundToTwo(price),
            ...(dl_event !== 'dl_select_item' &&
                dl_event !== 'dl_view_item' && {
                    quantity: String(quantity), // Not required for dl_select_item & dl_view_item
                }),
            ...(dl_event === 'dl_select_item' && {
                position: index + startIndex, // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1
            }),
            list, // The list the product was discovered from
            product_id: product.id.replace('gid://shopify/Product/', ''), // The product_id
            variant_id:
                selectedOrFirstAvailableVariant.id.replace('gid://shopify/ProductVariant/', '') ||
                product.id.replace('gid://shopify/Product/', ''), // id or variant_id
            compare_at_price: compareAtPrice > price ? roundToTwo(compareAtPrice) : '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
            image: (
                selectedOrFirstAvailableVariant.image?.src ??
                product.images?.[0]?.src ??
                ''
            ).replace('https:', ''), // If available, otherwise use an empty string
            url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${product.handle}`, // URL for the Product Page. Only required for dl_add_to_cart.
        };
    });
    return elevarProducts;
};

export const transformHitsToElevarImpressions = ({
    hits,
    dl_event = 'dl_view_search_results',
    tradeDiscountValue = 0,
    list = '',
}) => {
    /** Impressions Array **/
    // The impressions array must be less than 4000 characters.
    // The most logical way to limit this is by the number of products you send
    const elevarImpressions = hits?.map(hit => {
        const compareAtPrice = Number(hit.variants_compare_at_price_min || hit.variants_min_price);
        const price = (Number(hit.variants_min_price) * (100 - tradeDiscountValue)) / 100;
        return {
            id: hit.sku || String(hit.id), // SKU
            name: hit.title, // Product title
            brand: hit.vendor || 'Zia Tile',
            // Search hits doesn't have collection data
            category: '',
            variant: hit.variant_title !== 'Default Title' ? hit.variant_title : 'Default',
            price: roundToTwo(price),
            ...(dl_event !== 'dl_view_cart' && {
                list: dl_event === 'dl_view_search_results' ? '/search' : list,
            }),
            product_id: String(hit.id), // The product_id
            variant_id: hit.objectID, // id or variant_id
            compare_at_price: compareAtPrice > price ? roundToTwo(compareAtPrice) : '0.0', // If available
            position: hit.__position, // position in the list of search results, collection views and position in cart indexed starting at 1
        };
    });
    return elevarImpressions;
};
