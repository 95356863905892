import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--pinterest" width="18" height="18" viewBox="0 0 16 16" {...attrs}>
            <path
                d="M2 8C2 10.5615 3.6055 12.7485 5.865 13.609C5.81 13.1405 5.7515 12.368 5.8775 11.826C5.986 11.36 6.578 8.857 6.578 8.857C6.578 8.857 6.3995 8.4995 6.3995 7.97C6.3995 7.14 6.8805 6.52 7.48 6.52C7.99 6.52 8.236 6.9025 8.236 7.361C8.236 7.8735 7.9095 8.6395 7.741 9.35C7.6005 9.9445 8.0395 10.4295 8.6255 10.4295C9.687 10.4295 10.5035 9.31 10.5035 7.694C10.5035 6.2635 9.4755 5.264 8.008 5.264C6.309 5.264 5.3115 6.5385 5.3115 7.856C5.3115 8.3695 5.509 8.9195 5.756 9.219C5.77706 9.24162 5.79194 9.26928 5.79921 9.29932C5.80647 9.32935 5.80589 9.36076 5.7975 9.3905C5.752 9.5795 5.651 9.985 5.6315 10.068C5.605 10.177 5.545 10.2005 5.4315 10.1475C4.6855 9.8005 4.2195 8.71 4.2195 7.834C4.2195 5.9495 5.588 4.2195 8.1655 4.2195C10.2375 4.2195 11.848 5.696 11.848 7.669C11.848 9.7275 10.5505 11.3845 8.7485 11.3845C8.143 11.3845 7.5745 11.0695 7.3795 10.698C7.3795 10.698 7.08 11.839 7.0075 12.118C6.8665 12.66 6.4755 13.346 6.233 13.7355C6.792 13.9075 7.385 14 8 14C11.3135 14 14 11.3135 14 8C14 4.6865 11.3135 2 8 2C4.6865 2 2 4.6865 2 8Z"
                fill={fill}
            />
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
