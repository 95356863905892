import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--shipment" viewBox="0 0 56 50" {...attrs}>
            <g fill="none" stroke={fill}>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.03853 24H27.584V49H3.03853C1.68292 49 0.583984 47.8807 0.583984 46.5V26.5C0.583984 25.1193 1.68292 24 3.03853 24Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.584 24H52.1294C53.485 24 54.584 25.1193 54.584 26.5V46.5C54.584 47.8807 53.485 49 52.1294 49H27.584V24Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M40.584 24H13.584V3.55556C13.584 2.14416 14.6829 1 16.0385 1H38.1294C39.485 1 40.584 2.14416 40.584 3.55556V24Z"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M20.584 1V10.7684C20.5846 11.242 20.8491 11.6734 21.2644 11.8784C21.6798 12.0834 22.1727 12.0259 22.5328 11.7304L26.584 8.40028L30.6352 11.7304C30.9953 12.0259 31.4882 12.0834 31.9035 11.8784C32.3189 11.6734 32.5833 11.242 32.584 10.7684V1"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M8.58398 24V33.7684C8.58464 34.242 8.84909 34.6734 9.26445 34.8784C9.67981 35.0834 10.1727 35.0259 10.5328 34.7304L14.584 31.4003L18.6352 34.7304C18.9953 35.0259 19.4882 35.0834 19.9035 34.8784C20.3189 34.6734 20.5833 34.242 20.584 33.7684V24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
                <path
                    d="M34.584 24V33.7684C34.5846 34.242 34.8491 34.6734 35.2644 34.8784C35.6798 35.0834 36.1727 35.0259 36.5328 34.7304L40.584 31.4003L44.6352 34.7304C44.9953 35.0259 45.4882 35.0834 45.9035 34.8784C46.3189 34.6734 46.5833 34.242 46.584 33.7684V24"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
