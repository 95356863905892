import Cookies from 'js-cookie';

const removeUTMParameters = urlString => {
    const url = new URL(urlString);
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];

    utmParams.forEach(param => url.searchParams.delete(param));

    return url.toString();
};

export const captureUTMParameters = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const utmParameters = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
    const cookiesToSet = [];

    // Capture UTM parameters from the query string and remove corresponding cookies
    utmParameters.forEach(param => {
        const paramValue = urlParams.get(param);
        if (paramValue) {
            Cookies.remove(param);
            cookiesToSet.push({ name: param, value: paramValue });
        }
    });

    if (cookiesToSet.length > 0) {
        Cookies.set('persist_utm', true, { expires: 30 });
        const initialPageURL = removeUTMParameters(window.location.href);
        Cookies.set('event_source_url', initialPageURL, { expires: 30 });
        Cookies.set('event_time', new Date().getTime(), { expires: 30 });
    } else {
        Cookies.remove('persist_utm');
    }

    // Set UTM parameters as cookies with a 30-day expiration
    cookiesToSet.forEach(cookie => {
        Cookies.set(cookie.name, cookie.value, { expires: 30 });
    });
};
