import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--subway"
                width="120"
                height="40"
                viewBox="0 0 336.1 110.3"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <rect x="0.6" y="34.8" width="300" height="75" />
                <line x1="0.6" y1="7.6" x2="300.6" y2="7.6" />
                <line x1="300.6" y1="0" x2="300.6" y2="15.2" />
                <line x1="0.6" y1="0" x2="0.6" y2="15.2" />
                <line x1="328.6" y1="34.3" x2="328.6" y2="110.3" />
                <line x1="336.1" y1="110.3" x2="321" y2="110.3" />
                <line x1="336.1" y1="34.3" x2="321" y2="34.3" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
