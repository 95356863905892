import PropTypes from 'prop-types';

const CaretDownIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--caretDown" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-647 -40) translate(39.878 40) translate(484.122) translate(85)">
                    <g fill={fill} fillRule="nonzero">
                        <path
                            d="M12.142.151c.192-.198.509-.202.707-.01.176.172.199.44.066.638l-.057.07-5.717 5.879c-.168.174-.4.272-.641.272-.201 0-.395-.068-.552-.192l-.09-.08L.143.848C-.051.652-.047.335.152.143.326-.03.596-.045.79.092l.068.06 5.642 5.8 5.642-5.8z"
                            transform="translate(38) translate(1.5 4.5)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

CaretDownIcon.defaultProps = {
    fill: 'currentColor',
};

CaretDownIcon.propTypes = {
    fill: PropTypes.string,
};

export default CaretDownIcon;
