import { APPS } from './constants/general';

const LOCALSTORAGE_KEY = 'ziafrontend-debug';
let loggingEnabled = true;

try {
    loggingEnabled = !!localStorage.getItem(LOCALSTORAGE_KEY);
} catch {
    //
}

const getAppPrefix = (app = APPS.FRONTEND) => {
    return app ? `${app}: ` : 'Could not determine widget/app: ';
};

export const logInfo = (message, app = APPS.FRONTEND, ...rest) => {
    if (!loggingEnabled) {
        return;
    }
    const prefixedMessage = typeof message === 'string' ? getAppPrefix(app) + message : message;
    console.info(prefixedMessage, ...rest);
};

export const logTable = (message, app = APPS.FRONTEND, ...rest) => {
    if (!loggingEnabled) {
        return;
    }
    const prefixedMessage = typeof message === 'string' ? getAppPrefix(app) + message : message;
    console.table(prefixedMessage, ...rest);
};

if (typeof window !== 'undefined') {
    window.ZiaFrontendEnableDebug = () => {
        localStorage.setItem(LOCALSTORAGE_KEY, String(true));
        loggingEnabled = true;
        logInfo('Enabled console output 🔧', APPS.FRONTEND);
    };
}

export const logError = (message, app = APPS.FRONTEND, ...rest) => {
    const errorMessage = typeof message === 'string' ? getAppPrefix(app) + message : message;
    console.error(errorMessage, ...rest);
};
