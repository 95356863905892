import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';

const accordionStore = immer(set => ({
    pages: 1,
    currentPage: 1,
    setPages: pages =>
        set(draft => {
            draft.pages = pages;
        }),
    nextPage: () =>
        set(draft => {
            if (draft.currentPage < draft.pages) {
                ++draft.currentPage;
            }
        }),
}));

const useAccordionStore = create(devtools(accordionStore));

export default useAccordionStore;
