import PropTypes from 'prop-types';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';
import { render } from 'storyblok-rich-text-react-renderer';

import Section from 'components/atoms/Container';
import { BodyText, H1, H2, Rte } from 'components/atoms/Typography';
import { MinusIcon, PlusIcon } from 'components/atoms/Icons';
import { useEffect, useMemo } from 'react';
import { useAccordionStore } from './components';
import Button from 'components/atoms/Button';
import Image from 'components/atoms/Image';
import Component from '../Component';
import DynamicIcon from 'components/atoms/Icons/DynamicIcon';
import { storyblokEditable } from '@storyblok/react';

const SectionStyled = styled(Section)`
    .Collapsible__contentOuter {
        border-style: solid;
        border-width: 0 0 1px;
    }
    ${props =>
        props.layout === 'v1'
            ? `
        .Collapsible__trigger {
            .Collapsible__icon {
                border: 1px solid;
            }
            &:not(.is-open) {
                + .Collapsible__contentOuter,
                .Collapsible__icon {
                    border-color: #cccccc;
                }
                .Collapsible__heading {
                    opacity: 0.5;
                }
            }
            &.is-open,
            :hover {
                + .Collapsible__contentOuter,
                .Collapsible__icon {
                    border-color: #000;
                }
                .Collapsible__heading {
                    opacity: 1;
                }
            }
        }
    `
            : `
        .Collapsible__trigger {
            .Collapsible__icon {
                border: 1px solid;
            }
            &:not(.is-open) {
                + .Collapsible__contentOuter,
                .Collapsible__icon {
                    border-color: #cccccc;
                }
            }
            &.is-open,
            :hover {
                + .Collapsible__contentOuter,
                .Collapsible__icon {
                    border-color: #000;
                }
            }
        }
    `}
    .icon {
        width: 60px;
        height: auto;
    }
`;
const Headings = {
    h1: H1,
    h2: H2,
};
const Accordion = ({ blok }) => {
    const { pages, setPages, currentPage, nextPage } = useAccordionStore(
        ({ pages, setPages, currentPage, nextPage }) => ({ pages, setPages, currentPage, nextPage })
    );
    const version = useMemo(() => {
        return blok?.version || 'v1';
    }, [blok]);
    const chunkSections = useMemo(() => {
        const perChunk = 8;
        const chunks = blok.sections.reduce((resultArray, item, index) => {
            const chunkIndex = Math.floor(index / perChunk);

            if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
            }

            resultArray[chunkIndex].push(item);

            return resultArray;
        }, []);

        // const chunks = chunk(blok?.sections || [], 8);
        return chunks;
    }, [blok]);

    useEffect(() => {
        setPages(chunkSections?.length > 0 ? chunkSections.length : 1);
    }, [chunkSections]);

    const scrollToTarget = selector => {
        var element = document.querySelector(selector);
        var headerOffset = getComputedStyle(document.documentElement)
            .getPropertyValue('--header-height')
            .replace(/\D/g, '');
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth',
        });
    };

    const attachEventListener = () => {
        const selectors = ['[data-action="expand-accordion"], [href^="openaccordion:#"]'];
        const accordionHandler = event => {
            let target = event.target;
            if (!target) {
                // event.target is null
                return;
            }

            if (!target.matches(selectors)) {
                target = target.closest(selectors);
            }

            if (!target) {
                // target.closest is null
                return;
            }

            if (target.matches(selectors)) {
                event.preventDefault();
                event.stopPropagation();
                event.stopImmediatePropagation();
                const ariaControls = target.getAttribute('href')?.includes('openaccordion:#')
                    ? target.getAttribute('href').replace('openaccordion:#', '')
                    : target.getAttribute('aria-controls');
                if (ariaControls && document.querySelector(`#${ariaControls}`)) {
                    scrollToTarget(`#${ariaControls}`);

                    document.querySelector(`#${ariaControls}[aria-hidden="true"]`)?.click();
                }
            }
        };
        document.addEventListener('click', accordionHandler);

        return () => {
            document.removeEventListener('click', accordionHandler);
        };
    };

    const handleOpenOnLoad = () => {
        const hash = window.location.hash;

        if (hash && document.querySelector(hash)) {
            scrollToTarget(hash);

            document.querySelector(`${hash}[aria-hidden="true"]`)?.click();
        }
    };

    useEffect(() => {
        attachEventListener();
        handleOpenOnLoad();
    }, []);

    const Heading = Headings[blok?.headingTag || 'h2'] || H2;
    return (
        <SectionStyled
            id={blok.id}
            className={`container ${
                blok.css_class ? blok.css_class : 'mt-9 lg:mt-16 mb-9 lg:mb-16'
            }`}
            version={version}
            {...storyblokEditable(blok)}
        >
            {blok?.heading && (
                <div
                    className={`${
                        version === 'v1' ? 'mb-20' : 'mb-8'
                    } grid sm:grid-cols-6 lg:grid-cols-12`}
                >
                    <Heading
                        className={`col-span-6  ${
                            version === 'v2' ? 'col-start-4 text-center' : ''
                        }`}
                    >
                        {blok.heading}
                    </Heading>
                </div>
            )}
            {blok?.bannerImage?.filename && (
                <Image
                    className={`w-full mb-14`}
                    width={1200}
                    height={400}
                    src={blok.bannerImage.filename}
                    alt={blok.bannerImage.alt || ''}
                />
            )}
            <div className="border-t border-gray-300">
                {chunkSections?.map(
                    (sections, index) =>
                        index < currentPage &&
                        sections.map(section => {
                            return (
                                <div key={section._uid} {...storyblokEditable(section)}>
                                    <Collapsible
                                        className=""
                                        trigger={
                                            <TriggerElement
                                                id={section?.id || section._uid}
                                                title={section.title}
                                                icon={section.icon}
                                            />
                                        }
                                        triggerElementProps={{
                                            id: `collapsible-trigger-${
                                                section?.id || section._uid
                                            }`,
                                        }}
                                        contentElementId={`collapsible-content-${
                                            section?.id || section._uid
                                        }`}
                                        triggerWhenOpen={
                                            <TriggerElement
                                                id={section?.id || section._uid}
                                                title={section.title}
                                                icon={section.icon}
                                                isOpen
                                            />
                                        }
                                        easing="ease-in-out"
                                    >
                                        <div className="description pt-xs pb-md grid grid-cols-12">
                                            {version === 'v1' && (
                                                <div className="hidden md:block col-span-6" />
                                            )}
                                            <div className="col-span-12 lg:col-span-10 xl:col-span-9 2xl:col-span-7">
                                                <Rte
                                                    {...(version === 'v1' && { color: '#7f7f7f' })}
                                                >
                                                    {render(section.description, {
                                                        blokResolvers: {
                                                            ['component']: props => (
                                                                <Component {...props} />
                                                            ),
                                                        },
                                                        nodeResolvers: {
                                                            ['emoji']: (children, props) => {
                                                                if (
                                                                    !props?.fallbackImage &&
                                                                    !props?.emoji
                                                                )
                                                                    return null;
                                                                return (
                                                                    <span
                                                                        className={`${
                                                                            props.emoji
                                                                                ? 'align-middle'
                                                                                : 'align-text-bottom'
                                                                        }`}
                                                                        role="img"
                                                                        aria-label={props.name}
                                                                    >
                                                                        {props.emoji ? (
                                                                            <span className="text-icon text-black">
                                                                                {props.emoji}
                                                                            </span>
                                                                        ) : (
                                                                            <img
                                                                                className="inline-block"
                                                                                src={
                                                                                    props.fallbackImage
                                                                                }
                                                                                alt={props.name}
                                                                                width="1em"
                                                                                height="1em"
                                                                                style={{
                                                                                    width: '1em',
                                                                                    height: '1em',
                                                                                }}
                                                                            />
                                                                        )}
                                                                    </span>
                                                                );
                                                            },
                                                        },
                                                    })}
                                                </Rte>
                                            </div>
                                        </div>
                                    </Collapsible>
                                </div>
                            );
                        })
                )}
            </div>
            {currentPage < pages && (
                <Button
                    onClick={nextPage}
                    className="mt-10 mx-auto w-full"
                    type="primary"
                    label={blok?.loadMoreButtonText || 'Load more'}
                    maxWidth="285px"
                />
            )}
        </SectionStyled>
    );
};

Accordion.defaultProps = {
    blok: {},
};

Accordion.propTypes = {
    blok: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export const TriggerElement = ({ id, title, icon, isOpen }) => {
    return (
        <button
            id={id}
            className="group w-full text-left flex justify-between items-center py-m-md"
            aria-hidden={!isOpen}
            style={{
                '-webkit-tap-highlight-color': 'transparent',
            }}
        >
            <div className="flex-1 flex flex-col md:flex-row md:items-center">
                {icon && typeof icon === 'string' && (
                    <div className="mr-sm">
                        <DynamicIcon icon={icon} />
                    </div>
                )}
                {title && (
                    <BodyText
                        as="div"
                        className="Collapsible__heading mt-m-sm md:mt-0 flex-1 flex flex-wrap"
                        fontSize="24px"
                    >
                        <h4 className="block flex-1">{title}</h4>
                        <div className="Collapsible__icon h-30px w-30px shrink-0 flex justify-center items-center">
                            {isOpen ? <MinusIcon /> : <PlusIcon />}
                        </div>
                    </BodyText>
                )}
            </div>
        </button>
    );
};

TriggerElement.defaultProps = {
    title: null,
    icon: null,
    isOpen: false,
};

TriggerElement.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isOpen: PropTypes.bool,
};

export default Accordion;
