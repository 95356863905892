import PropTypes from 'prop-types';

const AccountIcon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--account" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-1308 -40) translate(39.878 40)">
                    <path
                        fill={fill}
                        fillRule="nonzero"
                        d="M7.977 8.494c3.846 0 6.963 3.118 6.963 6.963 0 .277-.224.5-.5.5s-.5-.223-.5-.5c0-3.293-2.67-5.963-5.963-5.963s-5.963 2.67-5.963 5.963c0 .277-.224.5-.5.5s-.5-.223-.5-.5c0-3.845 3.118-6.963 6.963-6.963zm0-8.451c2.198 0 3.98 1.782 3.98 3.98s-1.782 3.98-3.98 3.98-3.98-1.782-3.98-3.98S5.78.043 7.977.043zm0 1c-1.646 0-2.98 1.334-2.98 2.98s1.334 2.98 2.98 2.98 2.98-1.334 2.98-2.98-1.334-2.98-2.98-2.98z"
                        transform="translate(1268.122)"
                    />
                </g>
            </g>
        </svg>
    );
};

AccountIcon.defaultProps = {
    fill: 'currentColor',
};

AccountIcon.propTypes = {
    fill: PropTypes.string,
};

export default AccountIcon;
