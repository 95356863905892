import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--burger" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-30 -30)">
                    <path
                        fill={fill}
                        d="M16 12v1H0v-1h16zm-4-4v1H0V8h12zm4-4v1H0V4h16z"
                        transform="translate(30 30)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
