import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, makeStyles } from '@material-ui/core';
import { BodyText, H2 } from 'components/atoms/Typography';
import { CheckV2Icon, CloseV2Icon } from 'components/atoms/Icons';
import Slider from 'components/molecules/Slider';
import styled from 'styled-components';
import breakpoints from 'styles/breakpoints';
import { getUsagesColumns } from 'helpers/constants/product';
import SiteContext from 'context/SiteContext';

const SliderWrapper = styled.div`
    .slick-list {
        overflow: visible;
    }
    .slick-track {
        display: flex;
    }
    .slick-slide {
        height: inherit;
        > div {
            height: 100%;
        }
    }
    .slick-dots {
        width: 100%;
        height: 2px;
        bottom: -36px;
        @media ${breakpoints.maxLg} {
            display: flex !important;
        }
        > li {
            flex: 1;
            width: auto;
            margin: 0;
            button {
                width: 100%;
                height: 2px;
                padding: 0;
                &:before {
                    content: '';
                    width: 100%;
                    height: 2px;
                    background: #000;
                }
            }
        }
    }
    .usage-card {
        @media ${breakpoints.maxLg} {
            width: 200px;
        }
    }
`;

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <SliderWrapper
            className="pt-5 lg:pt-7 -mx-m-md px-m-md"
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && children}
        </SliderWrapper>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
    },
    tabsIndicator: {
        backgroundColor: 'black',
    },
    tabRoot: {
        textTransform: 'none',
        paddingInline: '24px',
        paddingBlock: '12px',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: 1,
        letterSpacing: '-0.1px',
        minHeight: '43px',
        fontFamily: 'var(--heading-font-family)',
    },
    tabTextColorInherit: {
        color: '#999',
        opacity: 1,
    },
    tabSelected: {
        color: '#000',
    },
}));

export default function TileUsage({ blok }) {
    const {
        store: {
            settings: { tileUsages },
        },
    } = useContext(SiteContext);

    const classes = useStyles();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const settings = {
        dots: false,
        arrows: false,
        adaptiveHeight: false,
        draggable: true,
        swipe: true,
        swipeToSlide: true,
        infinite: false,
        speed: 500,
        fade: false,
        slidesToShow: 6,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    variableWidth: true,
                },
            },
        ],
        // slidesPerRow: 6,
    };

    if (!blok.productTemplate || !tileUsages?.length > 0) {
        return null;
    }

    return (
        <div className={`${classes.root} my-14 lg:my-24 pb-9 lg:pb-0 overflow-hidden`}>
            <div className="container">
                <H2 className="mb-6">Tile Usage</H2>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="Tile usage"
                    classes={{ indicator: classes.tabsIndicator }}
                >
                    <Tab
                        label="Residential Usage"
                        {...a11yProps(0)}
                        classes={{
                            root: classes.tabRoot,
                            textColorInherit: classes.tabTextColorInherit,
                            selected: classes.tabSelected,
                        }}
                    />
                    <Tab
                        label="Commercial Usage"
                        {...a11yProps(1)}
                        classes={{
                            root: classes.tabRoot,
                            textColorInherit: classes.tabTextColorInherit,
                            selected: classes.tabSelected,
                        }}
                    />
                </Tabs>
                <TabPanel value={value} index={0}>
                    <Slider settings={settings}>
                        {getUsagesColumns(blok.productTemplate, tileUsages).map(
                            (usagesColumn, index) => (
                                <div key={index} className="px-3 h-full">
                                    <div className="usage-card h-full grid grid-rows-2 gap-y-4 lg:gap-y-7">
                                        {usagesColumn.map(({ title, text, usable }) => (
                                            <div
                                                key={title}
                                                className="grid gap-2 place-items-center content-between text-center"
                                            >
                                                <div>
                                                    <BodyText
                                                        fontSize="14px"
                                                        fontWeight="500"
                                                        margin="0 0 8px"
                                                    >
                                                        {title}
                                                    </BodyText>
                                                    <BodyText
                                                        fontSize="12px"
                                                        fontWeight="400"
                                                        color="#7F7F7F"
                                                    >
                                                        {text}
                                                    </BodyText>
                                                </div>
                                                {usable.includes('residential') ? (
                                                    <CheckV2Icon className="mt-auto" />
                                                ) : (
                                                    <CloseV2Icon className="mt-auto" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        )}
                    </Slider>
                </TabPanel>
                <TabPanel value={value} index={1}>
                    <Slider settings={settings}>
                        {getUsagesColumns(blok.productTemplate, tileUsages).map(
                            (usagesColumn, index) => (
                                <div key={index} className="px-3 h-full">
                                    <div className="usage-card h-full grid grid-rows-2 gap-y-4 lg:gap-y-7">
                                        {usagesColumn.map(({ title, text, usable }) => (
                                            <div
                                                key={title}
                                                className="grid gap-2 place-items-center content-between text-center"
                                            >
                                                <div>
                                                    <BodyText
                                                        fontSize="14px"
                                                        fontWeight="500"
                                                        margin="0 0 8px"
                                                    >
                                                        {title}
                                                    </BodyText>
                                                    <BodyText
                                                        fontSize="12px"
                                                        fontWeight="400"
                                                        color="#7F7F7F"
                                                    >
                                                        {text}
                                                    </BodyText>
                                                </div>
                                                {usable.includes('commercial') ? (
                                                    <CheckV2Icon className="mt-auto" />
                                                ) : (
                                                    <CloseV2Icon className="mt-auto" />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )
                        )}
                    </Slider>
                </TabPanel>
            </div>
        </div>
    );
}

TileUsage.propTypes = {
    blok: PropTypes.object,
};
