import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--trapezoid"
                width="120"
                height="50"
                viewBox="0 0 502.73 211.26"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="449.11 69.22 450.68 207.16 3.66 210.47 .93 131.21 449.11 69.22" />
                <line x1="492.54" y1="67.75" x2="492.54" y2="208.98" />
                <line x1="482.96" y1="67.49" x2="502.73" y2="67.49" />
                <line x1="482.59" y1="208.72" x2="502.36" y2="208.72" />
                <line x1="449.23" y1="9.02" x2="1.23" y2="10.78" />
                <line x1="449.1" y1=".37" x2="449.1" y2="20.14" />
                <line x1=".5" x2=".5" y2="19.77" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
