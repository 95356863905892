import Cookies from 'js-cookie';
import { default as NextLink } from 'next/link';
import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isInternalLink } from 'helpers';

const Link = ({ href = '', ...attrs }) => {
    const [url, setUrl] = useState(href);
    const persistUtm = Cookies.get('persist_utm');

    const getHrefWithUtmParams = useCallback(() => {
        if (!(persistUtm && isInternalLink(href))) {
            return href;
        }

        const utmParameters = [
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
        ];

        const queryParams = [];

        utmParameters.forEach(param => {
            const value = Cookies.get(param);
            if (value) {
                queryParams.push(`${param}=${value}`);
            }
        });

        if (queryParams.length === 0) {
            return href;
        }

        // Split the href into pathname and hash
        const [pathname, hash] = href.split('#');

        const separator = pathname.includes('?') ? '&' : '?';
        const updatedUrl = `${pathname}${separator}${queryParams.join('&')}`;

        // Reattach the hash at the end
        const finalUrl = hash ? `${updatedUrl}#${hash}` : updatedUrl;

        return finalUrl;
    }, [persistUtm, href]);

    useEffect(() => {
        setUrl(getHrefWithUtmParams());
    }, [getHrefWithUtmParams]);

    if (!href) {
        return <NextLink href="#" {...attrs} />;
    }

    if (!url) return null;

    return <NextLink href={url} {...attrs} />;
};

Link.defaultProps = {
    href: '',
};

Link.propTypes = {
    href: PropTypes.string,
};

export default Link;
