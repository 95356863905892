import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--toltec"
                width="120"
                viewBox="0 0 355 203"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <path
                    d="M305.55,51.79c0,1.56-1.56,149.65-1.56,149.65L49.45,202,50,108.53Z"
                    transform="translate(0)"
                />
                <line x1="306.59" y1="6.25" x2="43.46" y2="7.55" />
                <line x1="306.33" y1="0.26" x2="306.33" y2="14.05" />
                <line x1="42.94" x2="42.94" y2="13.79" />
                <line x1="347.71" y1="52.57" x2="347.71" y2="201.44" />
                <line x1="341.21" y1="52.31" x2="355" y2="52.31" />
                <line x1="340.95" y1="201.18" x2="354.74" y2="201.18" />
                <line x1="7.03" y1="109.05" x2="7.03" y2="201.7" />
                <line x1="0.26" y1="108.79" x2="14.05" y2="108.79" />
                <line y1="201.44" x2="13.79" y2="201.44" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
