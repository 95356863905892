import Button from 'components/atoms/Button';
import RevealElement from 'components/atoms/RevealElement';
import PropTypes from 'prop-types';
import TabItem from './TabItem';
import useImageWithTextV2Store from './useImageWithTextV2Store';
import useTabPaneV2Store from './useTabPaneV2Store';
import { storyblokEditable } from '@storyblok/react';

const TabPaneV2 = ({ tab }) => {
    const { activeTab } = useImageWithTextV2Store(({ activeTab }) => ({
        activeTab,
    }));

    const { expanded, toggleExpandable } = useTabPaneV2Store(({ expanded, toggleExpandable }) => ({
        expanded,
        toggleExpandable,
    }));

    return activeTab === tab._uid ? (
        <>
            <ul className="grid grid-cols-6 lg:grid-cols-12 gap-10">
                {tab?.items?.map(({ _uid, ...item }, index) => (
                    <RevealElement
                        className={`col-span-full lg:col-span-4`}
                        key={_uid}
                        as="li"
                        delay={0.05 * index}
                        {...storyblokEditable(item)}
                    >
                        <TabItem
                            item={item}
                            index={index}
                            visible={index < 3 || expanded}
                            textLineClampMobile={3}
                            imageWidth={413}
                            imageHeight={400}
                        />
                    </RevealElement>
                ))}
            </ul>
            {tab?.items?.length > 3 && (
                <RevealElement start="top 90%">
                    <Button
                        className={`mx-auto w-full lg:w-48 mt-7`}
                        label={expanded ? 'Load less' : 'Load more'}
                        onClick={toggleExpandable}
                    />
                </RevealElement>
            )}
        </>
    ) : null;
};

TabPaneV2.propTypes = {
    tab: PropTypes.object,
};

export default TabPaneV2;
