import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--chevronLeft" width="30" height="30" {...attrs}>
            <path d="m22.5,0l-15,15l15,15" stroke={fill} fill="none"></path>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
