import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import produce from 'immer';

const tabPaneV2Store = set => ({
    expanded: false,
    toggleExpandable: () =>
        set(
            produce(draft => {
                draft.expanded = !draft.expanded;
            })
        ),
});

const useTabPaneV2Store = create(devtools(tabPaneV2Store));

export default useTabPaneV2Store;
