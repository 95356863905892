import styled from 'styled-components';

export const PDPIcon = styled.div`
    svg {
        position: relative;
        g {
            position: absolute;
            top: 30px;
            left: 0px;
        }
    }
`;
