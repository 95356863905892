import { ApolloClient, gql, InMemoryCache } from '@apollo/client';
import { RestLink } from 'apollo-link-rest';

const restLinkFourSixtyTimeline = new RestLink({ uri: process.env.NEXT_PUBLIC_FOURSIXTY_API_URL });

const fetchFourSixtyTimeline = new ApolloClient({
    link: restLinkFourSixtyTimeline,
    headers: {
        Accept: 'application/json',
    },
    cache: new InMemoryCache(),
});

export const getInstagramTimeline = async ({ page = 1 }) => {
    const { data } = await fetchFourSixtyTimeline.query({
        variables: {},
        query: gql`
            query {
                timeline @rest(type: "Timeline", path: "/?page=${page}") {
                    results
                }
            }
        `,
        fetchPolicy: 'network-only',
    });
    return {
        ...data,
    };
};
