import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon-arrowDown" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd">
                <g transform="translate(-30 -192)">
                    <g>
                        <path
                            fill={fill}
                            fillRule="nonzero"
                            d="M14.758 7.072l.02.013.036.026.003.002c.023.02.045.041.065.065.011.013.017.02.023.03l.012.015.011.019.004.006c.018.032.032.062.042.094l-.018-.047.01.024.003.007.002.008.01.031c.005.015.008.03.011.046l.007.05.001.027v.029l-.001.016-.006.051-.001.006-.003.013-.006.028-.009.027-.003.01-.004.012-.015.034.022-.056c-.01.032-.024.062-.04.09l-.002.004-.005.009-.02.03-.02.026c-.011.014-.024.027-.036.04l-.024.021-.005.004c-.017.015-.03.024-.042.032l-.022.014-.006.004c-.032.018-.062.032-.094.042l.045-.017-.022.01c-.002 0-.004 0-.007.002h-.007c-.657.221-1.318.64-1.99 1.268l-.223.217c-.749.748-1.24 1.484-1.48 2.204-.087.262-.37.404-.632.316-.262-.087-.404-.37-.316-.632.293-.88.869-1.744 1.72-2.596.28-.278.56-.528.841-.747L1.5 8c-.276 0-.5-.224-.5-.5 0-.245.177-.45.41-.492L1.5 7l11.088.002c-.282-.22-.562-.47-.842-.748-.851-.852-1.427-1.716-1.72-2.596-.088-.262.054-.545.316-.632.262-.088.545.054.632.316.24.72.731 1.456 1.48 2.204.748.749 1.484 1.24 2.204 1.48l.01.003.012.004.031.014-.053-.021c.032.01.062.024.09.04z"
                            transform="translate(30 192) rotate(90 8 7.5)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
