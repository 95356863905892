import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--lock" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round">
                <g stroke={fill}>
                    <path
                        strokeLinejoin="round"
                        d="M0 9.446c0 3.02 2.448 5.469 5.469 5.469 3.02 0 5.468-2.449 5.468-5.469 0-3.02-2.448-5.469-5.468-5.469S0 6.426 0 9.446z"
                        transform="translate(-701 -185) translate(701 143) translate(0 35) translate(0 7) translate(2.6 .543)"
                    />
                    <path
                        strokeLinejoin="round"
                        d="M1.989 5.227V3.48C1.989 1.558 3.547 0 5.469 0s3.48 1.558 3.48 3.48v1.747"
                        transform="translate(-701 -185) translate(701 143) translate(0 35) translate(0 7) translate(2.6 .543)"
                    />
                    <path
                        d="M5.469 8.452L5.469 10.44"
                        transform="translate(-701 -185) translate(701 143) translate(0 35) translate(0 7) translate(2.6 .543)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
