import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--notes"
            viewBox="0 0 48.000000 48.000000"
            width="16"
            height="16"
            {...attrs}
        >
            <g
                transform="translate(0.000000,48.000000) scale(0.100000,-0.100000)"
                fill={fill}
                stroke="none"
            >
                <path
                    d="M148 442 c-95 -12 -79 -24 27 -21 55 2 130 2 167 1 l66 -2 -6 -82
                    c-6 -97 -14 -107 -88 -108 l-51 0 -7 -38 c-10 -64 -36 -78 -138 -74 -80 3 -88
                    5 -88 23 0 11 5 30 11 41 13 24 29 229 19 241 -4 4 -12 -38 -19 -94 -6 -56
                    -18 -126 -27 -157 -14 -45 -14 -56 -3 -63 21 -13 197 -11 222 3 12 7 31 11 42
                    10 33 -4 87 39 111 88 25 50 51 207 36 222 -12 12 -200 18 -274 10z m206 -247
                    c-16 -25 -81 -71 -89 -63 -3 2 0 18 7 35 9 23 20 32 52 40 23 5 42 11 44 12 1
                    0 -5 -10 -14 -24z"
                />
                <path
                    d="M440 390 c0 -5 5 -10 10 -10 6 0 10 -12 10 -27 0 -35 -58 -243 -72
                    -260 -12 -15 -32 -18 -23 -3 8 13 -4 50 -16 50 -6 0 -7 -14 -3 -34 6 -34 6
                    -34 -38 -40 -161 -19 -268 -15 -268 10 0 8 -5 14 -11 14 -6 0 -9 -10 -7 -22 3
                    -21 10 -24 93 -31 111 -10 238 4 273 30 30 23 45 62 74 199 17 85 19 106 8
                    119 -14 17 -30 20 -30 5z"
                />
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
