import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--zocalo"
                width="120"
                viewBox="0 0 387.07 250.11"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <path d="m2.33,58.13l.65,38.29s164.55,79.78,165.87,80.23,155.78,3.15,155.79.51c.02-2.64,1.16-117.94,1.16-117.94L2.33,58.13Z" />
                <path d="m2.66,118.11c-.01,1.98-.8,130.71-.8,130.71l323.26-2.31.07-47.75-162.64-.24L2.66,118.11Z" />
                <line x1="328.02" y1="9.73" x2="1.02" y2="10.79" />
                <line x1="327.95" y1="1.08" x2="327.9" y2="20.85" />
                <line x1=".51" y1="0" x2=".47" y2="19.77" />
                <line x1="376.88" y1="61.25" x2="376.47" y2="249.96" />
                <line x1="367.3" y1="60.88" x2="387.07" y2="60.92" />
                <line x1="366.52" y1="249.6" x2="386.29" y2="249.64" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
