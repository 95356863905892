export const size = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    xxl: 1400,
};

export default {
    sm: `(min-width: ${size.sm}px)`,
    md: `(min-width: ${size.md}px)`,
    lg: `(min-width: ${size.lg}px)`,
    xl: `(min-width: ${size.xl}px)`,
    xxl: `(min-width: ${size.xxl}px)`,
    // max
    maxXs: `(max-width: ${size.sm - 1}px)`,
    maxSm: `(max-width: ${size.md - 1}px)`,
    maxMd: `(max-width: ${size.lg - 1}px)`,
    maxLg: `(max-width: ${size.xl - 1}px)`,
    maxXl: `(max-width: ${size.xxl - 1}px)`,
    // only
    onlyMd: `(min-width: ${size.md}px) and (max-width: ${size.lg - 1}px)`,
    onlyLg: `(min-width: ${size.lg}px) and (max-width: ${size.xl - 1}px)`,
    onlyXl: `(min-width: ${size.xl}px) and (max-width: ${size.xxl - 1}px)`,
};
