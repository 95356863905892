import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--diamond"
                width="120"
                viewBox="0 0 211.7 343.9"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points="173.8,187.6 88.2,339.5 2.7,187.6 88.2,39.5" />
                <line x1="2.7" y1="12" x2="173.7" y2="12" />
                <line x1="173.7" y1="4.4" x2="173.7" y2="19.5" />
                <line x1="2.7" y1="4.4" x2="2.7" y2="19.5" />
                <line x1="201.5" y1="39.5" x2="201.5" y2="339.5" />
                <line x1="209" y1="339.5" x2="193.9" y2="339.5" />
                <line x1="209" y1="39.5" x2="193.9" y2="39.5" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
