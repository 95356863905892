import { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import NextImage from 'next/image';
import Link from 'components/atoms/Link';
import moment from 'moment';

import { identifyShape, roundToTwo } from 'helpers';
import breakpoints from 'styles/breakpoints';

import { BodyText, BodyTextAlt, DateText, H3 } from 'components/atoms/Typography';
import Image from 'components/atoms/Image';
import Button from 'components/atoms/Button';
import { Section } from 'components/atoms/Container';
import SiteContext from 'context/SiteContext';
import { logInfo } from 'helpers/logging';
import { APPS } from 'helpers/constants/general';
import { transformToElevarUserProperties } from 'helpers/elevar';

const DesktopContainer = styled.div`
    display: none;
    @media screen and ${breakpoints.lg} {
        display: block;
    }
`;

const MobileContainer = styled.div`
    @media screen and ${breakpoints.lg} {
        display: none;
    }
`;

const CardOuter = styled.div`
    cursor: pointer;
    padding: ${props => props.padding};
    height: 100%;
    display: flex;
    flex-direction: column;
    .card-img-cont {
        position: relative;
        width: 100%;
        height: 0%;
        padding-bottom: 100%;
        padding-bottom: ${props => props.aspectRatio};
        overflow: hidden;
        ${({ aspectRatioMob }) =>
            aspectRatioMob &&
            `
            @media ${breakpoints.maxXs} {
                padding-bottom: ${aspectRatioMob};
            }
        `}
        img {
            position: absolute;
            transition: all 0.2s linear;
            min-width: 100%;
            min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            object-fit: cover;
            object-position: center;
        }
        &.multiple-images {
            .image-1 {
                opacity: 0;
            }
            @media screen and ${breakpoints.md} {
                &:hover {
                    .image-0 {
                        opacity: 0;
                    }
                    .image-1 {
                        opacity: 1;
                    }
                }
            }
        }
    }
    .card-img-cont--next {
        > div,
            > div > div {
                position: absolute !important;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                padding: 0 !important;
            }
        }
    }
    .card-collection {
        &:before {
        content: '·';
        display: inline-block;
        margin: 0 12px;
    }
    }
`;

export const ProductCard = ({
    title = '',
    handle = '',
    images = [],
    collectionTitle = '',
    leadTime,
    price = '',
    className = '',
    imgWidth,
    imgHeight,
    imgPriority,
    imgLoading = 'lazy',
    imgSizes,
    length,
    width,
    isMosaic,
    shape,
    sqftBoxRatio,
    linearftBoxRatio,
    template,
    nonTilePDP,
    pageName,
    sectionName = 'Product list',
    compareAtPrice = null,
    productId,
    variantId,
    vendor,
    collections,
    variantTitle,
    position,
    variantSku,
    list,
    query = '',
    ...props
}) => {
    const {
        store: {
            tradeDiscountValue,
            customer,
            customerLoggedIn,
            checkout: { currencyCode },
        },
        setDataLayerList,
    } = useContext(SiteContext);

    const pricePerSquareFoot = useMemo(() => {
        if (template === 'default')
            return sqftBoxRatio ? roundToTwo(Number(price) / Number(sqftBoxRatio)) : null;
    }, [price, sqftBoxRatio]);
    const pricePerLinearFoot = useMemo(() => {
        if (template === 'barliner') return roundToTwo(Number(price) * 2);
        if (template === 'base-molding')
            return linearftBoxRatio ? roundToTwo(Number(price) / Number(linearftBoxRatio)) : null;
    }, [price, linearftBoxRatio]);

    const formatCollectionTitle = title => {
        const formattedTitle = title.split(' ')[0];
        const titleExceptions = ['terra forms', 'cotto allende'];
        const shouldSkipFormatting = titleExceptions.includes(title.toLowerCase());

        if (formattedTitle.toLowerCase() === 'thin') {
            return 'Thin Brick';
        }
        if (shouldSkipFormatting) {
            return title;
        }

        return formattedTitle;
    };

    const handleClick = () => {
        setDataLayerList(list);
        /** Collection/Search page product click...
                this is the product the user clicks on from collection page **/
        window.ElevarDataLayer = window.ElevarDataLayer ?? [];
        const compare_at_price = Number(compareAtPrice) || Number(price);
        const sale_price = (Number(price) * (100 - tradeDiscountValue)) / 100;
        const selectItemData = {
            event: 'dl_select_item',
            user_properties: {
                ...transformToElevarUserProperties({ customer, customerLoggedIn }),
            },
            ecommerce: {
                currencyCode: currencyCode,
                click: {
                    actionField: {
                        list, // this should be the collection page URL
                        action: 'click',
                    },
                    products: [
                        {
                            id: variantSku || handle, // SKU
                            name: title, // Product title
                            brand: vendor || 'Zia Tile',
                            category:
                                collections
                                    ?.slice(0, 5)
                                    ?.map(({ title }) => title)
                                    ?.join(',') || '',
                            variant: variantTitle !== 'Default Title' ? variantTitle : '',
                            price: roundToTwo(sale_price),
                            position, // Only required for dl_select_item; position in the list of search results, collection views and position in cart indexed starting at 1,
                            list, // The list the product was discovered from
                            product_id: String(productId).replace('gid://shopify/Product/', ''), // The product_id
                            variant_id:
                                String(variantId).replace('gid://shopify/ProductVariant/', '') ||
                                String(productId).replace('gid://shopify/Product/', ''), // id or variant_id
                            compare_at_price:
                                compare_at_price > sale_price
                                    ? roundToTwo(compare_at_price)
                                    : '0.0', // If available on dl_view_item & dl_add_to_cart otherwise use "0.0"
                            image: (images?.[0]?.src ?? '').replace('https:', ''), // If available, otherwise use an empty string
                            url: `${process.env.NEXT_PUBLIC_SITE_URL}/products/${handle}`, // URL for the Product Page. Only required for dl_add_to_cart.
                        },
                    ],
                },
            },
        };
        window.ElevarDataLayer.push({ ...selectItemData });
        logInfo('Fire Elevar dl_select_item', APPS.FRONTEND, selectItemData);
    };

    return (
        <div className={`relative ${className}`}>
            <Link
                href={`/products/${handle}${query}`}
                passHref
                className="absolute z-10 w-full h-full text-0"
                onClick={handleClick}
            >
                {title}
            </Link>
            <CardOuter {...props}>
                <Link
                    href={`/products/${handle}${query}`}
                    className={`card-img-cont card-img-cont--next block mb-4 z-20 ${
                        images.length > 1
                            ? 'multiple-images'
                            : images.length === 0
                            ? 'bg-black/10'
                            : ''
                    }`}
                    onClick={handleClick}
                >
                    {images?.map((image, i) => {
                        return (
                            <NextImage
                                key={i}
                                className={`image-${i}`}
                                src={image.src}
                                alt={`${title} - Featured products ${pageName} ${sectionName}`}
                                width={imgWidth || 395}
                                height={imgHeight || 395}
                                sizes={imgSizes}
                                loading={imgLoading}
                                priority={imgPriority}
                            />
                        );
                    })}
                </Link>
                <div className="grid grid-flow-col gap-3 justify-between items-start mb-3">
                    <div className="flex">
                        <BodyTextAlt>{title}</BodyTextAlt>
                        {collectionTitle ? (
                            <BodyText
                                className="card-collection flex-1 flex"
                                color="rgb(var(--subdued-text-color))"
                                lineHeight="0.875"
                            >
                                {formatCollectionTitle(collectionTitle)}
                            </BodyText>
                        ) : null}
                    </div>
                    {!!leadTime && (
                        <BodyTextAlt
                            className="ml-3 py-3px px-1.5 border border-current whitespace-pre"
                            color="rgb(var--(heading-color))"
                            lineHeight="1.167"
                        >
                            {leadTime}
                        </BodyTextAlt>
                    )}
                </div>
                {nonTilePDP || title === '511 POROUS PLUS SEALER' ? (
                    <div className="flex items-center mb-4">
                        <BodyText margin="0 5px 0 0 " color="rgb(var(--subdued-text-color))">
                            ${roundToTwo(Number(price))}
                        </BodyText>
                    </div>
                ) : (
                    <div className="flex items-center justify-between mb-4">
                        <BodyText margin="0 20px 0 0 " color="rgb(var(--subdued-text-color))">
                            {length && `${length}” x `}
                            {width && `${width}"`} {isMosaic ? 'Mosaic' : identifyShape(shape)}
                        </BodyText>
                        <span className="flex flex-wrap items-center">
                            {tradeDiscountValue && (
                                <>
                                    {pricePerSquareFoot && (
                                        <BodyText
                                            as="strike"
                                            margin="0 5px 0 0 "
                                            color="rgb(var(--subdued-text-color))"
                                            fontSize="0.925rem"
                                        >
                                            ${pricePerSquareFoot}
                                        </BodyText>
                                    )}
                                    {pricePerLinearFoot && (
                                        <BodyText
                                            as="strike"
                                            margin="0 5px 0 0 "
                                            color="rgb(var(--subdued-text-color))"
                                            fontSize="0.925rem"
                                        >
                                            ${pricePerLinearFoot}
                                        </BodyText>
                                    )}
                                </>
                            )}
                            {pricePerSquareFoot && (
                                <BodyText
                                    as="span"
                                    margin="0 5px 0 0 "
                                    color="rgb(var(--subdued-text-color))"
                                >
                                    $
                                    {roundToTwo(
                                        (pricePerSquareFoot * (100 - tradeDiscountValue)) / 100
                                    )}{' '}
                                    /ft
                                    <sup>2</sup>
                                </BodyText>
                            )}
                            {pricePerLinearFoot && (
                                <BodyText
                                    as="span"
                                    margin="0 5px 0 0 "
                                    color="rgb(var(--subdued-text-color))"
                                >
                                    $
                                    {roundToTwo(
                                        (pricePerLinearFoot * (100 - tradeDiscountValue)) / 100
                                    )}{' '}
                                    /linear ft
                                </BodyText>
                            )}
                        </span>
                    </div>
                )}
            </CardOuter>
        </div>
    );
};

ProductCard.propTypes = {
    title: PropTypes.string,
    handle: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
    collectionTitle: PropTypes.string,
    leadTime: PropTypes.string,
    inStock: PropTypes.bool,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    imgWidth: PropTypes.number,
    imgHeight: PropTypes.number,
    imgPriority: PropTypes.bool,
    imgLoading: PropTypes.oneOf(['eager', 'lazy']),
    imgSizes: PropTypes.string,
    length: PropTypes.string,
    width: PropTypes.string,
    shape: PropTypes.string,
    isMosaic: PropTypes.bool,
    sqftBoxRatio: PropTypes.number,
    linearftBoxRatio: PropTypes.number,
    template: PropTypes.string,
    nonTilePDP: PropTypes.bool,
    pageName: PropTypes.string,
    sectionName: PropTypes.string,
    compareAtPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    variantId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    vendor: PropTypes.string,
    collections: PropTypes.array,
    variantTitle: PropTypes.string,
    position: PropTypes.number,
    list: PropTypes.string,
    variantSku: PropTypes.string,
    query: PropTypes.string,
};

export const CollectionCard = ({ title, url, description, images, ...attrs }) => {
    return (
        <Link href={url}>
            <CardOuter {...attrs}>
                <div className={`card-img-cont ${images.length > 1 ? 'multiple-images' : ''}`}>
                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                src={image.filename}
                                alt={image.alt || ''}
                                loading="lazy"
                                sizes={`${breakpoints.maxXs} 100vw, ${breakpoints.maxMd} 50vw, ${breakpoints.maxLg} 33.33vw, 25vw`}
                            />
                        );
                    })}
                </div>
                <div className="flex justify-between items-center my-xs">
                    <div className="flex items-center">
                        <BodyTextAlt margin="0 20px 0 0 ">{title}</BodyTextAlt>
                        <BodyText opacity="0.4">{description}</BodyText>
                    </div>
                </div>
            </CardOuter>
        </Link>
    );
};

CollectionCard.defaultProps = {
    title: '',
    url: '',
    description: '',
    images: [],
};

CollectionCard.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
};

export const CollectionCardV2 = ({ title, url, description, images, ...attrs }) => {
    return (
        <Link href={url}>
            <CardOuter {...attrs}>
                <div className={`card-img-cont mb-6 ${images.length > 1 ? 'multiple-images' : ''}`}>
                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                src={image.filename}
                                alt={image.alt || ''}
                                loading="lazy"
                                sizes={`${breakpoints.maxMd} 100vw, 25vw`}
                            />
                        );
                    })}
                </div>
                <div className="flex-1 flex flex-col gap-4">
                    <BodyText as="h3" fontSize="24px" lineHeight="1">
                        {title}
                    </BodyText>
                    <BodyText>{description}</BodyText>
                    <Button className="mt-auto" as="span" label="Explore the range"></Button>
                </div>
            </CardOuter>
        </Link>
    );
};

CollectionCardV2.defaultProps = {
    title: '',
    url: '',
    description: '',
    images: [],
};

CollectionCardV2.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
};

export const AllCollectionsFeaturedCollectionCard = ({
    title,
    url,
    description,
    images,
    aspectRatio,
    aspectRatioMob,
    firstPublishedAt,
}) => {
    return (
        <Link href={url}>
            <CardOuter aspectRatio={aspectRatio} aspectRatioMob={aspectRatioMob}>
                <div className={`card-img-cont ${images.length > 1 ? 'multiple-images' : ''}`}>
                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                src={image.filename}
                                alt={image.alt || ''}
                                loading="lazy"
                                sizes={`${breakpoints.maxMd} 100vw, 50vw`}
                            />
                        );
                    })}
                </div>
                <MobileContainer>
                    <Section className="px-m-md pt-3 pb-m-lg md:pt-5 md:pb-20">
                        {firstPublishedAt ? (
                            <DateText margin="0 0 12px 0">
                                {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                            </DateText>
                        ) : null}
                        <H3>{title}</H3>
                        <div className="pt-xs">
                            <Button as="div" type="primary" label="View Collection" />
                        </div>
                    </Section>
                </MobileContainer>

                <DesktopContainer>
                    <div className="grid gap-x-40px grid-cols-6 mt-sm">
                        <div className="col-span-2">
                            {firstPublishedAt ? (
                                <DateText margin="0 0 12px 0">
                                    {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                                </DateText>
                            ) : null}
                            <BodyText>{description}</BodyText>
                        </div>
                        <div />
                        <div className="col-span-3 flex flex-col justify-between">
                            <H3>{title}</H3>
                            <Button as="div" type="primary" label="View Collection" />
                        </div>
                    </div>
                </DesktopContainer>
            </CardOuter>
        </Link>
    );
};

AllCollectionsFeaturedCollectionCard.defaultProps = {
    title: '',
    url: '',
    description: '',
    images: [],
    aspectRatio: '',
    aspectRatioMob: '',
    firstPublishedAt: '',
};

AllCollectionsFeaturedCollectionCard.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
    aspectRatio: PropTypes.string,
    aspectRatioMob: PropTypes.string,
    firstPublishedAt: PropTypes.string,
    length: PropTypes.string,
    width: PropTypes.string,
    shape: PropTypes.string,
};

export const AllCollectionsUpperCollectionCard = ({ title, url, images, firstPublishedAt }) => {
    return (
        <Link href={url}>
            <CardOuter>
                <div className={`card-img-cont ${images.length > 1 ? 'multiple-images' : ''}`}>
                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                src={image.filename}
                                alt={image.alt || ''}
                                loading="lazy"
                                sizes={`${breakpoints.maxMd} 100vw, 25vw`}
                            />
                        );
                    })}
                </div>
                <MobileContainer>
                    <Section padding="20px 0px 0">
                        {firstPublishedAt ? (
                            <DateText margin="0 0 12px 0">
                                {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                            </DateText>
                        ) : null}
                        <H3>{title}</H3>
                        <div className="pt-xs">
                            <Button as="div" type="primary" label="View Collection" />
                        </div>
                    </Section>
                </MobileContainer>
                <div className="flex flex-1 flex-col justify-between">
                    <DesktopContainer>
                        <div className="mt-sm" />
                        {firstPublishedAt ? (
                            <DateText margin="0 0 12px 0">
                                {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                            </DateText>
                        ) : null}
                        <H3>{title}</H3>
                    </DesktopContainer>
                    <DesktopContainer>
                        <Button as="div" type="primary" label="View Collection" />
                    </DesktopContainer>
                </div>
            </CardOuter>
        </Link>
    );
};

AllCollectionsUpperCollectionCard.defaultProps = {
    title: '',
    url: '',
    images: [],
    firstPublishedAt: '',
};

AllCollectionsUpperCollectionCard.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
    firstPublishedAt: PropTypes.string,
};

export const AllCollectionsLowerCollectionCard = ({
    aspectRatio,
    aspectRatioMob,
    title,
    url,
    images,
    firstPublishedAt,
}) => {
    return (
        <Link href={url}>
            <CardOuter aspectRatio={aspectRatio} aspectRatioMob={aspectRatioMob}>
                <div
                    className={`card-img-cont mb-4 lg:mb-0 ${
                        images.length > 1 ? 'multiple-images' : ''
                    }`}
                >
                    {images.map((image, i) => {
                        return (
                            <Image
                                key={i}
                                src={image.filename}
                                alt={image.alt || ''}
                                loading="lazy"
                                sizes={`${breakpoints.maxMd} 100vw, 25vw`}
                            />
                        );
                    })}
                </div>
                <MobileContainer>
                    <div className="mx-auto md:pt-5">
                        {firstPublishedAt ? (
                            <DateText margin="0 0 12px 0">
                                {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                            </DateText>
                        ) : null}
                        {/* <H2>{title}</H2> */}
                        <H3>
                            <Button as="span" className="pt-3" type="primary" label={title} />
                        </H3>
                    </div>
                </MobileContainer>
                <DesktopContainer>
                    <div className="flex flex-1 flex-col justify-between mt-sm">
                        {firstPublishedAt ? (
                            <DateText margin="0 0 12px 0">
                                {moment(firstPublishedAt).format('MMMM Do, YYYY')}
                            </DateText>
                        ) : null}
                        <H3 margin="0 0 100px">{title}</H3>
                        <Button as="div" type="primary" label="View Collection" />
                    </div>
                </DesktopContainer>
            </CardOuter>
        </Link>
    );
};

AllCollectionsLowerCollectionCard.defaultProps = {
    aspectRatio: '',
    aspectRatioMob: '',
    title: '',
    url: '',
    images: [],
    firstPublishedAt: '',
};

AllCollectionsLowerCollectionCard.propTypes = {
    aspectRatio: PropTypes.string,
    aspectRatioMob: PropTypes.string,
    title: PropTypes.string,
    url: PropTypes.string,
    images: PropTypes.oneOfType([PropTypes.array]),
    firstPublishedAt: PropTypes.string,
};

const Card = ({ type, ...props }) => {
    if (type === 'product') {
        return <ProductCard {...props} />;
    }
    if (type === 'collection') {
        return <CollectionCard {...props} />;
    }
    if (type === 'allCollectionsFeaturedCollection') {
        return <AllCollectionsFeaturedCollectionCard {...props} />;
    }
    if (type === 'allCollectionsUpperCollection') {
        return <AllCollectionsUpperCollectionCard {...props} />;
    }
    if (type === 'allCollectionsLowerCollection') {
        return <AllCollectionsLowerCollectionCard {...props} />;
    }
};

export default Card;
