import { useRef } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SlickSlider from 'react-slick';
import 'slick-carousel/slick/slick.css';

import Button from 'components/atoms/Button';
import { ArrowLeftIcon, ArrowRightIcon } from 'components/atoms/Icons';

const SliderContainer = styled.div`
    .slick-arrow {
        --border-color: var(--subdued-text-color);
        width: 40px;
        height: 40px;
        &:before {
            content: none;
        }
    }
    .slick-dots {
        position: absolute;
        > li button {
            display: block;
            cursor: pointer;
            font-size: 0;
            line-height: 0;
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                opacity: 0.25;
            }
        }
        li.slick-active button:before {
            opacity: 0.75;
        }
    }
`;

const PrevArrow = ({ onClick, prevFunc }) => {
    return (
        <Button
            type="icon"
            padding="7px"
            icon={<ArrowLeftIcon fill="currentColor" />}
            className="slick-arrow slick-prev"
            onClick={() => {
                if (onClick && typeof onClick === 'function') onClick();
                if (prevFunc && typeof prevFunc === 'function') prevFunc();
            }}
            inverted
        />
    );
};

PrevArrow.defaultProps = {
    onClick: () => {},
    prevFunc: () => {},
};

PrevArrow.propTypes = {
    onClick: PropTypes.func,
    prevFunc: PropTypes.func,
};

const NextArrow = ({ onClick, nextFunc }) => {
    return (
        <Button
            type="icon"
            padding="7px"
            icon={<ArrowRightIcon />}
            className="slick-arrow slick-next"
            onClick={() => {
                if (onClick && typeof onClick === 'function') onClick();
                if (nextFunc && typeof nextFunc === 'function') nextFunc();
            }}
            inverted
        />
    );
};

NextArrow.defaultProps = {
    onClick: () => {},
    nextFunc: () => {},
};

NextArrow.propTypes = {
    onClick: PropTypes.func,
    nextFunc: PropTypes.func,
};

const Slider = ({ settings, children, prevFunc, nextFunc, ...props }) => {
    const settingsWithArrow = {
        ...settings,
        prevArrow: <PrevArrow prevFunc={prevFunc} />,
        nextArrow: <NextArrow nextFunc={nextFunc} />,
    };
    const sliderRef = useRef();
    return (
        <SliderContainer {...props}>
            <SlickSlider ref={sliderRef} {...settingsWithArrow}>
                {children}
            </SlickSlider>
        </SliderContainer>
    );
};

Slider.defaultProps = {
    prevFunc: () => {},
    nextFunc: () => {},
    settings: {},
};

Slider.propTypes = {
    prevFunc: PropTypes.func,
    nextFunc: PropTypes.func,
    settings: PropTypes.oneOfType([PropTypes.object]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Slider;
