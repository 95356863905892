import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill = 'currentColor', ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--portal"
                width="182"
                viewBox="0 0 182 200"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth={0.5}
            >
                <polygon points="2,24.8 1.8,170.3 135.1,198.5 135.1,38.3 " />
                <polygon points="135.1,198.5 135.1,38.3 163.9,29.4 163.9,180.5 " />
                <polygon points="2,24.8 36.8,16.5 163.9,29.4 135.4,38.2 " />
                <polyline points="37.2,1.5 36.7,6.5 36.2,11.5 " />
                <polyline points="166.7,13.7 166.1,18.7 165.6,23.7 " />
                <line x1="36.5" y1="6.5" x2="166.1" y2="18.7" />
                <polyline points="169.9,29.7 174.9,29.7 179.9,29.7 " />
                <polyline points="170.2,180.5 175.2,180.5 180.2,180.5 " />
                <line x1="175.2" y1="180.5" x2="174.9" y2="29.7" />
                <path
                    d="M117.8,121.1c-0.1,1.3-0.2,2.5-0.2,2.6c-0.5,4.6-1.8,16.1-9.6,25.9c-6.5,8.2-14.7,11.6-17.4,12.6
                c-21.5,8.2-42.2-5.6-45.1-7.7c-0.5-0.3-0.9-0.7-1.4-1c-15.4-11.4-20.3-28.8-21.6-33.6c-6.2-23.4,0.4-51.6,15.8-62.1
                c0.4-0.3,0.8-0.5,1-0.6C56,46.7,74,52.3,75.9,52.9c0.1,0,0.4,0.1,0.8,0.3c17,6,26.3,20.5,29.6,25.5
                C117.7,96.3,118.3,113.9,117.8,121.1L117.8,121.1z"
                />
                <path
                    d="M66.4,50.7c-2.9,3.9-6.5,9.5-9.4,17c-0.8,2.1-3,8-4,15.4c-0.9,6.3-3,22.3,6.1,39c2.1,3.9,11.1,19.1,29.8,26.1
                c7,2.6,13.3,3.2,17.5,3.4"
                />
            </svg>
        </PDPIcon>
    );
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
