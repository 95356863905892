import { storyblokEditable } from '@storyblok/react';
import Image from 'components/atoms/Image';
import { H1, H3 } from 'components/atoms/Typography';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { render } from 'storyblok-rich-text-react-renderer';

const HeroV4 = ({ blok }) => {
    const data = useMemo(() => {
        const {
            image,
            mobileImage,
            preheading,
            heading,
            text,
            allowTransparentHeader = false,
        } = { ...blok };
        return {
            image: image?.filename && image?.filename !== '' ? image : null,
            mobileImage: mobileImage?.filename && mobileImage?.filename !== '' ? mobileImage : null,
            preheading,
            heading,
            text,
            allowTransparentHeader,
        };
    }, [blok]);
    return (
        <div
            className={`relative`}
            {...storyblokEditable(blok)}
            {...(data.allowTransparentHeader &&
                data.image && { 'data-allow-transparent-header': 'true' })}
        >
            {data.image && (
                <div className="aspect-h-10 aspect-w-15 lg:aspect-h-5">
                    <Image
                        className={`mix-blend-multiply  ${
                            data.mobileImage ? 'lg:block hidden' : ''
                        }`}
                        src={data.image.filename}
                        priority={true}
                        sizes="(max-width: 480px) 90vw, 100vw"
                        alt={data.image?.alt || data.heading}
                        fill={true}
                    />
                    {data.mobileImage && (
                        <Image
                            className={`mix-blend-multiply lg:hidden`}
                            src={data.mobileImage.filename}
                            priority={true}
                            sizes="100vw"
                            alt={data.mobileImage?.alt || data.heading}
                            fill={true}
                        />
                    )}
                </div>
            )}
            <div className="container my-9 lg:my-m-lg">
                <div className="grid grid-cols-6 lg:grid-cols-12 gap-4 lg:gap-8 items-center">
                    <div className="col-span-full lg:col-span-7 grid gap-1 lg:gap-2">
                        {data.preheading && <H3>{data.preheading}</H3>}
                        {data.heading && <H1>{data.heading}</H1>}
                    </div>
                    {data.text && (
                        <div className="col-span-full lg:col-span-5">
                            <div className="rte">{render(data.text)}</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

HeroV4.propTypes = {
    blok: PropTypes.object,
};

export default HeroV4;
