import { create } from 'zustand';
import { produce } from 'immer';
import { devtools } from 'zustand/middleware';

const instagramModalStore = set => ({
    modalOpen: false,
    handleCloseModal: () =>
        set(
            produce(draft => {
                draft.modalOpen = false;
                draft.selectedPost = false;
            })
        ),
    selectedPost: null,
    handleChangeSelectedPost: post =>
        set(
            produce(draft => {
                draft.selectedPost = post;
                draft.modalOpen = true;
            })
        ),
});

const useInstagramModalStore = create(devtools(instagramModalStore));

export default useInstagramModalStore;
