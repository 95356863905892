import { storyblokEditable } from '@storyblok/react';
import { H3 } from 'components/atoms/Typography';
import PropTypes from 'prop-types';
import { render } from 'storyblok-rich-text-react-renderer';
import styled from 'styled-components';

const Organism = styled.div`
    background-color: ${props => props.backgroundColor};
    border-top: 1px solid ${props => props.borderColor};
    border-bottom: 1px solid ${props => props.borderColor};
`;
const Announcement = ({ blok }) => {
    if (!blok.text) {
        return null;
    }
    return (
        <Organism
            className="text-center py-4"
            backgroundColor={blok.background.color || '#F7F7F7'}
            borderColor={blok.borderColor.color || '#CCCCCC'}
            {...storyblokEditable(blok)}
        >
            <div className="container">
                <H3 as="div" color={blok.textColor.color || '#000000'}>
                    {render(blok.text)}
                </H3>
            </div>
        </Organism>
    );
};

Announcement.propTypes = {
    blok: PropTypes.object,
};

export default Announcement;
