import PropTypes from 'prop-types';

import { PDPIcon } from './PdpIcon.styled';

const Icon = ({ fill, ...attrs }) => {
    return (
        <PDPIcon>
            <svg
                className="icon icon--size icon--aero"
                width="120"
                viewBox="0 0 336.95 278.31"
                {...attrs}
                fill="none"
                stroke={fill}
                strokeWidth="1"
            >
                <polygon points=".7 203.77 73.81 277.6 148.01 204.86 220.2 277.42 296.77 205.59 148.37 55.55 .7 203.77" />
                <line x1="326.76" y1="55.82" x2="326.76" y2="278.03" />
                <line x1="317.18" y1="55.41" x2="336.95" y2="55.41" />
                <line x1="316.81" y1="277.63" x2="336.58" y2="277.63" />
                <line strokeMiterlimit="10" x1="297.39" y1="9.02" x2="2.05" y2="10.78" />
                <line strokeMiterlimit="10" x1="297.3" y1=".37" x2="297.3" y2="20.14" />
                <line strokeMiterlimit="10" x1="1.57" x2="1.57" y2="19.77" />
            </svg>
        </PDPIcon>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
