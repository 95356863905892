import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg
            className="icon icon--checkFilled"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            {...attrs}
        >
            <g fill="none" fillRule="evenodd">
                <g fill={fill}>
                    <path
                        d="M16 0v16H0V0h16zm-4.626 3.564L6.579 9.009l-2.01-1.903-.1-.083c-.388-.255-.87-.216-1.186.083-.38.36-.38.96 0 1.32L6 10.997l.108.087c.414.267.931.2 1.237-.147l5.432-6.175.079-.104c.254-.423.16-.926-.2-1.21-.387-.306-.954-.257-1.281.115z"
                        transform="translate(-1134.000000, -411.000000) translate(983.000000, 348.000000) translate(40.000000, 60.000000) translate(111.000000, 0.000000) translate(0.000000, 3.000000)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
