import { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Scroller = styled.div`
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge add Firefox */
    --ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
`;

const ScrollingCarousel = ({
    children,
    ariaLabel,
    ariaRoleDescription = 'carousel',
    alignItems = 'start',
    prevNextButtons = false,
    PreviousButton,
    NextButton,
    autoPlay = true,
}) => {
    const [state, setState] = useState({
        scrollingPaused: !autoPlay,
        scrollAtStart: true,
        scrollAtEnd: false,
    });
    const stateRef = useRef();
    stateRef.current = state;

    const speed = 100;
    const $ref = useRef(null);

    const scrollRight = useCallback(() => {
        if ($ref?.current) {
            if (stateRef.current.scrollingPaused) {
                window.requestAnimationFrame(scrollRight);
                return;
            }
            if (
                $ref?.current?.scrollLeft + 1 <
                $ref.current?.scrollWidth - $ref.current?.offsetWidth
            ) {
                $ref.current.scrollLeft += speed / 100;
                window.requestAnimationFrame(scrollRight);
                return;
            }
            window.requestAnimationFrame(scrollLeft);
        }
    }, [stateRef, $ref]);

    const scrollLeft = useCallback(() => {
        if ($ref?.current) {
            if (stateRef.current.scrollingPaused) {
                window.requestAnimationFrame(scrollLeft);
                return;
            }
            if ($ref?.current?.scrollLeft != 0) {
                $ref.current.scrollLeft -= speed / 100;
                window.requestAnimationFrame(scrollLeft);
                return;
            }
            window.requestAnimationFrame(scrollRight);
        }
    }, [stateRef, $ref]);

    const scrollBy = (value, duration) => {
        var easingFunc = function (t) {
            return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
        };
        var startTime;
        var startPos = $ref.current.scrollLeft;
        var maxScroll = $ref.current.scrollWidth - $ref.current.offsetWidth;
        var scrollIntendedDestination = startPos + value;
        // low and high bounds for possible scroll destinations
        var scrollEndValue = Math.min(Math.max(scrollIntendedDestination, 0), maxScroll);
        // create recursive function to call every frame
        var scroll = function (timestamp) {
            startTime = startTime || timestamp;
            var elapsed = timestamp - startTime;
            $ref.current.scrollLeft =
                startPos + (scrollEndValue - startPos) * easingFunc(elapsed / duration);
            elapsed <= duration && window.requestAnimationFrame(scroll);
        };
        // call recursive function
        if (startPos != scrollEndValue) window.requestAnimationFrame(scroll);
    };

    const scrollRightBy = useCallback(() => {
        const value = window.outerWidth / 3;
        scrollBy(value, 500);
    }, [$ref]);

    const scrollLeftBy = useCallback(() => {
        const value = window.outerWidth / 3;
        scrollBy(value * -1, 500);
    }, [$ref]);

    const pauseScrolling = () => {
        setState(prevState => ({
            ...prevState,
            scrollingPaused: true,
        }));
    };

    const resumeScrolling = () => {
        if (!autoPlay) return;
        setState(prevState => ({
            ...prevState,
            scrollingPaused: false,
        }));
    };

    const handleScrollAt = useCallback(() => {
        // Scroll at start
        if ($ref?.current?.scrollLeft === 0 && !stateRef.current.scrollAtStart) {
            setState(prevState => ({
                ...prevState,
                scrollAtStart: true,
                scrollAtEnd: false,
            }));
            return;
        }

        // Scroll at end
        if (
            $ref?.current?.scrollLeft + 1 >=
                $ref?.current?.scrollWidth - $ref?.current?.offsetWidth &&
            !stateRef.current.scrollAtEnd
        ) {
            setState(prevState => ({
                ...prevState,
                scrollAtStart: false,
                scrollAtEnd: true,
            }));
            return;
        }

        // Scroll not at edge
        if (stateRef.current.scrollAtStart || stateRef.current.scrollAtEnd)
            setState(prevState => ({
                ...prevState,
                scrollAtStart: false,
                scrollAtEnd: false,
            }));
    }, [$ref, stateRef]);

    // Initialize
    useEffect(() => {
        if ($ref?.current) {
            $ref.current.addEventListener('scroll', handleScrollAt);
            if (autoPlay) {
                window.requestAnimationFrame(scrollRight);
            }
        }
        return () => {
            if ($ref?.current) $ref.current.removeEventListener('scroll', handleScrollAt);
        };
    }, [$ref]);

    return (
        <section
            className="relative"
            aria-label={ariaLabel}
            aria-roledescription={ariaRoleDescription}
        >
            <p className="sr-only">
                This is a carousel with auto-rotating slides.{' '}
                {prevNextButtons && <>Use Next and Previous buttons to navigate.</>}
            </p>
            {/* Pause / play autoplay button */}
            {/* Previous slide button */}
            {prevNextButtons && PreviousButton && (
                <PreviousButton
                    onClick={scrollLeftBy}
                    onMouseOver={pauseScrolling}
                    onFocus={pauseScrolling}
                    onMouseOut={resumeScrolling}
                    onBlur={resumeScrolling}
                    disabled={state.scrollAtStart}
                />
            )}
            <Scroller
                ref={$ref}
                className={`w-full flex items-${alignItems} overflow-x-auto overflow-y-hidden`}
                aria-live="off"
                onMouseOver={pauseScrolling}
                onMouseOut={resumeScrolling}
            >
                {/* Slides */}
                {children}
            </Scroller>
            {/* Next slide button */}
            {prevNextButtons && NextButton && (
                <NextButton
                    onClick={scrollRightBy}
                    onMouseOver={pauseScrolling}
                    onFocus={pauseScrolling}
                    onMouseOut={resumeScrolling}
                    onBlur={resumeScrolling}
                    disabled={state.scrollAtEnd}
                />
            )}
            {/* Slide dots */}
        </section>
    );
};

ScrollingCarousel.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
    ariaLabel: PropTypes.string.isRequired,
    ariaRoleDescription: PropTypes.string,
    alignItems: PropTypes.oneOf(['start', 'center', 'end']).isRequired,
    prevNextButtons: PropTypes.bool,
    PreviousButton: PropTypes.func,
    NextButton: PropTypes.func,
    autoPlay: PropTypes.bool,
};

export default ScrollingCarousel;
