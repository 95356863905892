import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--trade" width="16" height="16" viewBox="0 0 16 16" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M0 .656C0 .294.283 0 .632 0h10.736c.35 0 .632.294.632.656v4.11c0 4.2-2.172 8.076-5.688 10.148-.193.115-.43.115-.624 0C2.172 12.842-.001 8.966 0 4.766V.656h0zM0 2L12 2"
                        transform="translate(-420 -195) translate(405 183) translate(15 12) translate(2 .5)"
                    />
                    <path
                        d="M6.37 4.233l.84 1.65h1.641c.17-.006.324.095.387.252.064.157.022.337-.104.45L7.71 8.068l.787 1.811c.071.17.026.366-.112.488-.139.121-.34.141-.498.049l-1.904-1.07-1.903 1.071c-.16.092-.36.073-.498-.049-.138-.121-.184-.318-.113-.487l.788-1.812-1.42-1.484c-.127-.113-.17-.293-.106-.45.063-.157.218-.258.387-.252h1.64l.843-1.65C5.676 4.09 5.824 4 5.985 4c.162 0 .31.09.384.233z"
                        transform="translate(-420 -195) translate(405 183) translate(15 12) translate(2 .5)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
