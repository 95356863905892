import PropTypes from 'prop-types';

const Icon = ({ fill, ...attrs }) => {
    return (
        <svg className="icon icon--faq" width="64" height="64" viewBox="0 0 54 55" {...attrs}>
            <g fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                <g stroke={fill}>
                    <path
                        d="M3.26 35.283c0 3.88 3.146 7.027 7.026 7.027 3.881 0 7.027-3.146 7.027-7.027 0-3.88-3.146-7.026-7.027-7.026-3.88 0-7.026 3.146-7.026 7.026zM20.18 52.85c-1.468-4.21-5.437-7.029-9.895-7.029-4.457 0-8.427 2.82-9.894 7.028M48.387 32.564c1.383 3.289.081 7.093-3.027 8.845-3.108 1.752-7.036.897-9.134-1.99M51.8 52.85c-1.469-4.21-5.438-7.029-9.895-7.029-4.458 0-8.427 2.82-9.895 7.028M47.178.15h1.757c1.94 0 3.513 1.574 3.513 3.514v19.323c0 1.94-1.573 3.513-3.513 3.513h-10.54l-10.54 10.54V26.5H20.83c-1.94 0-3.514-1.573-3.514-3.513V3.664c0-1.94 1.573-3.513 3.514-3.513h3.513"
                        transform="translate(-503 -5435) translate(380 5240) translate(124 196)"
                    />
                    <path
                        d="M30.488 6.299c0-2.426 1.966-4.392 4.391-4.392 2.426 0 4.392 1.966 4.392 4.392 0 2.425-1.966 4.391-4.392 4.391M34.88 17.717c.484 0 .878.393.878.878s-.394.878-.879.878-.878-.393-.878-.878.393-.878.878-.878"
                        transform="translate(-503 -5435) translate(380 5240) translate(124 196)"
                    />
                </g>
            </g>
        </svg>
    );
};

Icon.defaultProps = {
    fill: 'currentColor',
};

Icon.propTypes = {
    fill: PropTypes.string,
};

export default Icon;
