import PropTypes from 'prop-types';

const Logo = props => {
    const { fill, ...attrs } = props;
    return (
        <svg
            className="icon icon--logoMobile"
            width="59"
            height="43"
            viewBox="0 0 59 43"
            {...attrs}
        >
            <defs>
                <filter id="5e5719ndka">
                    <feColorMatrix
                        in="SourceGraphic"
                        values="0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 0 1.000000 0 0 0 1.000000 0"
                    />
                </filter>
            </defs>
            <g fill="none" fillRule="evenodd">
                <g filter="url(#5e5719ndka)" transform="translate(-30 -4919) translate(0 4889)">
                    <g>
                        <path
                            fill={fill}
                            fillRule="nonzero"
                            d="M20.27 23.093v1.531H13.8c-.552 0-.999.446-1 .997L12.754 43H8.515l.045-17.373c0-.555-.447-1.003-1-1.003H1v-1.531h19.27zm5.97 0L26.231 43H22V23.093h4.24zM34.8 23l-.075 17.464c0 .557.448 1.005 1 1.005h6.692L42.483 43H30.482V23H34.8zm23.815.093v1.531H49.75v7.657h6.937v1.531H49.75v6.657c0 .552.448 1 1 1H59V43H45.512V23.093h13.103zm-21.352-23c.707 0 1.356.372 1.716.97l.077.143L48.351 20h-4.983l-1.439-3.07c-.306-.653-.936-1.086-1.646-1.145l-.165-.007h-7.765l-1.923 4.209H28.7L36.006 4.11 34.083.093h3.18zm-11.033 0l-.007 19.894H22V.093h4.23zm-7.529 0L5.82 18.456H18.7v1.53H1.848c-.215 0-.424-.069-.597-.197-.409-.304-.519-.861-.274-1.294l.068-.105L13.51 1.623H1.204V.093h17.497zm18.074 6.12l-3.845 8.035h6.103c.15 0 .297-.034.432-.098.463-.221.677-.752.515-1.226l-.045-.108-3.16-6.602z"
                            transform="translate(30 30)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

Logo.defaultProps = {
    fill: 'currentColor',
};

Logo.propTypes = {
    fill: PropTypes.string,
};

export default Logo;
