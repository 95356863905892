import { roundToTwo } from 'helpers';
import { logInfo } from './logging';
import { APPS } from './constants/general';

export const transformLineItemsToAlgoliaObjectData = ({ lineItems, tradeDiscountValue = 0 }) => {
    const objectData = lineItems.map(lineItem => {
        const price = lineItem.title?.toLowerCase()?.includes(', sample')
            ? 0
            : roundToTwo(
                  (Number(lineItem.variant.price.amount) * (100 - tradeDiscountValue)) / 100
              );
        const compareAtPrice = roundToTwo(Number(lineItem.variant.price.amount));

        return {
            price: price,
            discount: compareAtPrice > price ? roundToTwo(compareAtPrice - price) : 0,
            quantity: lineItem.quantity,
        };
    });
    return objectData;
};

const ANALYTICS_SESSIONSTORAGE_KEY = 'ziafrontend-algolia-debug';
export const isAlgoliaAnalyticsEnabled = () => {
    let enabled = process.env.NEXT_PUBLIC_ENV === 'PRODUCTION';

    if (process.env.NEXT_PUBLIC_ENV === 'STAGING') {
        try {
            enabled = !!sessionStorage.getItem(ANALYTICS_SESSIONSTORAGE_KEY);
        } catch {
            //
        }
    }
    return enabled;
};
if (typeof window !== 'undefined') {
    window.ZiaFrontendEnableAlgoliaDebug = () => {
        sessionStorage.setItem(ANALYTICS_SESSIONSTORAGE_KEY, String(true));
        logInfo('Enabled algolia analytics 🔧', APPS.FRONTEND);
    };
}
