import PropTypes from 'prop-types';
import NextImage from 'next/image';
import { getCloudinaryId } from 'helpers';

const Image = ({
    className,
    src,
    alt = '',
    width,
    height,
    loading = 'lazy',
    priority = false,
    dimension,
    maxWidth,
    sizes = '',
    fill = false,
}) => {
    const pattern = /^[0-9]{1,4}x[0-9]{1,4}|[(\r\n|\r|\n)]+$/;

    if (fill || (width && height)) {
        // do nothing
    } else if (pattern.test(dimension)) {
        width = parseInt(dimension.slice(0, dimension.indexOf('x')));
        height = parseInt(dimension.slice(dimension.indexOf('x') + 1));
        if (width > maxWidth) {
            height = parseInt((height / width) * maxWidth);
            width = parseInt(maxWidth);
        }
    } else if (src?.split('/')[5]?.split('x')[0] && src?.split('/')[5]?.split('x')[1]) {
        width = src.split('/')[5].split('x')[0];
        height = src.split('/')[5].split('x')[1];
    }

    const attrs = {
        className,
        src: src.includes('res.cloudinary.com') ? getCloudinaryId(src) : src,
        alt,
        ...(width && height
            ? {
                  width,
                  height,
                  className: `${className} object-cover object-center`,
              }
            : {
                  fill: true,
              }),
        priority,
        loading: priority ? 'eager' : loading,
        ...(src.includes('res.cloudinary.com') && {
            loader: () => {
                return `https://res.cloudinary.com/conspire-agency-zia-tile/image/upload/c_fill,g_auto,dpr_auto,f_auto,w_${
                    width || 'auto'
                }${height ? `,h_${height}` : ``}${getCloudinaryId(src)}`;
            },
        }),
        ...(src.includes('a.storyblok.com') && {
            loader: () => {
                return `${src}/m${
                    width && height ? `/${width}x${height}/smart` : ``
                }/filters:quality(75)`;
            },
        }),
        sizes,
    };

    return (
        <NextImage
            {...attrs}
            style={{
                objectFit: 'cover',
                objectPosition: 'center',
            }}
        />
    );
};

Image.propTypes = {
    className: PropTypes.string,
    src: PropTypes.string,
    alt: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    loading: PropTypes.oneOf(['eager', 'lazy']),
    priority: PropTypes.bool,
    dimension: PropTypes.string,
    maxWidth: PropTypes.number,
    sizes: PropTypes.string,
    fill: PropTypes.bool,
};

export default Image;
